import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CacheBuster from 'react-cache-buster';
import Package from '../package.json';
import { CircularProgress } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const isProduction = process.env.NODE_ENV === 'production';
{/* <script crossorigin src="..."></script> */ }
root.render(

  <React.StrictMode>
    <CacheBuster
      currentVersion={Package.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<CircularProgress />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <App />
    </CacheBuster>
  </React.StrictMode>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
