import React, { useRef } from 'react'
import '../../assets/css/roche.css'
import { CircularProgress, Grid, Box } from '@mui/material'
import { STORAGE_KEY } from '../../constants/constant'
import { _storeData } from '../../common/methods/localstore'
import { useNavigate } from 'react-router-dom'
import { setCountryId } from '../../redux/actions/actions'
import store from '../../redux/store/store'
import { CustomLoader } from '../../common/control/customloader'

const Style = {
    loader: {
      display: "flex",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      alignItems: "center",
      justifyContent: "center",
    },
    label: {
        position: 'absolute',
        top: '-12px',
        zIndex: 1,
        left: '1em',
        backgroundColor: 'white',
        padding: '0 10px',
    }
  };

const CountryFlag = (props) => {
    const navigate = useNavigate();
    const counter = useRef(0);
    const onCountry = (item) => {
        store.dispatch(setCountryId(item.country_id))
        _storeData(STORAGE_KEY.CountryId, item.country_id)
        navigate('/roche-welcome');
    }
    console.log(counter,"counter")
    const imageSourceLoaded = () => {
    //   props.setLoader(true);
    //   counter.current += 1;
    //   if (counter.current >= props?.list?.length) props.setLoader(false);
    };

    return (
        <>
        {props?.loader ? <div style={Style.loader}><CustomLoader /></div> : <>
        <div style={{position: 'relative'}}>
        <label for='country' style={Style.label} className='label-font'>AFRICA</label>
        <div style={{marginTop: 60, border: '1px solid #e6e6e6', padding: '30px 30px 15px 30px', borderRadius: 8}}>
        
            <div className='scroll'>
                {props?.list?.length ?
                <Grid container spacing={6} className='d-flex flex-wrap flags-row'>
                    {props?.list.filter(item => item?.continent_id == "1")?.map(itm => {
                        return (
                            <Grid className='row-col-flag' md={2.4} item onClick={() => onCountry(itm)}>
                                <div className='d-flex flex-row flag-col-row align-items-center'>
                                    <div className='d-flex flex-column' style={{width: '50px', height: '50px'}}>
                                        <img src={itm?.country_icon} alt="" className='flags-roche' onLoad={imageSourceLoaded} />
                                        {/* <img className='flags-roche' src={Germany} alt="" /> */}
                                    </div>
                                    <div className='d-flex flex-column justify-content-center'>
                                        <p className='country-par'>{itm?.country_name}</p>
                                        <p className='con-par'>{parseInt(itm?.total_days || 0)} Days</p>
                                    </div>
                                </div>
                                {/* <hr className='horizontal' /> */}
                            </Grid>
                        )
                    })}
                </Grid>:
                <div style={{textAlign: 'center'}}>No Data Found</div>}
            </div>
        </div>
        </div>



        <div style={{position: 'relative'}}>
        <label for='country' style={Style.label} className='label-font'>REST OF THE WORLD</label>
        <div style={{marginTop: 60, minHeight: 270, border: '1px solid #e6e6e6', padding: '30px 30px 15px 30px', borderRadius: 8}}>
        
            <div className='scroll'>
                {props?.list?.length ?
                <Grid container spacing={6} className='d-flex flex-wrap flags-row'>
                    {props?.list.filter(item => item?.continent_id !== "1")?.map(itm => {
                        return (
                            <Grid className='row-col-flag' md={2.4} item onClick={() => onCountry(itm)}>
                                <div className='d-flex flex-row flag-col-row '>
                                    <div className='d-flex flex-column' style={{width: '50px', height: '50px'}}>
                                        <img src={itm?.country_icon} alt="" className='flags-roche' onLoad={imageSourceLoaded}  />
                                        {/* <img className='flags-roche' src={Germany} alt="" /> */}
                                    </div>
                                    <div className='d-flex flex-column justify-content-center'>
                                        <p className='country-par'>{itm?.country_name}</p>
                                        <p className='con-par'>{parseInt(itm?.total_days || 0)} Days</p>
                                    </div>
                                </div>
                                {/* <hr className='horizontal' /> */}
                            </Grid>
                        )
                    })}
                </Grid>:
                <div style={{textAlign: 'center'}}>No Data Found</div>}
            </div>
        </div>
        </div> </>
        }
        </>
    )
}

export default CountryFlag