import { IconButton, MenuItem, Select } from "@mui/material";
import React from "react";
import '../../assets/css/pagination.css'
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const CommonPagination = (props) => {
  const {
    onPageChange,
    count,
    page,
    rowsPerPage,
    onRowsPerPageChange,
  } = props;

//   if (page === 0 || paginationRange.length < 2) {
//     return null;
//   }
const onFirst = () => {
    onPageChange(1);
  };

  const onNext = () => {
    onPageChange(page + 1);
  };

  const onLast = () => {
    onPageChange(Math.ceil(count/rowsPerPage));
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };

  const onRowsPerPage = (event) => {
    console.log(event,"event")
    onRowsPerPageChange(event)
  }

  let lastPage = Math.ceil(count/rowsPerPage)
  return (
    <div className="paginationCon">
        <div className="leftPagCon">
            <div className="itemsText">Items per page</div>
            <Select id="select" value={rowsPerPage} size="small" onChange={onRowsPerPage}>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="100">100</MenuItem>
            </Select>
        </div>
        <div className="rightPageCon">
            <div className="leftArrowCon">
                <IconButton aria-label="delete" size="small" onClick={onFirst} style={page <= 1 ? {cursor: 'not-allowed'} : { color: "#546FE6" }} disabled={page <= 1}>
                    <FirstPageIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="delete" size="small" onClick={onPrevious} style={page <= 1 ? {cursor: 'not-allowed'} : { color: "#546FE6" }} disabled={page <= 1}>
                    <KeyboardArrowLeftIcon fontSize="inherit" />
                </IconButton>
            </div>
            <div className="centerPageCon">
                <div className="currentPage">{page}</div>
                <div className="lastPage">of</div>
                <div className="lastPage">{lastPage ? lastPage : 1}</div>
            </div>
            <div className="rightArrowCon">
                <IconButton aria-label="delete" size="small" onClick={onNext} style={page >= lastPage ? {cursor: 'not-allowed'} : { color: "#546FE6" }} disabled={page >= lastPage}>
                    <KeyboardArrowRightIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="delete" size="small" onClick={onLast} style={page >= lastPage ? {cursor: 'not-allowed'} : { color: "#546FE6" }} disabled={page >= lastPage}>
                    <LastPageIcon fontSize="inherit" />
                </IconButton>
            </div>
        </div>
    </div>
  );
};

export default CommonPagination;
