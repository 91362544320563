import React, { useEffect, useState } from 'react'
import '../../assets/css/roche.css'
import '../../assets/css/login.css'
import { CiSearch } from "react-icons/ci";
import CountryFlag from './country'
import { useNavigate } from 'react-router-dom';
import BackgroundBg from '../../assets/images/logo-bg.png'
import { API, MOCKUP_CANCERTYPE } from "../../constants/constant";
import axios from '../../http/axios'

import {
    Box,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { debounce } from 'lodash'
import { useCallback, useMemo } from 'react'
import store from '../../redux/store/store'
import { setLogout } from '../../redux/actions/actions'

const Style = {

    dropdownTitle: {
        fontSize: 14,
        fontFamily: "Poppins-Regular",
        color: "#464A53",
    },
    selectRoche: {
        display: 'flex',
        minWidth: 170,
        height: 44,
        background: "#F1F8F8",
        borderRadius: 'none',
        border: '1px solid #F1F8F8'
    },
};
const Roche = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState("")
    const [debounceQuery, setDebounceQuery] = useState('')
    const [loader, setLoader] = useState(false)
    const [list, setList] = useState([])

    const sendRequest = useCallback((value) => {
        setDebounceQuery(value)
    }, []);
    const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, 1000);
    }, [sendRequest]);

    const handleChange = (e) => {
        setQuery(e.target.value)
        debouncedSendRequest(e.target.value);
    }

    const navigateLogin = () => {
        store.dispatch(setLogout());
        localStorage.clear();
        navigate('/')
    }
    const [cancerType, setCancerType] = useState({
        list: [],
        selectedItems: [],
        value: "",
        error: false,
    });
    useEffect(() => {
        const data = localStorage.getItem("AllCancerType")
        setCancerType({ ...cancerType, list: JSON.parse(data), selectedItems: [JSON.parse(data)?.[0]?.value] })
    }, [])

    const handleCancerTypeChange = (event) => {
        const {
            target: { value },
        } = event;
        setCancerType({
            ...cancerType,
            selectedItems: typeof value === "string" ? value.split(",") : value,
        });
    };

    const getCountryList = () => {
        let config = {
            method: 'get',
            url: `${API.getAllCountryByCancer}?cancer_type_id=${cancerType?.selectedItems?.[0]}&keyword=${query}`,

        };
        setLoader(true)
        axios.request(config).then(itm => {
            setList(itm?.data?.data)
            setLoader(false)
        })
            .catch(err => {
                setLoader(false)
            })
    }

    useEffect(() => {
        if (cancerType?.selectedItems?.length) {
            getCountryList()
        }
    }, [cancerType, debounceQuery])

    return (
        <>
            <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
                <div className='outer'>
                    <div className='inner-for2' style={{ borderRadius: 8 }}>
                        <div className='d-flex flex-row select-row select-country-row' style={{position: 'relative'}}>
                            <div className='d-flex flex-column'>
                                <p className='sel'>Select Country</p>
                            </div>
                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', margin: '0px 26px' }}>
                                <InputLabel
                                    style={{
                                        position: 'absolute',
                                        top: -25,
                                        right: -35,
                                        color: '#546FE6',
                                        fontSize: 20,
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        fontFamily: 'Poppins-Bold'
                                    }}
                                    onClick={navigateLogin}
                                >
                                    {"Exit"}
                                </InputLabel>
                            </Box>
                        </div>
                        <div className='d-flex flex-row ' style={{paddingRight: '18px'}}>

                            <div className='d-flex flex-column col-8'>
                                <div className='ser-div'>
                                    <span className='sear-icon' id="ser-icon-roche"><CiSearch size="21" color='#4285F4' /></span>
                                    <span className='sear-icon' id="ser-icon-roche"><CiSearch size="21" color='#4285F4' /></span>
                                    <input className='ser' type="search" onChange={handleChange} value={query} placeholder='Search' />
                                </div>
                            </div>
                            {cancerType.selectedItems?.length && <div className='d-flex flex-column col-4 drop-roche'>
                                <Box style={{ padding: 0, flex: 1 }}>
                                    <Select
                                        value={cancerType.selectedItems[0]}
                                        onChange={handleCancerTypeChange}
                                        style={Style.selectRoche}
                                        className="select-countryflag"
                                        defaultValue={cancerType.selectedItems[0]}
                                    >
                                        {(cancerType.list || []).map((item) => {
                                            return <MenuItem value={item.value}>{item.name}</MenuItem>;
                                        })}
                                    </Select>
                                </Box>
                            </div>}

                        </div>
                        <div>
                            <CountryFlag list={list} loader={loader} setLoader={setLoader} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Roche