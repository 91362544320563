import React, { useEffect, useState } from 'react';

import { Box, FormLabel, InputLabel, Paper } from '@mui/material';



const styles = {



}





const ToggleWithText = (props) => {
    const [isEnabled, setIsEnabled] = useState(false)
    useEffect(()=>{setIsEnabled(props.isEnabled)},[props.isEnabled])
    const toggleSwitch = () => {
        if (props.isEdit) {
            props.callBack(!isEnabled)
            setIsEnabled((previousState) => !previousState)
        }
    }
    return (
        <span style={{ cursor: 'pointer' }} onClick={toggleSwitch} >
            <Box sx={{ display: 'flex', flexDirection: 'column', background: props.isEdit && isEnabled ? '#01CA54' : '#E0E0E0', width: 120, height: 30, borderRadius: 15, cursor: props.isEdit ? 'pointer' : "not-allowed" }}>

                <Box sx={{ height: 28, margin: 'auto 5px', borderRadius: 15, cursor: 'pointer' }}>

                    <Box sx={{ background: 'white', marginTop: 0.6, width: 20, height: 20, borderRadius: 15, position: 'relative', float: isEnabled ? 'right' : 'left', cursor: props.isEdit ? 'pointer' : "not-allowed" }} />

                    <InputLabel style={{ height: 28, display: 'flex', alignItems: 'center', paddingRight: 10, justifyContent: 'right', fontFamily: 'Poppins-Medium', fontSize: 16, color: isEnabled ? '#fff' : '#999999', cursor: props.isEdit ? 'pointer' : "not-allowed" }}>
                        {isEnabled ? 'Active' : 'Inactive'}
                    </InputLabel>
                </Box>

            </Box >
        </span>
            );

};



export default ToggleWithText;



