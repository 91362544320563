import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Link } from '@mui/material';
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom';
import ToggleWithText from '../../../common/control/togglewithtext';
import axios from '../../../http/axios'
import { API } from '../../../constants/constant';
import { ReactComponent as EditIcon } from '../../../assets/webapp_icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/webapp_icon/delete.svg';
import CommonPagination from '../../../common/control/commonpagination';

const columns = [
  { id: "facilityname", label: "Facility Name", minWidth: 200, l: 33 },
  { id: "location_name", label: "Location", minWidth: 200 },
  { id: "region_name", label: "Region", minWidth: 170 },
  { id: "facility_status", label: "Status", minWidth: 70 },
  { id: "action", label: "Action", minWidth: 50, align: "center" },
];





const style = {
  spinner: {
    display: 'flex', position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  }
}


const FacilityTable = (props) => {
  const { query, debounceQuery, page, setPage } = props;
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (newPage) => {
        setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(1);
  };




  const navigate = useNavigate();
  const onEdit = (rowItem) => {
    if(rowItem?.facility_status == "0") return
    navigate('edit-facility', {
      state: {
        title: 'Edit Facility!',
        isEdit: true,
        rowItem
      }
    })
  }

  const onDelete = (facilitycode) => {
    navigate('delete', { state: { title: "Delete", res: "Are you sure you want to delete the user ?", apiPath: API.deleteNLAFacility, apiData: { id: parseInt(facilitycode) } } })
  }

  useEffect(() => {
    if (!state)
      getAllFacility()
  }, [state, debounceQuery,page,rowsPerPage]);

  const getAllFacility = () => {
    // setApiData([])
    setIsLoading(true)
    axios.get(API.getFetchFacility, {
      params: {
        search_text: query || '',
        page_no: page,
        limit: rowsPerPage
      }
    })
      .then((res) => {
        const { data: { data: { facilities } } } = res
        setApiData(facilities)
        setTotalCount(res?.data?.data?.totalCount)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        setIsLoading(false)
      });
  }


  const handleToggleBack = async (status, rowItem) => {
    console.log(status)
    var data = JSON.stringify({ status: status ? '1' : '0', facilitycode: rowItem?.facilitycode });
    await axios.put(API.updateFacilityStatus, data);
    getAllFacility();
  }

  return (
    <>
      <Box sx={{ width: "100%", marginBottom: 2,boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA", borderRadius: "8px", background: '#FFFFFF', }}>
        {isLoading && <Box sx={style.spinner}>
          <CircularProgress />
        </Box>}
        <TableContainer sx={{
          height: "calc(100vh - 220px)", overflow: "auto",  borderRadius: "8px", 
        }}>
          <Table >
            <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
              <TableRow style={{ background: '#F9F9F9 0% 0% no-repeat padding-box', height: "60px" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, color: '#63799B',fontSize: 16, fontFamily: 'Poppins-Medium' }}
                  >
                    <Box style={{ paddingLeft: column.l }}>
                      {column.label}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(apiData || [])               
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      style={{ height: 60 }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
                            {column.id == "addkey" ? (
                              <Link
                                href="#"
                                underline="always"
                                style={{
                                  color: "#1542F2",
                                  fontWeight: 600,
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                Add Keywords
                              </Link>
                            ) : column.id == "facility_status" ? (
                              <>
                                <ToggleWithText isEdit={true} isEnabled={row.facility_status === '1'} callBack={(e) => handleToggleBack(e, row)} />
                              </>
                            ) :

                              column.id == "action" ? (
                                <div className='d-flex flex-row table-action gap-3'>
                                  <div onClick={() => onEdit(row)}>
                                    <EditIcon style={row?.facility_status == "0" ? {
                                                        cursor: "not-allowed",  marginRight: '10px'
                                                    } : {cursor: "pointer", marginRight: '10px'}} />
                                  </div>
                                  <div onClick={() => onDelete(row.facilitycode)}>
                                    <DeleteIcon style={ {cursor: "pointer"}} />
                                  </div>
                                </div>
                              ) : (
                                <Box style={{ marginLeft: column.l }}>
                                  {value}
                                </Box>
                              )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {isLoading ? null : apiData?.length == 0 && ( <TableRow> <TableCell colSpan={6} style={{textAlign: 'center'}}>No records found</TableCell> </TableRow> )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CommonPagination
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
    </>
  );
};

export default FacilityTable;
