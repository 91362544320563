
export function setLoginInfo(data) {
  return {
    type: 'LOGINFO',
    payload: data,
  };
}
export function setLogout() {
  return {
    type: 'LOGOUT',
  };
}

export function setUserToken(data) {
  return {
    type: 'USER_TOKEN',
    payload: data,
  };
}

export function setCountryId(data) {
  return {
    type: 'COUNTRY_ID',
    payload: data,
  };
}

export function setUserRefreshToken(data) {
  return {
    type: 'REFRESH_TOKEN',
    payload: data,
  };
}

export function setRegion(data) {
  return {
    type: 'REGION_LIST',
    payload: data,
  };
}
export function setCancerType(data) {
  return {
    type: 'CANCERTYPE_LIST',
    payload: data,
  };
}


export function setAPISuccess(data) {
  return {
    type: 'API_SUCCESS',
    payload: data
  };
}

export function setAPIError(data) {
  return {
    type: 'API_ERROR',
    payload: data
  };
}


