import React, { useEffect, useState } from 'react'
import '../../../assets/css/userRole.css'
import { AiFillPlusCircle } from 'react-icons/ai'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { AiOutlineSearch } from 'react-icons/ai'
import Popup from '../popup';
import RoleTable from './roletable'
import { Outlet, useNavigate } from 'react-router-dom';
import TopBarImage from '../../../assets/images/navigator/topbar.png'
import { BsArrowLeft, BsSearch } from "react-icons/bs";
import { TopNavigation } from '../../../common/control/topnavigation'
import PlusI from '../../../assets/images/navigator/plus.png'
import axios from '../../../http/axios'
import { API } from '../../../constants/constant'
import { Box, CircularProgress } from '@mui/material'
import { CiSearch } from "react-icons/ci";
import { useCallback } from 'react'
import { useMemo } from 'react'
import { debounce } from 'lodash'

const style = {
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }
}
const UserRole = () => {
    const [userRole, setUserRole] = useState({
        list: [],
        selectedItems: [],
        name: '',
        error: false,
    });
    const [allFacility, setAllFacility] = useState({
        list: [],
        selectedItems: [],
        name: '',
        error: false,
    });
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [debounceQuery, setDebounceQuery] = useState('')
    const navigate = useNavigate();
    const [query, setquery] = useState('')
    const _onTopNavButtonCallBack = () => {
        navigate('add-user', {
            state: {
                title: 'Add PN or NLA!',
                isEdit: false,
                userRole,
                allFacility
            }
        })
    }
    const sendRequest = useCallback((value) => {
        setPage(1)
        setDebounceQuery(value)
      }, []);
      const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, 1000);
      }, [sendRequest]);
    const handleChange = (e) => {
        setquery(e.target.value)
        debouncedSendRequest(e.target.value);
    }
    useEffect(() => {
        setIsLoading(true)
        commonAPIcall()
    }, []);

    const commonAPIcall = async () => {
        const promiseArray = [axios.get(API.getUserRoles), axios.get(API.getAllFacility)]
        try {
            const commonData = await Promise.all(promiseArray)
            const _userRole_data = (commonData[0]?.data?.data || []).map(userRole => {
                return {
                    name: userRole.role_name,
                    value: userRole.role_id.toString(),
                    _id: userRole.role_id.toString(),
                }
            })
            setUserRole({
                list: _userRole_data || [],
                selectedItems: _userRole_data?.length && [_userRole_data[0]?.value],
                name: _userRole_data?.length && _userRole_data[0]?.name || '',
                error: false,
            })
            const _facility_data = (commonData[1].data?.data?.facilities || []).map(facility => {
                return {
                    name: facility.facilityname,
                    value: facility.facilitycode.toString(),
                    _id: facility.facilitycode.toString(),
                }
            })
            setAllFacility({
                list: _facility_data || [],
                selectedItems: _facility_data?.length && [_facility_data[0]?.value],
                name: _facility_data?.length && _facility_data[0]?.name || '',
                error: false,
            })
            setIsLoading(false)
        } catch(err) {
            alert(err?.message)
            setIsLoading(false)
        }
        
    }
    // Created new PR
    return (
        <>
            <div style={{ background: '#F6F7FA', width: '100%', height: '95px' }}>
                <TopNavigation navPath={'/NLA'} title={'PN & NLA'}
                    button={{ startIcon: PlusI, name: 'Add PN or NLA', width: '206px', _onTopNavButtonCallBack }}
                >
                    <box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "72.97px"
                        }}
                    >
                        <box className='search-search ttt'>
                            <span className='search-icon'><CiSearch color='#4285F4' size={28}/></span>
                            <span className='search-icon'><CiSearch size={28} color='#4285F4' /></span>
                            <input className="search-box" type="search" value={query} onChange={handleChange} placeholder='Search' style={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }} />
                        </box>
                    </box>
                </TopNavigation>

                <div style={{ padding: "26.5px 75px 0px 73.97px" }}>
                    <RoleTable query={query} debounceQuery={debounceQuery} userRole={userRole} allFacility={allFacility} page={page} setPage={setPage} />
                </div>
                <Outlet />
            </div>
        </>
    )
}
export default UserRole