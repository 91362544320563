export const _storeData = async (key, data) => {
    try {
        await localStorage.setItem(
            key,
            JSON.stringify(data),
        );
    } catch (error) {
        // Error saving data
    }
};

export const _retrieveData = async (key) => {
    try {
        const value = await localStorage.getItem(key);
        if (value !== null) {
            return JSON.parse(value)
        }
    } catch (error) {
        // Error retrieving data
    }
};

export const _removeData = async (key) => {
    try {
        await localStorage.removeItem(key);
        return true;
    }
    catch (exception) {
        return false;
    }
};