import React from "react";
import { connect, useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";
import store from "../../redux/store/store";
import { API, NAVIGATIONS_PATH, STORAGE_KEY, USER_ROLE } from "../../constants/constant";
import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import axios from "../../http/axios";
import { setCancerType, setRegion } from "../../redux/actions/actions";
import { _storeData } from "./localstore";


const PrivateAuth = (props) => {
    const { country_id } = useSelector(state => state.auth)
    const getDefaultData = async () => {
        try {
            if (country_id) {
                const promiseArray = [axios.get(API.getAllRegions, { params: { country_id } }), axios.get(API.getAllCancerType)]
                const response = await Promise.all(promiseArray)
                await setCommonData(response)
            }
        }
        catch (error) {
            console.log("error-PrivateAuth", error)
            // alert(error?.message)
        }
    }

    const setCommonData = async (commonData) => {
        if (commonData.length > 1) {
            const _regions_data = (commonData?.[0]?.data?.data || []).map(region => {
                return {
                    name: region?.region_name,
                    value: region?.region_id?.toString(),
                    _id: region?.region_id?.toString(),
                }
            })
            const _cancerType_data = (commonData?.[1]?.data?.data || []).map(cancerType => {
                return {
                    name: cancerType?.cancer_type_name,
                    value: cancerType?.cancer_type_id,
                    _id: cancerType?.cancer_type_id,
                }
            })
            store.dispatch(setRegion(_regions_data))
            store.dispatch(setCancerType(_cancerType_data))
            await _storeData(STORAGE_KEY.Regions, _regions_data)
            await _storeData(STORAGE_KEY.CancerTypes, _cancerType_data)
        }
    }

    useEffect(() => {   
        getDefaultData()
    }, [])
    const data = store?.getState()?.auth;
    return data.isAuthUser ? props.children : <Navigate to={'/'} />
};
export default PrivateAuth