import React from 'react'
import '../../assets/css/splash.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navigator from '../../assets/images/navigator/navigator.png'
import Next from '../../assets/images/next/next.png'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoImg } from '../../assets/webapp_icon/Logo/final_logo_splash.svg';
import { useEffect } from 'react'
const Splash = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate('/login');
        }, 7000)
    }, [])
    return (
        <>
            <div className="splash">
                <div className='main-box'>
                    <LogoImg />
                    {/* <div class="loader"></div> */}
                    <div class="loadingio-spinner-spin-9r77fcqv1dg"><div class="ldio-oauhw8tti3l">
                    <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
                    </div></div>
                </div>
                <div className='footer'>
                    <div style={{ paddingRight: "40px" }}>
                        <span style={{ fontFamily: "Montserrat-Medium", fontSize: 18, color: "#84818A", marginRight: "6px" }}>Powered by</span>
                        <a href='javascript:void(0)' style={{ fontFamily: "Montserrat-Bold", fontSize: 18, color: "#1542F2", pointerEvents: "none" }}>Yoda Tech Pte Ltd</a>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Splash