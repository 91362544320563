export function setUndefinedBarrierList(data) {
  return {
    type: 'GET_UNDEFINED_BARRIER_LIST',
    payload: data?.Description_List,
    count: data?.totalCount
  };
}

export function setDefinedBarrierList(data) {
  return {
    type: 'GET_DEFINED_BARRIER_LIST',
    payload: data?.Keyword_List,
    count: data?.totalCount
  };
}

export function setPublishedBarrierList(data) {
  return {
    type: 'GET_PUBLISHED_BARRIER_LIST',
    payload: data?.Published_Barriers,
    count: data?.totalCount
  };
}

export function setAllUndefinedBarrierList(data) {
  return {
    type: 'GET_ALL_UNDEFINED_BARRIER_LIST',
    payload: data?.Undefined_Barriers,
    count: data?.totalCount
  };
}

export function setBarrierDropdownData(data) {
  return {
    type: 'SET_BARRIER_DROPDOWN_DATA',
    payload: data,
  };
}

export function setBarrierPage(data) {
    return {
      type: 'SET_BARRIER_PAGE',
      payload: data,
    };
  }

export function setBarrierLoader(data) {
  return {
    type: 'SET_BARRIER_LOADER',
    payload: data,
  };
}
export function getJournetPhase(data) {
  return {
    type: 'GET_JOURNEY_PHASE',
    payload: data,
  };
}
export function getProcess(data) {
  return {
    type: 'GET_PROCESS',
    payload: data,
  };
}
export function getCompletionRate(data) {
  return {
    type: 'GET_COMPLETION_RATE',
    payload: data,
  };
}
export function getBarriesFigure(data) {
  return {
    type: 'GET_BARRIERS_FIGURE',
    payload: data,
  };
}
export function getBarries(data) {
  return {
    type: 'GET_BARRIERS',
    payload: data,
  };
}
export function getAllInsightsDays(data) {
    return {
      type: 'GET_ALL_INSIGHTS_DAYS',
      payload: data,
    };
  }
export function getRiskFactors(data) {
  return {
    type: 'GET_RISK_FATORS',
    payload: data,
  };
}
export function getReferrals(data) {
  return {
    type: 'GET_REFERRALS',
    payload: data,
  };
}
export function getTravel(data) {
  return {
    type: 'GET_TRAVEL',
    payload: data,
  };
}
export function getAppUsage(data) {
  return {
    type: 'GET_APP_USAGE',
    payload: data,
  };
}
export function getDeath(data) {
  return {
    type: 'GET_DEATH',
    payload: data,
  };
}
export function setExport(data) {
  return {
    type: 'SET_EXPORT',
    payload: data,
  };
}
export function setExportLoader(data) {
  return {
    type: 'SET_EXPORT_LOADER',
    payload: data,
  };
}