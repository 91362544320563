import * as React from "react";
import {
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Box, Link } from "@mui/material";
import ProcessStatistics from "../component/process";
import JourneyPhase from "../component/journeyphase";
import StatisticsOtherSection from "../component/othersections";
import Barriers from "../component/barriers/index";
import Completion from "../component/completion/index";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";
import "../../../../assets/css/pdf.css";
import Logo2Img from "../../../../assets/webapp_icon/Logo/logo2_2px.png";
import Logo1Img from "../../../../assets/webapp_icon/Logo/logo12x.png";
import { ReactComponent as RedUpArrow } from '../../../../assets/webapp_icon/reduparrow.svg';
import { useEffect } from "react";



const ReportPdf = () => {

  // const exportAsImage = async (el, imageFileName) => {

  //     let element = document.querySelector("#pdfReport")
  //     const canvas = await html2canvas(element, {
  //         allowTaint: true,
  //         useCORS: true,
  //         logging: false,
  //         height: 3600,
  //         windowWidth: 2200,
  //         width: 2200,
  //         windowHeight: 3600,
  //         scrollX: 0,
  //         scrollY: 15
  //     });
  //     const imgData = canvas.toDataURL();
  //     const pdf = new jsPDF("p", "pc", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
  //     const imgY = 0;
  //     pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
  //     pdf.save("document.pdf")
  // };

  useEffect(() => {
    const exportAsImage = async () => {
      let element = document.querySelector("#pdfReport");
      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: 3600,
        windowWidth: 2200,
        width: 2200,
        windowHeight: 3600,
        scrollX: 0,
        scrollY: 15,
      });
      const imgData = canvas.toDataURL();
      const pdf = new jsPDF("p", "pc", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("document.pdf");
    };
    exportAsImage();
  }, []);


  return (
    <>
      <Box className="mainContainer" id="pdfReport" >
        <Box className="header">
          <img src={Logo1Img} alt="Logo" className="logo1" />
          <Box className="headerText">
            <Typography className="report-title" style={{ fontFamily: "Poppins-Regular" }}>Statistics Report - April 2023</Typography>
            <Box className="reportRow">
              <Box className="reportText">
                <Typography className="reportText1">Cancer Type</Typography>
                <Typography className="reportText2">Cervical Cancer</Typography>
              </Box>
              <Box className="verticalLine"></Box>
              <Box className="reportText">
                <Typography className="reportText1">Region</Typography>
                <Typography className="reportText2">National Average</Typography>
              </Box>
            </Box>
          </Box>
          <img src={Logo2Img} alt="Logo" className="logo2" />
        </Box>

        <Box className="section">
          <Box className="overview">
            <Box className="overviewTime"></Box>
            <Box className="overviewRow">
              <Typography className="overviewTitle">Patient Journey Overview</Typography>
              <Box className="overviewBox">
                <Box className="overviewBox1">
                  <Typography className="overviewBoxHead">170 Days</Typography>
                  <Box className="overviewBox12">
                    <RedUpArrow className="arrowUp" />
                    <Typography className="textUp">10%</Typography>
                  </Box>
                </Box>
                <Typography className="overviewBox2">1st Screening suspicion - 1st Treatment Initiation</Typography>
              </Box>
            </Box>
            <Box className="overviewTime">
              <Typography className="timeUpdate">Last Updated : 21 May 2023 | 3:30 PM</Typography>
            </Box>
          </Box>
          <Box style={{ padding: "0px 12px" }}>
            <JourneyPhase />
            <ProcessStatistics />
            <Completion />
            <Barriers />
            <StatisticsOtherSection />
          </Box>

        </Box>
      </Box>

    </>
  );

};

export default ReportPdf;

