import * as React from "react";
import '../../assets/css/loading.css'
const CustomLoader = (props) => {
    // const [labels, setLables]=React.useState('Loading...');

    // React.useEffect(() => {
    //   setTimeout(() => {
    //     setLables("Loading in 10 Sec...");
    //   }, 2000);
    //   setTimeout(() => {
    //     setLables("Please stand by !!");
    //   }, 6000);
    //   setTimeout(() => {
    //     setLables("Loading in 20 Sec...");
    //   }, 12000);
    //   setTimeout(() => {
    //     setLables("Please stand by !!");
    //   }, 16000);
    //   setTimeout(() => {
    //     setLables("Loading in 30 Sec...");
    //   }, 30000);
    //   setTimeout(() => {
    //     setLables("Please stand by !!");
    //   }, 36000);
    // }, []);
    // setTimeout(()=>{
    //     setLables("Please stand by !!")
    // },14000)
    return (
        <div className="mainLoader">
            <div className="loading">
                <span>{"Loading in 30 Sec..."}</span>
                <span>Please stand by !!</span>
                {/* <span></span> */}
            </div>
        </div>
    );
};
export { CustomLoader }