import React, { useMemo, useState } from 'react'
import '../../../assets/css/userRole.css'
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/pnp.css'
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../../../assets/css/theme.css";
import { CiSearch } from "react-icons/ci";
import axios from '../../../http/axios'
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TopNavigation } from "../../../common/control/topnavigation";
import { API} from "../../../constants/constant";
import CommonPagination from '../../../common/control/commonpagination'
import { useEffect } from 'react'
import CommonDropDown from '../../../common/control/commondropdown'
import { debounce } from 'lodash';
import { useCallback } from 'react';

const columns = [
  { id: "name", label: "Name", minWidth: 180, l: 33 },
  { id: "id", label: "ID No.", minWidth: 180 },
  { id: "facility", label: "Facility", minWidth: 180 },
  { id: "avg", label: "Avg time", minWidth: 130, align: "center" },
];

const Style = {

  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
  },
  select: {
    display: 'flex',
    minWidth: 170,
    height: 46.48,
    background: "#ffffff",
    borderRadius: 4,
    border: '1px solid #f4f6fa',
  },
  spinner: {
    display: 'flex', position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
}
};

const PnpOnline = () => {
  const navigate = useNavigate();
  const [query, setquery] = useState('')
  const [debounceQuery, setDebounceQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dropdownData, setDropdownData] = useState(null)
  const sendRequest = useCallback((value) => {
    setPage(1)
    setDebounceQuery(value)
  }, []);
  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000);
  }, [sendRequest]);

  const handleChange = (e) => {
    setquery(e.target.value)
    debouncedSendRequest(e.target.value);
  }

  const theme = createTheme({
    palette: {
      background: {
        paper: '#fff',
      },
      text: {
        primary: '#173A5E',
        secondary: '#46505A',
      }
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: '12',
            h4: 'h2',
            h5: 'h2',
            h6: 'h6',
            subtitle1: 'h2',
            subtitle2: 'p',
            body1: 'span',
            body2: 'span',
          },
          fontFamily: 'Montserrat-Bold',
          color: '#FFFFFF'
        },
      },
    },
  });

  useEffect(() => {
    if (dropdownData?.month?.selectedItems?.length && dropdownData?.regions?.selectedItems?.length && dropdownData?.year?.selectedItems?.length)
    // setPage(1);
    getAllPNP();
  }, [debounceQuery, page, rowsPerPage, dropdownData]);

  const getAllPNP = () => {
    // setApiData([])
    setIsLoading(true)
    axios.get(API.getAllPN, {
      params: {
        search_text: query || '',
        page_no: page,
        limit: rowsPerPage,
        month_id: dropdownData?.month?.selectedItems[0],
        region: dropdownData?.regions?.selectedItems[0],
        year: dropdownData?.year?.name
      }
    })

      .then((res) => {
        setApiData(res?.data?.data?.PN)
        setTotalCount(res?.data?.data?.totalCount)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)

      });
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const _dropdownSelectedItems = (item) => {
    setDropdownData(item);
    setPage(1);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ height: "100vh", backgroundColor: "#F6F7FA" }}>
          <TopNavigation navPath={'/NLA'} title={'Patient Navigator-Online'} >
            <box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <box className='search-search ttt'>
                <span className='search-icon'><CiSearch size={28} color='#4285F4' /></span>
                <input className="search-box" type="search" value={query} onChange={handleChange} placeholder='Search' style={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }} />
              </box>
            </box>
          </TopNavigation>

          <Box
            sx={{
              margin: "10px 60px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
            maxWidth={1200}
          >
            <CommonDropDown callBack={_dropdownSelectedItems} hideCancerType={true} hideDuration={true} yearBoxStyle={{ padding: 0, flex: 1 }} />
          </Box>
          {/* {isLoading ? <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div> : */}
          <div className='m-table'>
            
            <Box sx={{ width: "100%",  boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA", borderRadius: "8px", marginBottom:2, background: '#FFFFFF', }}>
            {isLoading && <Box sx={Style.spinner}>
                    <CircularProgress />
                </Box>}
              <TableContainer sx={{
                 height: "calc(100vh - 266px)", overflow: "auto", borderRadius: "8px"
              }}>
                <Table>
                  <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
                    <TableRow style={{ height: "60px", background: '#F9F9F9', }}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Medium' }}
                        >
                          <Box style={{ paddingLeft: column.l }}>
                            {column.label}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {apiData?.map(itm => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          style={{ height: 60 }}
                        >
                          <TableCell style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px', paddingLeft: 50 }}> {itm?.full_name}</TableCell>
                          <TableCell style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>{itm?.identity_no}</TableCell>
                          <TableCell style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>{itm?.facility_name}</TableCell>
                          <TableCell style={{ textAlign: 'center', color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>{itm?.sessionDuration + " hours"}</TableCell>

                        </TableRow>
                      );
                    }) }
                    {isLoading ? null : apiData?.length == 0 && ( <TableRow> <TableCell colSpan={6} style={{textAlign: 'center'}}>No records found</TableCell> </TableRow> )}
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>

            <CommonPagination
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
                  {/* } */}
        </Box>
      </ThemeProvider >
    </>
  )
}
export default PnpOnline