import { Outlet } from "react-router-dom";


import {
  Box,
  CircularProgress,
} from "@mui/material";

import { useEffect, useState } from "react";
import "../../../assets/css/theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { API } from "../../../constants/constant";
import "../../../assets/css/patientJourney.css";
import PatientJourneyBox from "./patient-journey-box"
import { TopNavigation } from "../../../common/control/topnavigation";
import AllInsightsI from '../../../assets/images/navigator/allinsights.png'
import axios from '../../../http/axios'
import store from "../../../redux/store/store";
import { setJourneyDropdownData, setPatientJourneyData, setPatientJourneyLoader } from "../../../redux/actions/rocheactions";
import { useSelector } from "react-redux";
import CommonDropDown from "../../../common/control/commondropdown";
import { CustomLoader } from "../../../common/control/customloader";

const Style = {

  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
  },
  select: {
    height: 45,
    background: "#ffffff",
    borderRadius: 4,
    border: '1px solid #f4f6fa'
  },
  loader: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: "center",
    justifyContent: "center",
  }
};
const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: '12',
          h4: 'h2',
          h5: 'h2',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'p',
          body1: 'span',
          body2: 'span',
        },
        fontFamily: 'Montserrat-Bold',
        color: '#FFFFFF'
      },
    },
  },
});

const PatientJourney = (props) => {
  const { isLoading, dropdownData, journeyTotalDays } = useSelector(state => state.rocheReducer)
  const { country_id, loginInfo } = useSelector(state => state.auth)
  const navigate = useNavigate();
  const _onTopNavButtonCallBack = () => {
    navigate('/reports/allinsights');
  }

  const getJourneyData = (data) => {
    var config = {
      method: 'get',
      url: `${API.getPatientJourney}?cancer_type=${data?.cancerTypes?.selectedItems[0]}&duration=${data?.duration?.name}&duration_month=${data?.month?.selectedItems[0]}&region=${data?.regions?.selectedItems[0]}&year=${data?.year?.name}&country_id=${country_id}`
    };
    store.dispatch(setPatientJourneyLoader(true))
    axios.request(config)
      .then(function (response) {
        if (response?.data?.data?.data?.length) {
          store.dispatch(setPatientJourneyData(response?.data?.data))
        }
      })
      .catch(function (error) {
        console.log(error);
        store.dispatch(setPatientJourneyLoader(false))
      });
  }

  useEffect(() => {
    if (dropdownData?.regions?.name && dropdownData?.duration?.name && dropdownData?.cancerTypes?.name && dropdownData?.month?.name && dropdownData?.year?.name && country_id) {
      getJourneyData(dropdownData)
    }
  }, [dropdownData?.regions?.name, dropdownData?.duration?.name, dropdownData?.cancerTypes?.name, dropdownData?.month?.name, dropdownData?.year?.name])


  const _dropdownSelectedItems = (item) => {
    store.dispatch(setJourneyDropdownData(item))
  }

  const redirectToBack = () => {
    if(loginInfo?.user_role?.toUpperCase() === "NLA") {
      return '/NLA'
    } else if(loginInfo?.user_role?.toUpperCase() === 'ADMIN') {
      return '/roche-welcome'
    } else if(loginInfo?.user_role?.toUpperCase() === 'PN') {
      return '/welcome'
    }
  }

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ height: "100vh", backgroundColor: "#F6F7FA", overflow: "scroll" }}>
        <TopNavigation
          navPath={redirectToBack()}
          title={'Patient Journey'}
          button={{ startIcon: AllInsightsI, name: 'All Insights',width: '177px', _onTopNavButtonCallBack }}
        />

        <Box
          sx={{
            margin: "20px 40px",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <CommonDropDown callBack={_dropdownSelectedItems} onLoad />
          <div className="hr-row">
          </div>

          <box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 5,

            }}
            style={{ paddingTop: 4 }}
          >
            <box
              style={{
                fontSize: 24,
                fontFamily: "Poppins-SemiBold",
                color: "#554886",
              }}
            >
              {journeyTotalDays?.total_days || 0} Days
            </box >

            {!journeyTotalDays?.total_diff_mode ?
              <MdKeyboardArrowUp
                style={{
                  marginLeft: 8,
                  height: 25,
                  width: 25,
                  cursor: "pointer",
                  color: "#E65656",
                }}
              /> :
              <MdKeyboardArrowDown
                style={{
                  marginLeft: 8,
                  height: 25,
                  width: 25,
                  cursor: "pointer",
                  color: "#62E89C",
                }}
              />}
            <box
              style={{
                fontSize: '24px',
                fontFamily: "Poppins-Medium",
                color: !journeyTotalDays?.total_diff_mode ? "#E65656" : "#62E89C",
              }}
            >
              {journeyTotalDays?.total_diff}%
            </box>
          </box>
          {isLoading ? <div style={Style.loader}><CustomLoader /></div> :
            <PatientJourneyBox />
          }
          <Outlet />
        </Box>
      </Box >
    </ThemeProvider >
  );
};

export default PatientJourney;
