import { Outlet, Link, useLocation } from "react-router-dom";

import {
  Box,
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import "../../assets/css/theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BsArrowLeft } from "react-icons/bs";
import { BiSolidDownArrow } from "react-icons/bi";
import BarriersTable from './barriers-table';
import { useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import { MOCKUP_CANCERTYPE, MOCKUP_DURATION, MOCKUP_MONTH, MOCKUP_YEAR, MOCKUP_REGION } from "../../constants/constant";
import { TopNavigation } from "../../common/control/topnavigation";
import CommonDropDown from "../../common/control/commondropdown";
import { useCallback } from "react";
import { debounce } from "lodash";
import { useMemo } from "react";

const Style = {

  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
  },
  select: {
    // width: 301,
    height: 45,
    background: "#ffffff",
    borderRadius: 4,
    border: '1px solid #f4f6fa'
  },
};
const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: '12',
          h4: 'h2',
          h5: 'h2',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'p',
          body1: 'span',
          body2: 'span',
        },
        fontFamily: 'Montserrat-Bold',
        color: '#FFFFFF'
      },
    },
  },
});

const Barriers = () => {

  const [query, setquery] = useState('')
  const [debounceQuery, setDebounceQuery] = useState('')
  const [page, setPage] = useState(1);

  const sendRequest = useCallback((value) => {
    setPage(1)
    setDebounceQuery(value)
  }, []);
  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000);
  }, [sendRequest]);

  const handleChange = (e) => {
    setquery(e.target.value)
    debouncedSendRequest(e.target.value);
  }



  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/welcome');
  };

  const [regions, setRegions] = useState({
    list: [],
    selectedItems: [],
    name: '',
    error: false,
  });
  const [cancerType, setCancerType] = useState({
    list: [],
    selectedItems: [],
    name: '',
    error: false,
  });
  const [duration, setDuration] = useState({
    list: [],
    selectedItems: [],
    name: '',
    error: false,
  });
  const [month, setMonth] = useState({
    list: [],
    selectedItems: [],
    name: '',
    error: false,
  });
  const [year, setYear] = useState({
    list: [],
    selectedItems: [],
    name: '',
    error: false,
  });

  const _dropdownSelectedItems = (item) => {
    console.log(item)
    const { regions, cancerTypes, duration, month, year } = item;
    setRegions(regions)
    setCancerType(cancerTypes)
    setDuration(duration)
    setMonth(month)
    setYear(year)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "100vh", backgroundColor: "#F6F7FA", overflow: "auto" }}>
        <TopNavigation navPath={'/welcome'} title={'All Barriers'} >
          <box
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "72.97px"
            }}
          >
            <box className='search-search ttt'>
              <span className='search-icon'><CiSearch size={28} color='#4285F4' /></span><input className="search-box" type="search" value={query} onChange={handleChange} placeholder='Search' style={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }} />
            </box>
          </box>
        </TopNavigation>

        <Box
          sx={{
            margin: "10px 60px 0px 60px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <CommonDropDown callBack={_dropdownSelectedItems} />
          <box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 5,
            }}
          >
            <BarriersTable query={query} debounceQuery={debounceQuery} regionsid={regions?.selectedItems[0]} cancerTypeId={cancerType?.selectedItems[0]} duration={duration?.name} monthId={month?.selectedItems[0]} year={year?.name} page={page} setPage={setPage} />
          </box>
          <Outlet />
        </Box>
      </Box >
    </ThemeProvider >
  );
};

export default Barriers;
