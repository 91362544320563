import React, { useState } from 'react'
import '../../assets/css/login.css'
import { useLocation, useNavigate } from 'react-router-dom';
import Back from '../../assets/images/back.png'
import BackgroundBg from '../../assets/images/logo-bg.png'
import { MuiOtpInput } from 'mui-one-time-password-input'
import store from '../../redux/store/store';
import { setAPIError } from '../../redux/actions/actions';
import { HttpStatusCode } from 'axios';
import axios from '../../http/axios';
import { API } from '../../constants/constant';
import { Box, CircularProgress } from '@mui/material';
import _ from 'lodash';

const Style = {
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }
};

const OtpScreen = () => {
    const { state } = useLocation();
    const [otp, setOtp] = useState('')
    const [errors, setErrors] = useState({
        otp: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [passToken, setPassTocken] = useState(state?.password_token)
    const navigate = useNavigate();
    const navigateLogin = () => {
        navigate('/login');
    };
    const generateOtp = (e) => {
        e.preventDefault();
        setOtp('')
        setIsLoading(true)
        const FormData = require('form-data');
        let data = new FormData();
        data.append('mobile_number', `${state?.phone}`); //RV

        let config = {
            method: 'post',
            url: API.generateOTP,
            data: data,
        };
        axios.request(config)
            .then(response => {
                let { data: { password_token, status_code } } = response;
                setPassTocken(password_token)
                setIsLoading(false)

            })
            .catch(error => {
                setIsLoading(false);
                store.dispatch(setAPIError({
                    status: true,
                    title: 'Error',
                    message: error?.response?.message || error?.message
                }));
            });


    };

    const verifyOTP = (e) => {

        e.preventDefault();
        let errorsItem = {};
        if (!otp.length == 4) {
            errorsItem['otp'] = true;
        }

        if (!_.isEmpty(errorsItem)) {
            setErrors({ ...errors, ...errorsItem });
            return;
        }

        setIsLoading(true)
        const FormData = require('form-data');
        let data = new FormData();
        data.append('mobile_number', state?.phone);
        data.append('password_reset_token', passToken);
        data.append('otp', otp);

        let config = {
            method: 'post',
            url: API.verifyOTP,
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                let { data: { password_token, status_code } } = response;
                setIsLoading(false)
                navigate('/setPassword', { state: { ...state, password_token } });
            })
            .catch((error) => {
                setIsLoading(false)
                store.dispatch(setAPIError({
                    status: true,
                    title: 'Error',
                    message: error?.response?.message || error?.message
                }));
            });
    };
    return (
        <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
            {isLoading && <Box sx={Style.spinner}>
                <CircularProgress />
            </Box>}
            <div className='outer'>
                <div className='inner-for'>
                    <form className='form-otp'>
                        <p className="login-par-mobile">Get your code !</p>
                        <p className="login-innerpar1">Please enter the 4-digit code that has been sent to your mobile number.</p>
                        <div class="inputfield-otp">
                            <MuiOtpInput
                                value={otp}
                                onChange={setOtp}
                                TextFieldsProps={{
                                    placeholder: '-',
                                    className: 'input-otp',
                                    border: 'none'
                                }}

                            />
                        </div>

                        <p className='receive'>Haven't received the code?<span className='resend-txt' onClick={generateOtp}>Resend</span></p>

                        <div className="inner-form" >
                            <button type="submit" className="login-btn" onClick={verifyOTP}>Verify and Proceed</button>
                        </div>
                        <div className='d-flex flex-row justify-content-center forgot-row' onClick={navigateLogin}>
                            <div className='d-flex flex-column'>
                                <img className='back-img' src={Back} alt="" />
                            </div>
                            <div className='d-flex flex-column'>
                                <p className='back-login'>Back to login</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default OtpScreen