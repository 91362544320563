import { Box, Paper } from "@mui/material"
import React, { useState } from "react"
import store from "../../../../../../redux/store/store"
import _ from "lodash"

const data = [
    {
        name: 'HIV',
        desc: 'Average positive cases per month',
        value: 0.7,
        _id: '1'
    },
    {
        name: 'Alcohol',
        desc: 'Average drink per week',
        value: 1.2,
        _id: '2'
    },
    {
        name: 'Smoking',
        desc: 'Average cigarettes per day',
        value: 0.2,
        _id: '3'
    },
    {
        name: 'Exercise',
        desc: 'Average hours per week',
        value: 0.9,
        _id: '4'
    },
    {
        name: 'Meat',
        desc: 'Average portions per day',
        value: 0.2,
        _id: '5'
    },
    {
        name: 'First Born Age',
        desc: 'Average age',
        value: 9.2,
        _id: '6'
    },
    {
        name: 'No of Children',
        desc: 'Average no of children',
        value: 2.3,
        _id: '7'
    },
    {
        name: 'Age of 1st Sexual Intercourse',
        desc: 'Average Age',
        value: 18.3,
        _id: '8'
    }
]


const RiskFactors = () => {
    const [riskFactors, setRiskFactors] = useState([])
    React.useEffect(() => {
        let { riskFactors } = store?.getState()?.nlaReducer;
        setRiskFactors((riskFactors || []).map((item, i) => {
            return ({
                name: item?.title,
                desc: item?.description,
                value: Math.round(item?.value) || 0,
                _id: i,
            })
        }))
    }, [store?.getState()?.nlaReducer?.riskFactors])


    return (

        <Paper
            elevation={0}
            sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1 }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "24px"
                }}
            >
                {(riskFactors || []).map((item) => {
                    return (
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: 'space-between',
                                padding: "0px 0px 15px 0px",
                            }}
                            key={item._id}
                        >
                            <Box>
                                <h3
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        margin: 0, padding: 0
                                    }}>
                                    {item.name}
                                </h3>
                                <span
                                    style={{
                                        fontSize: 13,
                                        fontFamily: "Poppins-Regular",
                                        color: "#838383",
                                    }}>
                                    {item.desc}
                                </span>
                            </Box>
                            <Box>
                                <span
                                    style={{
                                        fontSize: 16,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                    }}>
                                    {item.value}
                                </span>
                            </Box>
                        </Box>
                    )
                })}
                 {
                _.isEmpty(riskFactors) && <span>No Data</span>
                }
            </Box>
        </Paper>
    )
}
export default RiskFactors