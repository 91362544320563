import * as React from "react";

import {
  Grid,
  InputLabel,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  LinearProgress,
  Tooltip
} from "@mui/material";
import { Box, Link } from "@mui/material";
import Divider from '@mui/material/Divider';
import "../../../../../assets/css/patientJourney.css";
import { ProgressBar } from 'react-bootstrap';
import CompletionRate from "./completion-rate";
import CompletionRate2 from "./completion-rate2";
import CompletionRate3 from "./completion-rate3";
import CompletionRate4 from "./completion-rate4";
import CompletionRate5 from "./completion-rate5";
import store from "../../../../../redux/store/store";
import { useState } from "react";
import { fills } from "../../../../../constants/constant";


const Completion = () => {

  const [completionRate, setCompletionRate] = useState([])
  React.useEffect(() => {
    let { completionRate } = store?.getState()?.nlaReducer;
    setCompletionRate((completionRate || []).map((item, i) => {
      return ({
        process: (item.data || []).map((processItem, i) => {
          return (
            {
              delay30DaysValue: processItem?.delay_30_days || 0,
              cancelledAppointment: processItem?.cancelled_appointment || 0,
              name: processItem?.journey_step_name || 'NA',
              _id: processItem?.journey_step_id || i,
              fill: processItem?.step_color,//fills[i],
              rate: Math.round(processItem?.completion_rate) || 0,
              isUp: processItem?.completion_rate_flag //RV
            }
          )
        }),
        sections: {
          completion: {
            value: Math.round(item?.average_completion_rate) || 0, //RV
            repitition: Math.round(item?.repetition_rate) || '0', //RV
            isUp: item?.repetition_rate_flag //RV
          },
          delayed: {
            value: Math.round(item?.milestone_gap) || '0',
          },
          cancelled: {
            value: Math.round(item?.discontinue_patient) || '0',
          },
        }
      })
    }))
  }, [store?.getState()?.nlaReducer?.completionRate])


  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      marginTop: '30px',
      flexWrap: "wrap",
      // alignContent: 'center'
    }}>
      <InputLabel
        style={{
          fontSize: 24,
          fontFamily: "Poppins-Medium",
          color: "#554886",
          marginLeft: '8px'
        }}
      >
        Completion Rate
      </InputLabel>

      <Box
        className="section-container"
        sx={{
          "& > :not(style)": {
            m: 1,
            flex: 1,
            height: 650,
            minWidth: 360
          },
        }}

      >
        {(completionRate || []).map((completionRateItem) => {
          return (
            <CompletionRate {...completionRateItem} />
          )
        })}

        {/* <CompletionRate2 />
        <CompletionRate3 />
        <CompletionRate4 />
        <CompletionRate5 /> */}
      </Box>
    </Box >
  )
}
export default Completion