import { Outlet, Link, useLocation } from "react-router-dom";

import {
  Box,
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import "../../assets/css/theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BsArrowLeft } from "react-icons/bs";
import { BiSolidDownArrow } from "react-icons/bi";
import UserTable from './user-table';
import { useNavigate } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { TopNavigation } from "../../common/control/topnavigation";
import "../../assets/css/patientJourney.css";
import { CiSearch } from "react-icons/ci";
import { useCallback } from "react";
import { useMemo } from "react";
import { debounce } from "lodash";


const Style = {

  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
  },
  select: {
    width: 250,
    height: 40,
    background: "#ffffff",
    borderRadius: 6,
    border: '1px solid #f4f6fa'
  },
};
const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: '12',
          h4: 'h2',
          h5: 'h2',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'p',
          body1: 'span',
          body2: 'span',
        },
        fontFamily: 'Montserrat-Bold',
        color: '#FFFFFF'
      },
    },
  },
});

const UserCredentials = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [query, setquery] = useState('')
  const [debounceQuery, setDebounceQuery] = useState('')

  const sendRequest = useCallback((value) => {
    setPage(1)
    setDebounceQuery(value)
  }, []);
  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000);
  }, [sendRequest]);

  const handleChange = (e) => {
    setquery(e.target.value)
    debouncedSendRequest(e.target.value);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/welcome');
  };




  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "100vh", backgroundColor: "#F6F7FA", overflow: "auto" }}>
        <TopNavigation navPath={'/welcome'} title={'User Credentials'} >
          <box
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "72.97px"
            }}
          >
            <box className='search-search ttt'>
              <span className='search-icon'><CiSearch size={28} color='#4285F4' /></span>
              <span className='search-icon'><CiSearch size={28} color='#4285F4' /></span>
              <input className="search-box" type="search" value={query} onChange={handleChange} placeholder='Search' style={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }} />
            </box>
          </box>
        </TopNavigation>

        <Box
          sx={{
            margin: "20px 60px 0px 60px",
            display: "flex",
            flexDirection: "column",
          }}
        >     
            <UserTable query={query} debounceQuery={debounceQuery} setPage={setPage} page={page} />
          
          <Outlet />
        </Box>
      </Box >
    </ThemeProvider >
  );
};

export default UserCredentials;
