import { Box, Divider, InputLabel, Paper } from "@mui/material"
import RiskFactors from "./riskfactors"
import Referral from "./referrals"
import store from "../../../../../redux/store/store"
import React, { useEffect, useState } from "react"
import {ReactComponent as LeafImg} from "../../../../../assets/webapp_icon/leaf.svg"

const Travel = () => {
    const [travel, setTravel] = useState(undefined)
    useEffect(() => {
        let { travel } = store?.getState()?.nlaReducer;
        setTravel(travel)
    }, [store?.getState()?.nlaReducer?.travel])

    return (
        <Paper
            elevation={0}
            sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, padding: '20px',paddingTop : '5px', height: 122 }}
        >
            <Box style={{ display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
            <LeafImg/>
            </Box>

            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'space-between',
                        padding: "10px 0",
                    }}>
                    <InputLabel
                        style={{
                            fontSize: 13,
                            fontFamily: "Poppins-Medium",
                            color: "#237BBC",
                        }}
                    >
                        {"AVERAGE TRAVEL (KM)"}
                    </InputLabel>


                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            padding: "10px 0",
                        }}
                    >
                        <h3
                            style={{
                                fontSize: 25,
                                fontFamily: "Poppins-SemiBold",
                                color: "#00133A",
                                paddingRight: 10
                            }}
                        >
                            {travel?.average_travel}
                        </h3>
                    </Box>
                </Box>

                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'space-between',
                        padding: "10px 0",
                    }}>
                    <InputLabel
                        style={{
                            fontSize: 13,
                            fontFamily: "Poppins-Medium",
                            color: "#237BBC",
                        }}
                    >
                        {"CO2 EMISSION AVERAGE"}
                    </InputLabel>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                            justifyContent: 'center',
                            padding: "10px 0",
                        }}>
                        <h3
                            style={{
                                fontSize: 25,
                                fontFamily: "Poppins-SemiBold",
                                color: "#242424",
                            }}
                        >
                            {`${travel?.co2_average_percentage || 0}%`}
                            {/* RV */}
                        </h3>
                        <Box
                            style={{
                                height: 20,
                                width: 4,
                                margin: '4px 10px',
                                background: "#242424",
                            }}
                        />
                        <h3
                            style={{
                                fontSize: 25,
                                fontFamily: "Poppins-SemiBold",
                                color: "#00133A",
                            }}
                        >
                            {travel?.co2_average}
                        </h3>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}
export default Travel