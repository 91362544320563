import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { Button, Link, Box, CircularProgress } from '@mui/material';
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from '../popup';
import SettingsPop from '../settingsPopup'
import ToggleWithText from '../../../common/control/togglewithtext';
import { ReactComponent as EditIcon } from '../../../assets/webapp_icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/webapp_icon/delete.svg';
import axios from '../../../http/axios'
import { API } from '../../../constants/constant';
import { async } from 'q';
import CommonPagination from '../../../common/control/commonpagination';

const columns = [
  { id: "user_name", label: "User Name", minWidth: 200, l: 33 },
  { id: "facilityname", label: "Facility", minWidth: 200 },
  { id: "role_name", label: "Role", minWidth: 170 },
  { id: "user_status", label: "Status", minWidth: 70 },
  { id: "action", label: "Action", minWidth: 50, align: "center" },
];

const style = {
  spinner: {
    display: 'flex', position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  }
}



function createData(name, facility, role,) {
  return { name, facility, role };
}


const RoleTable = (props) => {
  const { userRole, allFacility, page, setPage } = props;
  const {state} = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };


  useEffect(() => {
    
    if(!state)
    getAllUser()
  }, [props?.debounceQuery, page, rowsPerPage, state]);

  const getAllUser = () => {
    setIsLoading(true)
      
    axios.get(API.getFetchUsers, {
      params: {
        search_text: props?.query || '',
        page_no: page,
        limit: rowsPerPage
      },
    })
      .then((res) => {
        const { data: { data: { users } } } = res
        setAllUser(users)
        setTotalCount(res?.data?.data?.totalCount)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        alert(error?.message)
      });
  }

  const handleToggleBack = (isenable, item) => {
    setIsLoading(true)
    const data = {
      status: item.user_status == 0 ? 1 : 0,
      user_id: parseInt(item.user_id)
    };
    console.log(data);
  
    axios.put(API.updateNlaPnUserStatus,
      data,
    )
      .then((res) => {
        console.log("Data updated successfully:", res.data);
        getAllUser();
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setIsLoading(false)
      });
      
  }


  const navigate = useNavigate();
  const onEditPopup = (rowItem) => {
    if(rowItem?.user_status == "0") return
    navigate('edit-user', {
      state: {
        title: 'Edit PN or NLA!',
        isEdit: true,
        rowItem,
        userRole: { ...userRole, selectedItems: [rowItem.role_id], name: rowItem.role_name },
        allFacility: { ...allFacility, selectedItems: [rowItem.facilitycode], name: rowItem.facilityname },
      }
    })
  }

  const onDelete = (rowItem) => {
    navigate('delete', { state: { title: "Delete", res: "Are you sure you want to delete the user ?", apiPath: API.deleteNLAUser, apiData: { id: rowItem?.user_id } } })
  }


  return (
    <>
      <Box sx={{ width: "100%", marginBottom: 2, boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA", borderRadius: "8px" }}>

        {isLoading && <Box sx={style.spinner}>
          <CircularProgress />
        </Box>}

        <TableContainer  sx={{
            height: "calc(100vh - 220px)", overflow: "auto", borderRadius: "8px",
        }}>
          <Table>
            <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
              <TableRow style={{  height: "60px", background: '#F9F9F9' }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Medium' }}
                  >
                    <Box style={{ paddingLeft: column.l }}>
                      {column.label}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(allUser || [])
                .map((row) => {
                  
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      style={{ height: 60 }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        console.log(value,"value")
                        return (
                          <TableCell key={column.id} align={column.align} style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
                            {column.id == "addkey" ? (
                              <Link
                                href="#"
                                underline="always"
                                style={{
                                  color: "#1542F2",
                                  fontWeight: 600,
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                Add Keywords
                              </Link>
                            ) : column.id == "user_status" ? (
                              <>
                                <ToggleWithText isEdit={true} isEnabled={parseInt(row.user_status) == 1} callBack={(e) => { handleToggleBack(e, row) }} />
                              </>
                            ) :

                              column.id == "action" ? (
                                <div className='d-flex flex-row table-action gap-3'>
                                  <div onClick={() => onEditPopup(row)}>
                                    {/* <EditIcon style={{ marginRight: '10px' }} /> */}
                                    <EditIcon style={row?.user_status == "0" ? {
                                                        cursor: "not-allowed",  marginRight: '10px'
                                                    } : {cursor: "pointer", marginRight: '10px'}} />
                                  </div>
                                  <div onClick={() => onDelete(row)}>
                                    <DeleteIcon style={ {cursor: "pointer"}} />
                                  </div>
                                </div>
                              ) : (
                                <Box style={{ marginLeft: column.l }}>
                                {column.id == "user_name" ? 
                                 row.first_name + " " + row.last_name 
                                :
                                column.id == "facilityname" ? 
                                row?.facility_status == "1" ? value : ""
                                :
                                  value}
                                </Box>
                              )}
                          </TableCell>
                        );
                      })}
                       
                    </TableRow>
                      
                  );
                })}
                {isLoading ? null : allUser?.length == 0 && ( <TableRow> <TableCell colSpan={6} style={{textAlign: 'center'}}>No records found</TableCell> </TableRow> )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CommonPagination
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default RoleTable;
