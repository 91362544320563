import * as React from "react";
import { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { styled } from "@mui/material/styles";
import {
  Grid,
  InputLabel,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  LinearProgress,
  Tooltip,
  Button,
  MenuItem,
  Select,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { Box, Link } from "@mui/material";
import { FiMonitor } from "react-icons/fi";
import { BiSolidUpArrow } from "react-icons/bi";
import { BsFillSquareFill } from "react-icons/bs";
import Divider from "@mui/material/Divider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SuspectedScreening from "./component/process/suspectedscreening";
import DiagnosedPatients from "./component/process/diagnosedpatients";
import InformedConsent from "./component/process/informedconsent";
import PatinetActionPlanning from "./component/process/patientactionplanning";
import TreatmentInitiations from "./component/process/treatmentinitiations";
import ProcessStatistics from "./component/process";
import JourneyPhase from "./component/journeyphase";
import RiskFactors from "./component/othersections/riskfactors";
import StatisticsOtherSection from "./component/othersections";
import { TopNavigation } from "../../../common/control/topnavigation";
import ExportI from "../../../assets/images/navigator/export.png";
import NationalCancerImg from "../../../assets/images/g36-preview.png";
import EswatiniImg from "../../../assets/images/g48-preview.png";
import Barriers from "./component/barriers/index";
import Completion from "./component/completion/index";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  MOCKUP_CANCERTYPE,
  MOCKUP_DURATION,
  MOCKUP_MONTH,
  MOCKUP_YEAR,
  MOCKUP_REGION,
  API,
  EXPORT_KEY,
} from "../../../constants/constant";
import { useRef } from "react";
import axios from "../../../http/axios";
import _ from "lodash";
import { HttpStatusCode } from "axios";
import CommonDropDown from "../../../common/control/commondropdown";
import store from "../../../redux/store/store";
import {
  getAllInsightsDays,
  getAppUsage,
  getBarries,
  getBarriesFigure,
  getCompletionRate,
  getDeath,
  getJournetPhase,
  getProcess,
  getReferrals,
  getRiskFactors,
  getTravel,
} from "../../../redux/actions/nlaactions";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CustomLoader } from "../../../common/control/customloader";

const RADIAN = Math.PI / 180;

const Style = {
  dropdownTitle: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
    color: "#464A53",
  },
  select: {
    display: "flex",
    minWidth: 170,
    height: 46.48,
    background: "#ffffff",
    borderRadius: 4,
    border: "1px solid #f4f6fa",
  },
  pdfHeaderContent: {
    padding: "25px 17px",
    background: "#fff",
  },
  content: {
    color: "#554886",
    fontSize: 35,
    fontFamily: "Poppins-Regular",
    textAlign: "center",
    marginBottom: 10,
  },
  cancerTypeText: {
    color: "#959595",
    fontSize: 12,
    fontFamily: "Poppins-Regular",
  },
  screenSus: {
    color: "#959595",
    fontSize: 12,
    fontFamily: "Poppins-SemiBold",
  },
  cancerText: {
    color: "#554886",
    fontSize: 20,
    fontFamily: "Poppins-Regular",
  },
  verticalLine: {
    height: 40,
    border: "1px solid #959595",
  },
  overviewText: {
    color: "#554886",
    fontSize: 25,
    fontFamily: "Poppins-Regular",
  },
  suspicionCon: {
    border: "1px solid #AAAAAA",
    borderRadius: 8,
    padding: "5px 14px",
  },
  dateCreatedText: {
    color: "#939393",
    fontSize: 16,
    fontFamily: "Poppins-Regular",
    position: "absolute",
    right: 50,
    top: 50,
  },
  pdfContent: {
    position: "absolute",
    right: 1000000,
    width: "100%",
    top: 0,
    height: "100vh",
    overflow: "scroll",
  },
  spinner: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
};

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "12",
          h4: "h2",
          h5: "h2",
          h6: "h6",
        },
        fontFamily: "Poppins-Medium",
        fontWeight: 500,
        color: "#4B4B4B",
      },
    },
  },
});

const AllInsights = ({ allInsightsCategory }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBarrier, setIsLoadingBarrier] = useState(true);
  const [category, setCategory] = React.useState();
  const { country_id } = useSelector(state => state.auth)
  const { allInsightsDays } = useSelector(state => state.nlaReducer)
  useEffect(() => {
    const allInsightsCategoryToObject = {};
    allInsightsCategory?.forEach((item) => {
      allInsightsCategoryToObject[item.name] = item;
    });
    setCategory(allInsightsCategoryToObject);
  }, [allInsightsCategory]);

  const [regions, setRegions] = useState({
    list: [],
    selectedItems: [],
    name: "",
    error: false,
  });
  const [cancerType, setCancerType] = useState({
    list: [],
    selectedItems: [],
    name: "",
    error: false,
  });
  const [duration, setDuration] = useState({
    list: [],
    selectedItems: [],
    name: "",
    error: false,
  });
  const [month, setMonth] = useState({
    list: [],
    selectedItems: [],
    name: "",
    error: false,
  });
  const [year, setYear] = useState({
    list: [],
    selectedItems: [],
    name: "",
    error: false,
  });

  const pdfRef = useRef();

  React.useEffect(() => {
    setIsLoading(true);
    if (!_.isEmpty(cancerType.selectedItems)) getAllInghts();
    else setIsLoading(false);
  }, [regions, cancerType, duration, month, year]);


  const params = {
    country_id,
    region: regions?.selectedItems?.[0],
    cancer_type: cancerType?.selectedItems?.[0],
    duration: duration?.name,
    duration_month: month?.selectedItems?.[0],
    year: year?.name,
  };

  const getAllInghts = async () => {
    try {
      const promiseArray = [axios.get(API.allInsightsReport, { params }), axios.get(API.allInsightsBarrier, { params })]
      const response = await Promise.all(promiseArray);
      
      response?.forEach((item, index) => {
        let { data: { data } } = item
        if (!_.isEmpty(data) && index == 0)
          _mapAllInsightsReport(data);
        else if (!_.isEmpty(data) && index == 1)
          _mapAllInsightsBarrier(data);
      })
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      alert(error?.message);
      console.log(error);
    }

  };

  const _onTopNavButtonCallBack = () => {
    debugger
    const selectedCancerType = cancerType.list.find(
      (e) => e.value == cancerType.selectedItems[0]
    );
    const selectedMonth = month.list.find(
      (e) => e.value == month.selectedItems[0]
    );
    const selectedYear = year.list.find(
      (e) => e.value == year.selectedItems[0]
    );
    const selectedDuration = duration.list.find(
      (e) => e.value == duration.selectedItems[0]
    );
    const selectedRegions = regions.list.find(
      (e) => e.value == regions.selectedItems[0]
    );
    navigate("export-insights", {
      state: {
        fileName: `Patient Navigator System - Statistics Report -
    ${selectedCancerType?.name} - ${selectedMonth?.name} ${selectedYear?.name}`,
        selectedCancerType,
        selectedMonth,
        selectedYear,
        selectedDuration,
        selectedRegions,
        country_id
      },
    });
  };

  const dateTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ap = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let mergeTime = hours + ":" + minutes + " " + ap;
    return mergeTime;
  };

  const getMonthName = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[date.getMonth()];
  };

  const renderPdfContent = () => {
    return (
      <div id="pdfReport" style={Style.pdfContent}>
        <Stack
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
          style={Style.pdfHeaderContent}
        >
          <div>
            <img src={NationalCancerImg} />
          </div>
          <div>
            <div>
              {/* <Stack style={Style.content}>Statistics Report - {getMonthName(new Date())} {new Date().getFullYear()}</Stack> */}
              <Stack style={Style.content}>
                Statistics Report - {month?.name} {year?.name}
              </Stack>
              <Stack
                display="flex"
                alignItems={"center"}
                flexDirection="row"
                gap="50px"
                minHeight={"40px"}
              >
                <Stack
                  display="flex"
                  alignItems={"center"}
                  flexDirection="row"
                  gap="25px"
                >
                  <div style={Style.cancerTypeText}>Cancer Type</div>
                  <div style={Style.cancerText}>{cancerType?.name}</div>
                </Stack>
                <div style={Style.verticalLine}></div>
                <Stack
                  display="flex"
                  alignItems={"center"}
                  flexDirection="row"
                  gap="25px"
                >
                  <div style={Style.cancerTypeText}>Region</div>
                  <div style={Style.cancerText}>{regions?.name}</div>
                </Stack>
              </Stack>
            </div>
          </div>
          <div>
            <img src={EswatiniImg} />
          </div>
        </Stack>
        <Stack position={"relative"}>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="center"
            gap="20px"
            alignItems={"center"}
            mt="30px"
            mb="10px"
          >
            <div style={Style.overviewText}>Patient Journey Overview</div>
            <Stack style={Style.suspicionCon}>
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="40px"
              >
                <div style={Style.overviewText}>{allInsightsDays?.total_days || 0} Days</div>
                <div style={{ ...Style.overviewText, color: !allInsightsDays?.total_diff_mode ? "#E65656" : "#62E89C" }}>
                {!allInsightsDays?.total_diff_mode ?
                <KeyboardArrowUpIcon fontSize="20px" style={{color: "#E65656"}} />:
                <KeyboardArrowDown fontSize="20px" style={{color: "#62E89C"}}/>
                } {allInsightsDays?.total_diff || 0}%
                </div>
              </Stack>
              <Stack style={Style.screenSus}>
                1st Screening suspicion - 1st Treatment Initiation
              </Stack>
            </Stack>
          </Stack>
          <Stack style={Style.dateCreatedText}>
            Last Updated : {new Date().getDate()} {getMonthName(new Date())}{" "}
            {new Date().getFullYear()} | {dateTime(new Date())}
          </Stack>
        </Stack>
        <Divider
          variant="middle"
          style={{ borderColor: "gray", borderStyle: "dashed", margin: 25 }}
        />
        {category?.["Journey Phase"]?.isChecked && <JourneyPhase />}
        {category?.["Process"]?.isChecked && <ProcessStatistics />}
        {category?.["Completion Rate"]?.isChecked && <Completion />}
        {category?.["Barriers"]?.isChecked && <Barriers />}
        <StatisticsOtherSection category={category} />
      </div>
    );
  };
  const _dropdownSelectedItems = (item) => {
    const { regions, cancerTypes, duration, month, year } = item;
    setRegions(regions);
    setCancerType(cancerTypes);
    setDuration(duration);
    setMonth(month);
    setYear(year);
  };

  const _mapAllInsightsBarrier = (allInsightsData) => {
    const { barrier_figure, risk_factor, referrals, travel, app_usage, death } = allInsightsData;
    store.dispatch(getBarriesFigure(barrier_figure));
    store.dispatch(getRiskFactors(risk_factor));
    store.dispatch(getReferrals(referrals));
    store.dispatch(getTravel(travel));
    store.dispatch(getAppUsage(app_usage));
    store.dispatch(getDeath(death));
  };
  const _mapAllInsightsReport = (allInsightsData) => {
    const { data } = allInsightsData;
    console.log(allInsightsData,"datadatadata")
    if (data.length) {
      store.dispatch(getJournetPhase(data.map((e) => e.journey_phase) || []));
      store.dispatch(getProcess(data.map((e) => e.process) || []));
      store.dispatch(
        getCompletionRate(data.map((e) => e.completion_ratio) || [])
      );
      store.dispatch(getBarries(data.map((e) => e.barrier) || []));
      store.dispatch(getAllInsightsDays({total_days: allInsightsData?.total_days, total_diff: allInsightsData?.total_diff, total_diff_mode: allInsightsData?.total_diff_mode}));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {renderPdfContent()}
      <Box
        ref={pdfRef}
        sx={{ height: "100vh", backgroundColor: "#F6F7FA", overflow: "scroll" }}
      >
        {isLoading && (
          <Box sx={Style.spinner}>
            <CustomLoader />
          </Box>
        )}
        <TopNavigation
          navPath={"/reports/patient-journey"}
          title={"All Insights"}
          button={{
            startIcon: ExportI,
            name: "Export",
            _onTopNavButtonCallBack,
            height: 31,
          }}
        />
        <Box sx={{ margin: 3 }}>
          <CommonDropDown callBack={_dropdownSelectedItems} />
        </Box>

        <Divider
          variant="middle"
          style={{ borderColor: "gray", borderStyle: "dashed", margin: 25 }}
        />

        <>
          <Box style={{ padding: "0px 20px" }}>
            {!isLoading && (
              <>
                <JourneyPhase />
                <ProcessStatistics />
                <Completion />
                <Barriers />
                <StatisticsOtherSection />
              </>
            )}
          </Box>
        </>
      </Box>

      <Outlet />
    </ThemeProvider>
  );
};

// export default AllInsights;

const mapStateToProps = (state) => ({
  allInsightsCategory: state?.nlaReducer?.allInsightsCategory,
});

export default connect(mapStateToProps)(AllInsights);
