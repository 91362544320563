import React from 'react'
import '../../assets/css/login.css'
import {useNavigate} from 'react-router-dom';
import { TiTick } from "react-icons/ti"
import Tick from '../../assets/images/check-mark.png'
import BackgroundBg from '../../assets/images/logo-bg.png'

const PasswordReset = () =>{
    const navigate = useNavigate();
    const navigateLogin = () => {
        navigate('/login');
      };
    return(
        <div style={{backgroundImage:`url(${BackgroundBg})`,width: '100%',height: '549px'}}>
           <div className='outer'>
                <div className='inner-for'>
                    <div className='d-flex flex-row justify-content-center password-reset-row'>
                        <img className="tick-img" src={Tick} alt="" />
                    </div>
                    <div className='login-pass'>
                        <p className='login-par-mobile'>Password reset !</p>
                        <p className='login-innerpar1'>Your password has been reset successfully. Click below to login<br /> with your new password</p>
                    </div>
                    <div className="inner-form" >
                        <button onClick={navigateLogin} type="submit" className="login-btn" >Continue</button>
                    </div>
                </div>
           </div>
        </div>
    )
}
export default PasswordReset