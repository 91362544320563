import { Box, Button, Typography, Modal, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import axios from '../../http/axios'
import { API } from "../../constants/constant";
import store from "../../redux/store/store";
import {ReactComponent as DeleteImg} from "../../assets/webapp_icon/delete.svg"

const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        width: 438,
        height: 443,
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
    },
    deleteItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0px 90px',
        justifyContent: 'center',
        height: 443,
        gap: 14,
        marginTop: 30
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -25,
        right: -25,
        width: 63,
        height: 63,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    }

};

const Delete = (props) => {
    const [open, setOpen] = React.useState(true);
    const { state, pathname } = useLocation();
    const [loader, setLoader] = React.useState(false)
    const navigate = useNavigate();
    const handleClose = () => navigate(-1, { state: { isBack: false } });
    useEffect(() => {
        console.log('props', state, pathname)
    }, [state])



    const handleDelete = () => { 
        var config = {
            method: 'put',
            url: state?.apiPath,
            data: state?.apiData,
            headers: { "Content-Type": "application/json" }
        };
        setLoader(true)
        axios.request(config)
            .then(function (response) {
                setLoader(false)
                handleClose()
            })
            .catch(function (error) {
                console.log(error);
                alert(error?.message)
                setLoader(false)
            });

    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={36} style={{ color: "#fff", marginTop: 11 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box style={style.deleteItem}>
                        <DeleteImg
                            style={{
                                height: 105,
                                width: 89,
                                cursor: "pointer",
                            }}
                            color="#EE2D2D"
                        />
                        <Typography id="modal-modal-description" sx={{ mt: 2, color: '#434343', fontSize: 19, fontFamily: 'Montserrat-Medium' }}>
                            {state?.res}
                        </Typography>

                        <Button
                            variant="contained"
                            style={{
                                height: 50,
                                marginBottom: 4,
                                width: '-webkit-fill-available',
                                margin: 10,
                                borderRadius: 25,
                                background: "#546FE6",
                                textTransform: 'none',
                                fontSize: 18,
                                fontFamily: 'Poppins-SemiBold'
                            }}
                            onClick={handleDelete}
                            disabled={loader}
                        >
                            {loader ? <CircularProgress color="inherit" /> : "Yes"}
                        </Button>

                    </Box>
                </Box>
            </Modal>
        </>
    );
};
export default Delete