import { Box, Button, Typography, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa";


const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        width: 438,
        height: 443,
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
    },
    deleteItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0px 90px',
        justifyContent: 'center',
        height: 443,
        gap: 10
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -20,
        right: -15,
        width: 55,
        height: 55,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    }

};

const Success = (props) => {
    const [open, setOpen] = React.useState(true);
    const { state } = useLocation();
    const navigate = useNavigate();
    const handleClose = () => navigate(-1);
    useEffect(() => {
        console.log('props', state)
    }, [state])

    const handleOk = () => {
        navigate("/barriers/published-barriers")
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={30} style={{ color: "#fff", marginTop: 10 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box style={style.deleteItem}>
                        <FaCheck
                            style={{
                                height: 85,
                                width: 89,
                                cursor: "pointer",
                            }}
                            color="#01CA54"
                        />
                        <Typography id="modal-modal-title" sx={{ color: '#01CA54', fontSize: 30, fontFamily: 'Montserrat-Bold' }}>
                            {`${state?.title} !`}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ color: '#000000', fontSize: 19, fontFamily: 'Montserrat-Medium' }}>
                            {state?.res}
                        </Typography>

                        <Button
                            variant="contained"
                            style={{
                                height: 50,
                                marginBottom: 4,
                                width: '-webkit-fill-available',
                                margin: 10,
                                borderRadius: 25,
                                background: "#546FE6",
                                textTransform: 'none',
                                fontSize: 18,
                                fontFamily: 'Poppins-SemiBold'
                            }}
                            onClick={handleOk}
                        >
                            Ok
                        </Button>

                    </Box>
                </Box>
            </Modal>
        </>
    );
};
export default Success