import React, { useEffect, useState } from 'react'
import '../../assets/css/login.css'
import Navigator from '../../assets/images/navigator/navigator.png'
import { useNavigate } from 'react-router-dom';
import Lock from '../../assets/images/lock.png'
import BackgroundBg from '../../assets/images/logo-bg.png'
import UserEmail from '../../assets/images/Useremail.png'
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import _ from 'lodash';
import store from '../../redux/store/store';
import { setCancerType, setCountryId, setLoginInfo, setRegion, setUserToken } from '../../redux/actions/actions'
import { _storeData } from '../../common/methods/localstore';
import axios from '../../http/axios'
import { API, NAVIGATIONS_PATH, STORAGE_KEY, USER_ROLE } from '../../constants/constant';
import Logo from "../../assets/webapp_icon/web_logo/icon-512.png";

const style = {
    singinButton: {
        background: '#FDA945',
        opacity: 1,
        color: 'white',
        padding: '12px 60px',
        border: 'none',
        cursor: 'pointer',
        width: 570,
        height: 70,
        opacity: 0.9,
        marginTop: 25,
        borderRadius: 40,
        fontSize: 24,
        fontFamily: 'Poppins-Semibold',
        padding: 0,
    },
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }
}

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [pass, setPass] = useState('');
    const [errors, setErrors] = useState({
        userName: false,
        pass: false,
    });

    const [errorMessage, setErrorMessage] = useState('');

    const navigateForgot = () => {
        navigate('/forgot');
    };
    useEffect(() => {
        const userInfo = store?.getState()?.auth?.userInfo;
        redirection(userInfo?.user_role.toLowerCase(), userInfo?.is_web)
    }, [])
    const redirection = (user_role, isWeb) => {
        // debugger
        switch (user_role) {
            case USER_ROLE.PN:
                if (isWeb == "1")
                    navigate(NAVIGATIONS_PATH.PN);
                else
                    alert("Access Denied")
                break
            case USER_ROLE.NLA:
                navigate(NAVIGATIONS_PATH.NLA)
                break
            case USER_ROLE.ROCHE:
                navigate(NAVIGATIONS_PATH.ROCHE)
                break
            default:
                navigate(NAVIGATIONS_PATH.LOGIN)
        }
    };
    const setCommonData = async (commonData) => {
        if (commonData.length > 1) {
            const _regions_data = (commonData?.[0]?.data?.data || []).map(region => {
                return {
                    name: region?.region_name,
                    value: region?.region_id?.toString(),
                    _id: region?.region_id?.toString(),
                }
            })
            const _cancerType_data = (commonData?.[1]?.data?.data || []).map(cancerType => {
                return {
                    name: cancerType?.cancer_type_name,
                    value: cancerType?.cancer_type_id,
                    _id: cancerType?.cancer_type_id,
                }
            })
            store.dispatch(setRegion(_regions_data))
            store.dispatch(setCancerType(_cancerType_data))
            await _storeData(STORAGE_KEY.Regions, _regions_data)
            await _storeData(STORAGE_KEY.CancerTypes, _cancerType_data)
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        let errorsItem = {};
        if (!userName?.trim() && !pass?.trim()) {
            errorsItem['userName'] = true;
            errorsItem['pass'] = true;
            setErrorMessage('Please enter email and password.');
          } else if (!userName?.trim()) {
            errorsItem['userName'] = true;
            setErrorMessage('Please enter email.');
          } else if (!pass?.trim()) {
            errorsItem['pass'] = true;
            setErrorMessage('Please enter password.');
          }

        if (!_.isEmpty(errorsItem)) {
            setErrors({ ...errors, ...errorsItem });
            // setErrorMessage('Please enter email and password.');
            return;
        }

        var data = new FormData();
        data.append('email', userName);
        data.append('password', pass);

        var config = {
            method: 'post',
            url: API.checkLogin,
            data: data,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        };
        setIsLoading(true)
        axios.request(config)
            .then(async (response) => {
                let _data = response?.data;
                if (!_.isEmpty(_data)) {
                    store.dispatch(setLoginInfo({ ..._data.data, jwt: _data.jwt }));
                    store.dispatch(setUserToken(_data.jwt))
                    store.dispatch(setCountryId(_data.data?.country_id))
                    _storeData(STORAGE_KEY.CountryId, _data.data?.country_id)
                    _storeData(STORAGE_KEY.LoginInfo, store.getState()?.auth?.loginInfo)
                    const promiseArray = [axios.get(API.getAllRegions, { params: { country_id: _data.data.country_id } }), axios.get(API.getAllCancerType)]
                    const response = await Promise.all(promiseArray)
                    await setCommonData(response)
                    redirection(_data?.data?.user_role?.toLowerCase(), _data?.data?.is_web);
                }
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                alert(error?.message)
                setIsLoading(false);
            });

    };


    return (
        <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
            {isLoading && <Box sx={style.spinner}>
                <CircularProgress />
            </Box>}
            <div className='outer'>

                <div className='inner-login'>
                    <div className='tel-img-box'>
                        <img className='tel-img' src={Logo} />
                    </div>


                    <form className='form-login' onSubmit={onSubmit}>

                        <p className="login-par">Welcome to RedPath!</p>
                        <p className="login-innerpar">World’s most efficient patient navigation software. <br />Enabling faster pathways. Saving lives. </p>
                        

                        <div className="form-field">
                            <TextField
                                error={errors.userName}
                                id="login-email"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <Box className="textfield-icon"><img src={UserEmail} alt="" style={{ width: '25px', height: '30px', marginTop: '4px' }} /></Box>,
                                    disableUnderline: true,
                                    style: {
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: 20,
                                        color: '#1B1B1B',
                                    }
                                }}
                                onFocus={() => setErrors({ ...errors, userName: false })}
                                sx={{
                                    "& fieldset": {
                                        border: errors.userName ? 1 : 'none',
                                        borderRadius: '35px'
                                    },
                                    "& input:-webkit-autofill": {
                                        WebkitBoxShadow: '0 0 0 1000px #F1F8F8 inset',
                                        WebkitTextFillColor: '#1B1B1B',
                                        borderRadius: '35px',
                                    },
                                }}

                                placeholder="Email"
                                className="form-input-field"
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                    setErrorMessage('');
                                }}
                            />
                            <TextField
                                error={errors.pass}
                                id="login-pass"
                                variant="outlined"
                                type='password'
                                InputProps={{
                                    startAdornment: <Box className="textfield-icon"><img src={Lock} style={{ width: '24px', height: '32px', marginTop: '4px' }} alt="" /></Box>,
                                    disableUnderline: true,
                                    style: {
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: 20,
                                        color: '#1B1B1B'
                                    }
                                }}
                                onFocus={() => setErrors({ ...errors, pass: false })}
                                sx={{
                                    "& fieldset": {
                                        border: errors.pass ? 1 : 'none',
                                        borderRadius: '35px'
                                    },
                                }}
                                placeholder="Password"
                                className="form-input-field"
                                onChange={(e) => {
                                    setPass(e.target.value);
                                    setErrorMessage('');
                                }}
                            />

                            {errorMessage && (
                            <div style={{color:'red', textAlign: 'center', fontSize: 18, fontFamily: 'Poppins-Regular', marginBottom: 0}}>
                                {errorMessage}
                            </div>
                            )}

                            <Button
                                variant="contained"
                                style={style.singinButton}
                                // className="login-btn"
                                // onClick={onLogin}
                                type='submit'
                            >
                                Sign In
                            </Button>
                            {/* <button onClick={() => onLogin()} className="login-btn">Sign In</button> */}
                        </div>
                        <div className="inner-forget">
                            <div className="inner-inner">
                                <div className="remember" >
                                    <input className='check' type="checkbox" />
                                    <label className="label"> Remember Me</label>
                                </div>
                                <div className="remember">
                                    <p onClick={navigateForgot} className="forgot-par">Forgot Password?</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >

        </div >
    )
}
export default Login