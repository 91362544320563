let initialState = {
    isLoading: false,
    undefinedBarrierList: [],
    undefinedBarrierCount: 0,
    definedBarrierList: [],
    definedBarrierCount: 0,
    publishedBarrierList: [],
    publishedBarrierCount: 0,
    allUndefinedBarrierList: [],
    allUndefinedBarrierCount: 0,
    dropdownData: null,
    barrierPage: 1,
    allInsightsDays: null
}

const nlareducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_UNDEFINED_BARRIER_LIST':
            return { ...state, undefinedBarrierList: action.payload, isLoading: false, undefinedBarrierCount: action.count }
        case 'GET_DEFINED_BARRIER_LIST':
            return { ...state, definedBarrierList: action.payload, isLoading: false, definedBarrierCount: action.count }
        case 'GET_PUBLISHED_BARRIER_LIST':
            return { ...state, publishedBarrierList: action.payload, isLoading: false, publishedBarrierCount: action.count }
        case 'GET_ALL_UNDEFINED_BARRIER_LIST':
            return { ...state, allUndefinedBarrierList: action.payload, isLoading: false, allUndefinedBarrierCount: action.count }
        case 'SET_BARRIER_DROPDOWN_DATA':
            return { ...state, dropdownData: action.payload }
        case 'SET_BARRIER_PAGE':
            return { ...state, barrierPage: action.payload }
        case 'SET_BARRIER_LOADER':
            return { ...state, isLoading: action.payload }
        case 'GET_JOURNEY_PHASE':
            return { ...state, journeyPhase: action.payload, isLoading: false }
        case 'GET_PROCESS':
            return { ...state, process: action.payload, isLoading: false }
        case 'GET_COMPLETION_RATE':
            return { ...state, completionRate: action.payload, isLoading: false }
        case 'GET_BARRIERS_FIGURE':
            return { ...state, barriesFigure: action.payload, isLoading: false }
        case 'GET_BARRIERS':
            return { ...state, barriers: action.payload, isLoading: false }
        case 'GET_ALL_INSIGHTS_DAYS':
            return { ...state, allInsightsDays: action.payload, isLoading: false }
        case 'GET_RISK_FATORS':
            return { ...state, riskFactors: action.payload, isLoading: false }
        case 'GET_REFERRALS':
            return { ...state, referrals: action.payload, isLoading: false }
        case 'GET_TRAVEL':
            return { ...state, travel: action.payload, isLoading: false }
        case 'GET_APP_USAGE':
            return { ...state, appUsage: action.payload, isLoading: false }
        case 'GET_DEATH':
            return { ...state, death: action.payload, isLoading: false }
        case 'SET_EXPORT':
            return { ...state, allInsightsCategory: action.payload, isLoading: false }
        case 'SET_EXPORT_LOADER':
            return { ...state, isLoading: action.payload }
        default:
            return state
    }
}
export default nlareducer
