import React, { useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import '../../assets/css/login.css'
import { useNavigate } from 'react-router-dom';
import { FaMobileAlt } from "react-icons/fa";
import Mob from '../../assets/images/mob.png'
import Back from '../../assets/images/back.png'
import BackgroundBg from '../../assets/images/logo-bg.png'
import PhoneI from '../../assets/images/phone.png'
import { Box, CircularProgress, MenuItem, Select, TextField } from '@mui/material';
import { API, MOCKUP_COUNTRYCODED, MOCKUP_REGION } from '../../constants/constant';
import { setAPIError } from '../../redux/actions/actions';
import axios from '../../http/axios';
import store from '../../redux/store/store';
import _ from 'lodash';
import { HttpStatusCode } from 'axios';

const Style = {

    dropdownTitle: {
        fontSize: 14,
        fontFamily: "Poppins-Regular",
        color: "#464A53",
    },
    select: {
        display: 'flex',
        // minWidth: 170,
        height: 46.48,
        background: "#ffffff",
        borderRadius: 4,
        border: '1px solid #f4f6fa',
    },
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }
};

const Forgot = () => {
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        phone: false,
    });
    const [countryCode, setCountryCode] = useState({
        list: MOCKUP_COUNTRYCODED,
        selectedItems: ['SZ'],
        value: "",
        error: false,
    });
    const navigate = useNavigate();
    const navigateLogin = () => {
        navigate('/login');
    };
    const generateOtp = (e) => {
        e.preventDefault();
        let errorsItem = {};
        if (!phone?.trim()) {
            errorsItem['phone'] = true;
        }

        if (!_.isEmpty(errorsItem)) {
            setErrors({ ...errors, ...errorsItem });
            return;
        }

        setIsLoading(true)
        const FormData = require('form-data');
        let data = new FormData();
        data.append('mobile_number', `${phone}`); //RV

        let config = {
            method: 'post',
            url: API.generateOTP,
            data: data,
        };
        axios.request(config)
            .then(response => {
                let { data: { password_token, status_code } } = response;
                if (status_code == HttpStatusCode.Ok)
                    navigate('/otp', { state: { phone, password_token } });

            })
            .catch(error => {
                setIsLoading(false);
                store.dispatch(setAPIError({
                    status: true,
                    title: 'Error',
                    message: error?.response?.message || error?.message
                }));
            });


    };
    const handleCountryChange = (event) => {
        const {
            target: { value },
        } = event;
        setCountryCode({
            ...countryCode,
            selectedItems: typeof value === "string" ? value.split(",") : value,
        });
    };
    const handlePhoneChange = (event) => {
        const maxLength = 10;
        const {
            target: { value },
        } = event;
        
        const numericValue = value.replace(/\D/g, '');
        setPhone(numericValue);
        //setPhone(value)
    };
    return (
        <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
            {isLoading && <Box sx={Style.spinner}>
                <CircularProgress />
            </Box>}
            <div className='outer'>
                <div className='inner-for'>
                    <form className='form-login' style={{ margin: '20px 0px' }}>
                        <p className="login-par-mobile" >Mobile Number here!</p>
                        <p className="login-innerpar1">Enter the mobile number associated with your account.</p>
                        <div className="form-field">
                            <TextField
                                error={errors.phone}
                                onFocus={() => setErrors({ ...errors, phone: false })}
                                id="phone"
                                variant="outlined"
                                InputProps={{
                                    startAdornment:
                                        <Box className="textfield-icon" >
                                            <img src={PhoneI} style={{ width: '24px', height: '32px',marginRight:16, marginTop: '4px' }} alt="" />
                                            <Select
                                                sx={{
                                                    border: 0,
                                                    "& fieldset": {
                                                        border: 0
                                                    },
                                                }}
                                                value={countryCode.selectedItems}
                                                onChange={handleCountryChange}
                                            >
                                                {(countryCode.list || []).map((item) => {
                                                    return <MenuItem sx={{
                                                        border: 0,
                                                        "& fieldset": {
                                                            border: 0
                                                        },
                                                    }} value={item._id}>{countryCode.selectedItems == item._id ? item.value : item.value + " " + item.name}</MenuItem>;
                                                })}
                                            </Select>
                                        </Box>,
                                    disableUnderline: true,
                                    style: {
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: 20,
                                        color: '#1B1B1B'
                                    }
                                }}
                                onInput={(e) => {
                                // Remove non-numeric characters and limit the input to the maximum length
                                e.target.value = e.target.value.replace(/\D/g, '');
                                handlePhoneChange(e);
                                }}
                                onChange={(e) => handlePhoneChange(e)}
                                sx={{
                                    "& fieldset": {
                                        border: errors.phone ? 1 : 'none',
                                        borderRadius: '35px'
                                    },
                                }}
                                placeholder="mobile number"
                                className="form-input-field"
                            />
                            <button type="submit" className="login-btn" id="pass-btn" onClick={generateOtp}>Recover Password</button>
                        </div>
                        <div className='d-flex flex-row justify-content-center forgot-row' onClick={navigateLogin}>
                            <div className='d-flex flex-column'>
                                {/* <AiOutlineArrowLeft color="#00ADEE" className='arr-let'/> */}
                                <img className='back-img' src={Back} alt="" />
                            </div>
                            <div className='d-flex flex-column'>
                                <p className='back-login'>Back to login</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Forgot