import * as React from "react";
import { PieChart, Pie, Sector, Cell } from 'recharts';
import {
    InputLabel,
    Paper,
    Tooltip
} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material";
import { BiSolidUpArrow } from "react-icons/bi";
import Divider from '@mui/material/Divider';
import store from "../../../../../redux/store/store";
import { fills } from "../../../../../constants/constant";

const RADIAN = Math.PI / 180;
const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#7A7A7A'} style={{ fontSize: 12, fontFamily: 'Poppins-SemiBold' }}>
                {100}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        </g>
    );
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index, value }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} key={index} fill="#7A7A7A" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 10, fontFamily: ' Poppins-Regular' }}>
            {value}
        </text>
    );
};

const SuspectedScreening = (props) => {
    const screenIndex = 1
    const [process, setProcess] = useState([])
    console.log(process,"process123")
    const [patientData, setPatientData] = useState(undefined)

    React.useEffect(() => {
        let { process } = store?.getState()?.nlaReducer;
        process = (process || [])?.find(e => e.journey_step_seq == screenIndex);
        setProcess(process?.journey_data.map((item, i) => {
            return ({
                value: parseInt(item?.total_patient || 0),
                name: item?.journey_step_name,
                _id: item?.journey_step_id,
                fill: item?.step_color ///fills[i]
            })
        }))
        setPatientData(_setPatientData(process?.patient_data))
    }, [store?.getState()?.nlaReducer?.process])

    const checkInfinity = (value) => {
        const mathRetult = Math.round(value)
        return Number.isFinite(mathRetult) ? Math.abs(mathRetult) : "NA"
    }


    const _setPatientData = (item) => {
        return {
            suspectedScreening: {
                value: item?.patient_all || 0,
                percentage: checkInfinity((item?.patient_all - item?.patient_all_previous) / item?.patient_all_previous * 100) || 0, //RV
                isUp: item?.patient_all <= item?.patient_all_previous //RV
            },
            hospital: {
                firstTime: {
                    name: '1st Time',
                    value: item?.patient_hs_ft || 0,
                },
                rescreen: {
                    name: 'Rescreen',
                    value: item?.patient_hs_rs || 0,
                }

            },
            outreach: {
                firstTime: {
                    name: '1st Time',
                    value: item?.patient_or_ft || 0,
                },
                rescreen: {
                    name: 'Rescreen',
                    value: item?.patient_or_rs || 0,
                }
            }
        }
    }

    // const process = props.process || [{
    //     value: 110,
    //     name: 'HPV/DNA',
    //     _id: '1',
    //     fill: '#f6bcb8'
    // },
    // {
    //     value: 26,
    //     name: 'VIA',
    //     _id: '2',
    //     fill: '#edd5fc'
    // },
    // {
    //     value: 90,
    //     name: 'PAP',
    //     _id: '3',
    //     fill: '#f5c076'
    // },
    // {
    //     value: 10,
    //     name: 'Others',
    //     _id: '4',
    //     fill: '#ad41e8'
    // },
    // ]
    // const patientData = props.patientData || {
    //     suspectedScreening: {
    //         value: 286,
    //         percentage: 46,
    //         isUp: true
    //     },
    //     hospital: {
    //         firstTime: {
    //             name: '1st Time',
    //             value: 120,
    //         },
    //         rescreen: {
    //             name: 'Rescreen',
    //             value: 56,
    //         }

    //     },
    //     outreach: {
    //         firstTime: {
    //             name: '1st Time',
    //             value: 40,
    //         },
    //         rescreen: {
    //             name: 'Rescreen',
    //             value: 20,
    //         }
    //     }
    // }

    return (
        <>
            <Paper
                elevation={0}
                sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 20px",

                    }}
                >
                    <InputLabel
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-Medium",
                            color: "#838383",
                            paddingBottom: 5,
                            marginTop: 15
                        }}
                    >
                        {"Patient"}
                    </InputLabel>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            // alignItems: "center",
                            justifyContent: 'space-between',
                            padding: "20px 0",
                            height: 240,
                            overflowY: 'auto',
                            paddingTop: 0
                        }}
                    >
                        <Box
                            style={{ display: "flex", flexDirection: "column", alignItems: 'start', width: 167 }}
                        >
                            <Box style={{ width: 188 }}>
                                
                                <div>
                                {(process || []).map((processItem, i) => {
                                    return (
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: "Poppins-SemiBold",
                                                    color: "#00133A",
                                                    width: 20
                                                }}
                                            >
                                                {processItem.value}
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: "Poppins-Medium",
                                                    color: "#838383",
                                                    paddingLeft: 8,
                                                    flex: 1
                                                }}
                                            >
                                                {processItem.name}
                                            </span>
                                        </Box>
                                    )
                                })}
                                </div>


                            </Box>
                        </Box>

                        <PieChart width={130} height={130} onMouseEnter={() => { }}>
                            <Pie
                                data={process}
                                innerRadius={22}
                                outerRadius={45}
                                labelLine={false}
                                cx="50%"
                                cy="50%"
                                label={renderCustomizedLabel}
                                fill="#8884d8"
                                paddingAngle={0}
                                dataKey="value"
                                activeIndex={0}
                                activeShape={renderActiveShape}
                            >
                                {(process || []).map((entry, index) => (
                                    <>
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.fill}
                                        >
                                        </Cell>
                                        <Tooltip placement="top" arrow title={"Test"}></Tooltip>
                                    </>
                                ))}
                            </Pie>
                        </PieChart>
                    </Box>
                    <Divider variant="middle" />
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: 'space-between',
                            padding: "10px 0",
                        }}>

                        <InputLabel
                            style={{
                                fontSize: 13,
                                fontFamily: "Poppins-Medium",
                                color: "#237BBC",
                            }}
                        >
                            {"SUSPECTED SCREENINGS"}
                        </InputLabel>

                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            // alignItems: "center",
                            justifyContent: 'space-between',
                            padding: "10px 0",
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                            }}
                        >
                            <h3
                                style={{
                                    fontSize: 25,
                                    fontFamily: "Poppins-SemiBold",
                                    color: "#00133A",
                                    paddingRight: 10
                                }}
                            >
                                {patientData?.suspectedScreening.value}
                            </h3>
                            <span
                                style={{
                                    fontSize: 14,
                                    fontFamily: "Poppins-Medium",
                                    paddingRight: 5,
                                    color: patientData?.suspectedScreening.isUp ? "#34D399" : "#F57C7C",
                                }}
                            >
                                {patientData?.suspectedScreening.percentage + '%'}
                            </span>
                            <BiSolidUpArrow color={patientData?.suspectedScreening.isUp ? "#34D399" : "#F57C7C"} size={8} />
                        </Box>
                    </Box>

                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "20px 0",
                        }}
                    >
                        <Box>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                    paddingBottom: 5
                                }}
                            >
                                {"HOSPITAL"}
                            </InputLabel>
                            <Box style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 10
                            }}>
                                {patientData && Object.keys(patientData?.hospital).map((key, i) => {
                                    return (<Box>
                                        <h3
                                            style={{
                                                fontSize: 22,
                                                fontFamily: "Poppins-SemiBold",
                                                color: "#00133A",
                                                width: 20
                                            }}
                                        >
                                            {patientData?.hospital[key].value}
                                        </h3>
                                        <p
                                            style={{
                                                fontSize: 12,
                                                fontFamily: "Poppins-Regular",
                                                color: "#4B4B4B",
                                            }}
                                        >
                                            {patientData?.hospital[key].name}
                                        </p>
                                    </Box>)
                                })}
                            </Box>
                        </Box>
                        <Divider
                            style={{
                                height: 1,
                                width: 40,
                                transform: "rotate(90deg)",
                                background: "gray",
                            }}
                        />

                        <Box>
                            <InputLabel
                                id="patient"
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                    paddingBottom: 5
                                }}
                            >
                                {"OUTREACH"}
                            </InputLabel>
                            <Box style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 10
                            }}>
                                {patientData && Object.keys(patientData?.outreach).map((key, i) => {
                                    return (<Box>
                                        <h3
                                            style={{
                                                fontSize: 22,
                                                fontFamily: "Poppins-SemiBold",
                                                color: "#00133A",
                                                width: 20
                                            }}
                                        >
                                            {patientData?.outreach[key].value}
                                        </h3>
                                        <p
                                            style={{
                                                fontSize: 12,
                                                fontFamily: "Poppins-Regular",
                                                color: "#4B4B4B",
                                            }}
                                        >
                                            {patientData?.outreach[key].name}
                                        </p>
                                    </Box>)
                                })}

                            </Box>
                        </Box>
                    </Box>


                </Box>
            </Paper>
        </>
    )
}
export default SuspectedScreening