let initialState = {
    patientJourneyData: [],
    isLoading: false,
    patientJourneyDetail: null,
    dropdownData: null,
    journeyTotalDays: null
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PATIENT_JOURNEY_DATA':
            return { ...state, patientJourneyData: action.payload?.data, isLoading: false, journeyTotalDays: { total_days: action.payload?.total_days, total_diff: action?.payload?.total_diff, total_diff_mode: action?.payload?.total_diff_mode} }
        case 'PATIENT_JOURNEY_DETAIL':
            return { ...state, patientJourneyDetail: action.payload, isLoading: false }
        case 'PATIENT_JOURNEY_LOADER':
                return { ...state, isLoading: action.payload }
        case 'SET_JOURNEY_DROPDOWN_DATA':
            return { ...state, dropdownData: action.payload }
        default:
            return state
    }
}
export default rootReducer
