import axios, { HttpStatusCode } from 'axios';
import axiosRetry from 'axios-retry';
import store from '../redux/store/store';
import isProduction from '../app.json';
import { _retrieveData } from '../common/methods/localstore';
import { SESSION_KEY } from '../constants/constant';
import { setLogout } from '../redux/actions/actions';
import { BASE_URL } from '../constants/env';

const pendingRequests = {};

const generateRequestId = (config) => {
  if (config) {
    return `${config?.method || 'TXT'}-${config.url}`;
  }
};
// const getEnv =() =>{
//   const urlType = window.location.host.split(".")?.[0]?.toLowerCase()
//   switch(urlType)
//   {
//     case "staging" :
//     return BASE_URL[urlType]
//     case "dev" : 
//     return BASE_URL[urlType]
//     default :
//     return BASE_URL["prod"]
//   }
// }

axiosRetry(axios, { retries: 3 });
const instance = axios.create({
  baseURL: BASE_URL,
});

// request interceptor
instance.interceptors.request.use(
  function (config) {
    // const requestId = generateRequestId(config);
    // if (pendingRequests[requestId]) {
    //   pendingRequests[requestId].cancel('REQUEST_CANCELLED_DUE_TO_DUPLICATE');
    // }
    // const source = axios.CancelToken.source();
    // config.cancelToken = source.token;
    // pendingRequests[requestId] = source;

    const storeState = store.getState();
    const authToken = storeState && storeState?.auth?.token ? storeState?.auth?.token : null;
    // config.headers["rocheapi"] = "NH1jxt1HKOa6iW1ES25Gc3E12qQv5Egx5xWT2B6e";
    // authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2VjMi0xMy0yMTMtOC0xNDcuYXAtc291dGhlYXN0LTEuY29tcHV0ZS5hbWF6b25hd3MuY29tL3JvY2hlcGF0aWVudG5hdmlnYXRvciIsImF1ZCI6Imh0dHBzOi8vZWMyLTEzLTIxMy04LTE0Ny5hcC1zb3V0aGVhc3QtMS5jb21wdXRlLmFtYXpvbmF3cy5jb20vcm9jaGVwYXRpZW50bmF2aWdhdG9yIiwiaWF0IjoxNjkzMzgxMzk1LCJuYmYiOjE2OTMzODEzOTUsImV4cCI6MTY5Mzk4NjE5NSwiZGF0YSI6eyJpZCI6IjEzIiwibmFtZSI6IlBvb25hbSIsImVtYWlsIjoicG9vbmFtQHlvZGEtdGVjaC5jb20iLCJ1c2VyX3JvbGUiOiJOTEEifX0.2U16F9XjlAyhKSzBsJrcvYkBs48Od6GTg40HDKVYkik'
    
    if (authToken)
      config.headers.Authorization = `Bearer ${authToken}`;

    // config.headers['Access-Control-Allow-Origin'] = '*';
    // config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// response interceptor
instance.interceptors.response.use(
  async response => {
    // console.log(response);
    // const requestId = generateRequestId(response.config);
    // delete pendingRequests[requestId];
    // if(response.data.status_code === 401) {
    //   console.log('error 401')
    //   store.dispatch(setLogout());
    //   localStorage.clear();
    // }
    if (!((response.data.status == "success") || (response.data.status_code > 199) && (response.data.status_code < 300))) {
      if(response?.data?.status_code == HttpStatusCode.Unauthorized)
      {
        store.dispatch(setLogout());
        localStorage.clear();
      }
      return Promise.reject({ message: response?.data?.messages })
    }
    return response;
  },
  async error => {
    console.log(error,"errorname")
    const requestId = generateRequestId(error.config);
    delete pendingRequests[requestId];
    return Promise.reject(error);
  },
);

export default instance;
