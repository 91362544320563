import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { Box, Link, CircularProgress } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from '../../http/axios';
import CommonPagination from '../../common/control/commonpagination';
import { API } from '../../constants/constant';

const columns = [
    { id: "category_name", label: "Category", minWidth: 230, l: 33 },
    { id: "subcategory_name", label: "Subcategory", minWidth: 230 },
    { id: "barrier_type", label: "Barrier Type", minWidth: 230 },
    { id: "appearance", label: "Appearance", minWidth: 230 },
    { id: "isactive", label: "Status", minWidth: 150, l: 20 }
];

const style = {
    spinner: {
        display: 'flex', position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    }
}


const BarriersTable = (props) => {
    let { page, setPage } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };

    useEffect(() => {
        if (page, rowsPerPage, props?.regionsid, props?.cancerTypeId, props?.duration, props?.monthId, props?.year) {
            setIsLoading(true)
            axios.post(API.getPnBarriers, {
                "search_text": props?.query || '',
                "region_id": props?.regionsid,
                "cancer_id": props?.cancerTypeId,
                "duration": props?.duration,
                "month_id": props?.monthId,
                "year": props?.year,
                "page_no": page,
                "limit": rowsPerPage

            })
                .then((res) => {
                    const { data: { data: { Published_Barriers } } } = res
                    setApiData(Published_Barriers);
                    setTotalCount(res?.data?.data?.totalCount)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                    setIsLoading(false)
                });
        }

    }, [props?.debounceQuery, page, rowsPerPage, props?.regionsid, props?.cancerTypeId, props?.duration, props?.monthId, props?.year]);

    useEffect(() => {
        setPage(1);
    }, [props.regionsid, props.cancerTypeId, props.duration, props.monthId, props.year]);

    const rows = apiData.map((item, i) => ({
        category_name: item.category_name,
        subcategory_name: item.subcategory_name,
        barrier_type: item.barrier_type,
        appearance: item.appearance + " Times",
        isactive: item.isactive,
        i
    }));

    const navigate = useNavigate();


    const handleToggleBack = (item) => {
        console.log('isEnabled', item)
    }

    return (
        <>
            <Box sx={{  width: "100%", marginBottom: 2, boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA", borderRadius: "8px", background: '#FFFFFF', }}>
                {isLoading && <Box sx={style.spinner}>
                    <CircularProgress />
                </Box>}

                <TableContainer sx={{
                    height: "calc(100vh - 268px)", overflow: "auto", borderRadius: "8px",
                }}>
                    <Table>
                        <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
                            <TableRow style={{ height: "60px", background: '#F9F9F9' }}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, fontSize: '16px', fontFamily: 'Poppins-Medium', color: '#63799B', left: column.l, right: column.r }}
                                    >
                                        <Box style={{ paddingLeft: column.l }}>
                                            {column.label}
                                        </Box>

                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(apiData || [])
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={{ height: "60px" }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} style={{ color: '#666666', fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
                                                        {column.id == "isactive" ?
                                                            (row.isactive === "1" ? (
                                                                <box className="active-table1">
                                                                    Active
                                                                </box>
                                                            ) : (
                                                                <box className="inactive-table1">
                                                                    Inactive
                                                                </box>
                                                            ))


                                                            : (
                                                                <Box style={{ marginLeft: column.l }}>
                                                                {column.id == "appearance" ?
                                                                 row.appearance + " Times"
                                                                :
                                                                    value}
                                                                </Box>
                                                            )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                {isLoading ? null : apiData?.length == 0 && ( <TableRow> <TableCell colSpan={6} style={{textAlign: 'center'}}>No records found</TableCell> </TableRow> )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Outlet />
            </Box >

            <CommonPagination
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default BarriersTable;
