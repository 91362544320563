import React, { useState } from 'react'
import '../../assets/css/login.css'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom';
import { FaUserAlt } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai"
import Back from '../../assets/images/back.png'
import Lock from '../../assets/images/lock.png'
import BackgroundBg from '../../assets/images/logo-bg.png'
import { Box, TextField } from '@mui/material';
import _ from 'lodash';
import store from '../../redux/store/store';
import { setAPIError } from '../../redux/actions/actions';
import { API } from '../../constants/constant';
import axios from '../../http/axios';

const PasswordScreen = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({
        newPass: false,
        confirmPass: false,
    });
    const { state } = useLocation();
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const navigateForgot = () => {
        navigate('/forgot');
    };
    const navigatePasswordReset = () => {
        navigate('/password-reset');
    };
    const resetPassword = (e) => {
        e.preventDefault();
        let errorsItem = {};
        if (!newPass?.trim()) {
            errorsItem['newPass'] = true;
        }
        if (!confirmPass?.trim()) {
            errorsItem['confirmPass'] = true;
        }

        if (!_.isEmpty(errorsItem)) {
            setErrors({ ...errors, ...errorsItem });
            return;
        }
        let data = JSON.stringify({
            "mobile_number": `${state?.phone}`, //${route?.params.mobileNumber?.country_code}
            "new_password": newPass,
            "confirm_password": confirmPass,
            "password_reset_token": `${state?.password_token}`
        });
        setIsLoading(true)

        let config = {
            method: 'post',
            url: API.changePassword,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios
            .request(config)
            .then((response) => {
                setIsLoading(false);
                navigate('/password-reset');
            })
            .catch((error) => {
                setIsLoading(false);
                store.dispatch(
                    setAPIError({
                        status: true,
                        title: 'Error',
                        message: error?.response?.message || error?.message,
                    }),
                );
            });
    };
    const navigateLogin = () => {
        navigate('/login');
    };
    const handleSetNewChange = (event) => {
        const {
            target: { value },
        } = event;
        setNewPass(value)
    };
    const handleConfirmChange = (event) => {
        const {
            target: { value },
        } = event;
        setConfirmPass(value)
    };
    return (
        <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
            <div className='outer'>
                <div className='inner-login'>
                    <form className='form-login'>
                        <p className="login-par">Set New Password !</p>
                        <p className="login-innerpar">Your new password must be different from the previously used password.</p>
                        <div className="form-field">
                            <TextField
                                error={errors.newPass}
                                id="login-email"
                                variant="outlined"
                                type='password'
                                InputProps={{
                                    startAdornment: <Box className="textfield-icon"><img src={Lock} alt="" style={{ width: '25px', height: '30px', marginTop: '4px' }} /></Box>,
                                    disableUnderline: true,
                                    style: {
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: 20,
                                        color: '#1B1B1B'
                                    }
                                }}
                                sx={{
                                    "& fieldset": {
                                        border: errors.newPass ? 1 : 'none',
                                        borderRadius: '35px'
                                    },
                                }}
                                onChange={handleSetNewChange}
                                placeholder="New Password"
                                className="form-input-field"
                            />
                            <TextField
                                error={errors.confirmPass}
                                id="login-pass"
                                variant="outlined"
                                type='password'
                                InputProps={{
                                    startAdornment: <Box className="textfield-icon"><img src={Lock} style={{ width: '24px', height: '32px', marginTop: '4px' }} alt="" /></Box>,
                                    disableUnderline: true,
                                    style: {
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: 20,
                                        color: '#1B1B1B'
                                    }
                                }}

                                sx={{
                                    "& fieldset": {
                                        border: errors.confirmPass ? 1 : 'none',
                                        borderRadius: '35px'
                                    },
                                }}
                                onChange={handleConfirmChange}
                                placeholder="Confirm Password"
                                className="form-input-field"
                            />
                            <button onClick={resetPassword} type="submit" className="login-btn">Change Password</button>
                        </div>
                        <div className='d-flex flex-row justify-content-center forgot-row' onClick={navigateLogin}>
                            <div className='d-flex flex-column'>
                                <img className='back-img' src={Back} alt="" />                            </div>
                            <div className='d-flex flex-column'>
                                <p className='back-login'>Back to login</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default PasswordScreen