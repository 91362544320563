export function setPatientJourneyData(data) {
    return {
      type: 'PATIENT_JOURNEY_DATA',
      payload: data,
    };
}

export function setPatientJourneyDetail(data) {
    return {
      type: 'PATIENT_JOURNEY_DETAIL',
      payload: data,
    };
}

export function setPatientJourneyLoader(data) {
    return {
      type: 'PATIENT_JOURNEY_LOADER',
      payload: data,
    };
}
export function setJourneyDropdownData(data) {
    return {
      type: 'SET_JOURNEY_DROPDOWN_DATA',
      payload: data,
    };
}