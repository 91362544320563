import * as React from "react";
import {
    InputLabel,
    Paper,
    Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { FiMonitor } from "react-icons/fi";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import Divider from '@mui/material/Divider';
import { ProgressWithDynamicTooltip } from "../../../../../common/control/progresswithdynamictooltip";

const PaperJournyPhase = (props) => {
    console.log(props,"propsprops")
    return (
        <>
            <Paper
                elevation={0}
                sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 20px",
                        // minWidth: 360,
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "10px 0",
                        }}
                    >
                        {/* <FiMonitor color="#6B6B6B" size={25} /> */}
                        {props?.journeyPhase?.image}
                        <InputLabel
                            id="screening"
                            style={{
                                fontSize: 16,
                                fontFamily: "Poppins-Medium",
                                color: "#6B6B6B",
                                padding: "0 20px",
                                textTransform: 'capitalize'
                            }}
                        >
                            {props?.journeyPhase?.phaseName}
                        </InputLabel>
                    </Box>

                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "20px 0",
                        }}
                    >
                        <Box>
                            <Typography variant="h4" component="h4">
                                {props?.journeyPhase?.patients?.value}
                            </Typography>
                            <InputLabel
                                id="patient"
                                style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Regular",
                                    color: "#4B4B4B",
                                }}
                            >
                                Patient
                            </InputLabel>
                        </Box>
                        <Divider
                            style={{
                                height: 1,
                                width: 40,
                                transform: "rotate(90deg)",
                                background: "lightgray",
                            }}
                        />

                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="h4"
                                    sx={{ paddingRight: 2 }}
                                >
                                    {props?.journeyPhase?.estinamtedDays?.value}
                                </Typography>
                                {props?.journeyPhase?.estinamtedDays?.isUp ?
                                    <BiSolidUpArrow color={"#F57C7C"} />
                                    :
                                    <BiSolidDownArrow color={"#62E89C"} />
                                }
                                <InputLabel
                                    id={`estimatedDays`}
                                    style={{
                                        fontSize: 18,
                                        fontFamily: "Poppins-Medium",
                                        color: props?.journeyPhase?.estinamtedDays?.isUp ? "#F57C7C" : "#62E89C"
                                    }}
                                >
                                    {props?.journeyPhase?.estinamtedDays?.difference}
                                </InputLabel>
                            </Box>
                            <InputLabel
                                id="patient"
                                style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Regular",
                                    color: "#4B4B4B",
                                }}
                            >
                                Estimated Days
                            </InputLabel>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "20px 0",
                        }}
                    >
                        <Box sx={{ flex: 1, flexDirection: "column" }}>
                            <InputLabel
                                style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Regular",
                                    color: "#4B4B4B",
                                }}
                            >
                                {"Number of days"}
                            </InputLabel>
                            <ProgressWithDynamicTooltip data={props?.journeyPhase?.data} />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <InputLabel
                                    style={{
                                        fontSize: 12,
                                        fontFamily: "Poppins-Regular",
                                        color: "#4B4B4B",
                                        width: '50%',
                                        overflow: 'inherit',
                                        whiteSpace: 'inherit',
                                        textOverflow: 'inherit'
                                    }}
                                >
                                    {props?.journeyPhase?.start_process}
                                </InputLabel>
                                <InputLabel
                                    style={{
                                        fontSize: 12,
                                        fontFamily: "Poppins-Regular",
                                        color: "#4B4B4B",
                                        width: '50%',
                                        overflow: 'inherit',
                                        whiteSpace: 'inherit',
                                        textAlign: 'right',
                                        textOverflow: 'inherit'
                                    }}
                                >
                                    {props?.journeyPhase?.end_process}
                                </InputLabel>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper >
        </>
    )
}
export { PaperJournyPhase }