import React, { useState } from 'react'
import '../../../assets/css/userRole.css'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import FacilityPopup from '../facilityPopup';
import { AiOutlineSearch } from 'react-icons/ai'
import { Outlet, useNavigate } from 'react-router-dom';
import FacilityTable from './facilitytable'
import { AiFillPlusCircle } from 'react-icons/ai'
import TopBarImage from '../../../assets/images/navigator/topbar.png'
import { BsArrowLeft, BsSearch } from "react-icons/bs";
import { TopNavigation } from '../../../common/control/topnavigation';
import PlusI from '../../../assets/images/navigator/plus.png'
import { CiSearch } from "react-icons/ci";
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { useCallback } from 'react';

const Facility = () => {
    const navigate = useNavigate();
    const [query, setquery] = useState('')
    const [debounceQuery, setDebounceQuery] = useState('')
    const [page, setPage] = useState(1);
    const _onTopNavButtonCallBack = () => {
        // setIsOpen(!isOpen);
        navigate('add-facility', {
            state: {
                title: 'Add Facility!',
                isEdit: false,
            }
        })
    }
    const sendRequest = useCallback((value) => {
        setPage(1)
        setDebounceQuery(value)
      }, []);
      const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, 1000);
      }, [sendRequest]);
    const handleChange = (e) => {
        setquery(e.target.value)
        debouncedSendRequest(e.target.value);
    }
    return (
        <>
            <div style={{ background: '#F6F7FA', width: '100%', height: '95px' }}>
                <TopNavigation navPath={'/NLA'} title={'Facility'}
                    button={{ startIcon: PlusI, name: 'Add Facility',width: "184.23px", _onTopNavButtonCallBack }}
                >
                    <box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "72.97px",
                        }}
                    >
                        <box className='search-search ttt'>
                            <span className='search-icon'><CiSearch color='#4285F4' size={28} /></span>
                            <span className='search-icon'><CiSearch color='#4285F4' size={28} /></span>
                            <input className="search-box" type="search" value={query} onChange={handleChange} placeholder='Search' style={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }} />
                        </box>
                    </box>
                </TopNavigation>
                <div style={{ padding: "20px 60px 0px 60px" }}>

                    <FacilityTable query={query} debounceQuery={debounceQuery} setPage={setPage} page={page} />

                </div>
                <Outlet />
            </div>
        </>
    )
}
export default Facility