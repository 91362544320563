import _ from "lodash"
import { STORAGE_KEY } from "../../constants/constant"

const userInfo = JSON.parse(localStorage.getItem(STORAGE_KEY.LoginInfo))
const regions = JSON.parse(localStorage.getItem(STORAGE_KEY.Regions))
const cancerTypes = JSON.parse(localStorage.getItem(STORAGE_KEY.CancerTypes))
const country_id = JSON.parse(localStorage.getItem(STORAGE_KEY.CountryId))

let initialState = {
    isLoading: false,
    isAuthUser: !!userInfo?.jwt,
    token: userInfo?.jwt || undefined,
    userInfo,
    loginInfo:userInfo,
    regions_list: regions,
    cancerTypes_list: cancerTypes,
    country_id
}
const rootReducer = (state = {...initialState}, action) => {
    switch (action.type) {
        case 'LOGINFO':
            return { ...state, isAuthUser: true, loginInfo: action.payload }
        case 'USER_TOKEN':
            return { ...state, token: action.payload }
        case 'COUNTRY_ID':
            return { ...state, country_id: action.payload }
        case 'REFRESH_TOKEN':
            return { ...state, refreshToken: action.payload }
        case 'REGION_LIST':
            return { ...state, regions_list: action.payload }
        case 'CANCERTYPE_LIST':
            return { ...state, cancerTypes_list: action.payload }
        case 'LOGOUT':
            return {}
        case 'API_SUCCESS':
            return { ...state, APISuccessResponse: action.payload }
        case 'API_ERROR':
            return { ...state, APIErrorResponse: action.payload }
        default:
            return state
    }
}
export default rootReducer
