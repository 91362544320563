import { Outlet, Link, useLocation, Navigate } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';

import {
    Grid,
    Box,
    InputLabel,
    CircularProgress,
} from "@mui/material";

import { useEffect, useState } from "react";
import "../../../assets/css/theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { MOCKUP_CANCERTYPE, MOCKUP_DURATION, MOCKUP_MONTH, MOCKUP_YEAR, MOCKUP_REGION, API } from "../../../constants/constant";
import "../../../assets/css/patientJourney.css";
import { TopNavigation } from "../../../common/control/topnavigation";
import AllInsightsI from '../../../assets/images/navigator/allinsights.png'
import { ProgressWithDynamicTooltip } from "../../../common/control/progresswithdynamictooltip";
import { setPatientJourneyLoader } from "../../../redux/actions/rocheactions";
import store from "../../../redux/store/store";
import axios from '../../../http/axios'
import { useSelector } from "react-redux";
import { CustomLoader } from "../../../common/control/customloader";

const Style = {
    spinner: {
      display: "flex",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      alignItems: "center",
      justifyContent: "center",
    },
  };

const theme = createTheme({
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: '12',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h6',
                    subtitle1: 'h2',
                    subtitle2: 'p',
                    body1: 'span',
                    body2: 'span',
                },
                fontFamily: 'Montserrat-Bold',
                color: '#FFFFFF'
            },
        },
    },
});

const StatisticAllBarriers = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [barriersData, setBarriersData] = useState(null)
    const { isLoading } = useSelector(state => state.rocheReducer)
    const {country_id} = useSelector(state => state.auth)

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${API.fetchRochePhaseWiseBarrierList}?cancer_type=${state?.cancerTypes?.selectedItems[0]}&journey_phase_id=${state?.journey_stage_id}&region=${state?.regions.selectedItems[0]}&duration=${state?.duration?.name}&duration_month=${state?.month?.selectedItems[0]}&year=${state?.year?.name}&country_id=${country_id}`
        };
        store.dispatch(setPatientJourneyLoader(true))
        axios.request(config)
            .then(function (response) {
                console.log(response,"response")
                if (response?.data?.data?.barrier_category?.length) {
                    setBarriersData(response?.data?.data)
                    store.dispatch(setPatientJourneyLoader(false))
                } else {
                    let staticData = {
                        actual_days: 0,
                        end_process: "NA",
                        journey_stage_id: "1009",
                        journey_stage_name: "NA",
                        standard_days: "0",
                        start_process: "NA",
                        barrier_category: [{category_id: "0", category_name: 'NA', occurance_barrier_count: "0", resolved_barrier_count: "0", todo_expense: "0"}]
                    }
                    setBarriersData(staticData)
                    store.dispatch(setPatientJourneyLoader(false))
                }
            })
            .catch(function (error) {
                console.log(error);
                store.dispatch(setPatientJourneyLoader(false))
            });

    }, [])

    const _onTopNavButtonCallBack = () => {
        navigate('/reports/allinsights');
    }

    if(!state) {
        return <Navigate to={'/reports/patient-journey'} />
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ background: "#F6F7FA", minHeight: "100vh" }}>

                <TopNavigation navPath={'/reports/patient-journey'} title={'All Barriers'}
                    button={{ startIcon: AllInsightsI, name: 'All Insights',width: '177px', _onTopNavButtonCallBack }}
                >
                    <box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'center',
                            flex: 1
                        }}
                    >
                        <InputLabel
                            style={{
                                fontSize: 20,
                                fontFamily: "Montserrat-Medium",
                                color: "white",
                            }}
                        >
                            {state?.regions?.name}
                        </InputLabel>
                        <Box
                            style={{
                                height: 40,
                                width: 1,
                                margin: '0 20px',
                                // transform: "rotate(90deg)",
                                background: "white",
                            }}
                        />
                        <InputLabel
                            style={{
                                fontSize: 20,
                                fontFamily: "Montserrat-Medium",
                                color: "white",
                            }}
                        >
                            {state?.cancerTypes?.name}
                        </InputLabel>
                        <Box
                            style={{
                                height: 40,
                                width: 1,
                                margin: '0 20px',
                                // transform: "rotate(90deg)",
                                background: "white",
                            }}
                        />
                        <InputLabel
                            style={{
                                fontSize: 20,
                                fontFamily: "Montserrat-Medium",
                                color: "white",
                            }}
                        >
                            {state?.month?.name + ' ' + state?.year?.name}
                        </InputLabel>
                    </box>
                </TopNavigation>
                {isLoading ?
                    <div style={Style.spinner}><CustomLoader /></div> :
                    <Box style={{ background: '#fefefe', minHeight: "90vh", margin: 10 }}>

                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "20px",
                            }}
                        >
                            <Box sx={{ flex: 1, flexDirection: "column" }}>
                                <InputLabel
                                    style={{
                                        fontSize: 16,
                                        fontFamily: "Poppins-Regular",
                                        color: "#727272",
                                    }}
                                >
                                    {"No of days"}
                                </InputLabel>
                                <ProgressWithDynamicTooltip data={{
                                    estimatedDays: parseInt(barriersData?.standard_days || 0),
                                    actual: parseInt(barriersData?.actual_days || 0)
                                }}
                                    height={35} />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <InputLabel
                                        style={{
                                            fontSize: 16,
                                            fontFamily: "Poppins-Regular",
                                            color: "#727272",
                                        }}
                                    >
                                        {barriersData?.start_process}
                                    </InputLabel>
                                    <InputLabel
                                        style={{
                                            fontSize: 16,
                                            fontFamily: "Poppins-Regular",
                                            color: "#727272",
                                        }}
                                    >
                                        {barriersData?.end_process}
                                    </InputLabel>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                padding: "0 5px",
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            <Grid container lg={12}>
                                {barriersData?.barrier_category?.length ? barriersData?.barrier_category?.map(item => <Grid lg={3} sm={6} xs={12} item style={{ padding: 14 }}>
                                    <div className="grid-box" style={{ background: '#ffffff', border: '1px solid #EAEBEC' }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginBottom: '5px'

                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginLeft: "0 20px",
                                                    gap: 5,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontFamily: "Poppins-SemiBold",
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    {item?.category_name}
                                                </div>
                                            </div>
                                        </div>

                                        <Grid container>
                                            <Grid lg={4} item >
                                                <div style={{ fontFamily: "Poppins-Regular", fontSize: 12, color: "#82888A", }}>
                                                    Resolved/Occurrence
                                                </div>
                                            </Grid>
                                            <Grid lg={4} item >

                                            </Grid>

                                            <Grid lg={4} item sx={{ paddingLeft: '12px' }}>
                                                <div style={{ fontFamily: "Poppins-Regular", fontSize: 12, color: "#82888A", }} >
                                                    Expense(USD)
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container style={{ marginBottom: '4px', alignItems: 'center' }}>
                                            <Grid lg={4} item >
                                                <div style={{ fontFamily: "Poppins-Medium", fontSize: 18 }}>
                                                    {parseInt(item?.resolved_barrier_count || 0)} / {parseInt(item?.occurance_barrier_count || 0)}
                                                </div>
                                            </Grid>
                                            <Grid lg={4} item sx={{ borderRight: '1px solid #ccc' }}>
                                                <div>
                                                    <ProgressBar variant="progress1" now={parseInt(item?.resolved_barrier_count || 0)} className='progressbar' max={parseInt(item?.occurance_barrier_count || 0)} />
                                                    <ProgressBar variant="progress2" now={parseInt(item?.occurance_barrier_count || 0)} className='progressbar' max={parseInt(item?.occurance_barrier_count || 0)} />
                                                </div>
                                            </Grid>

                                            <Grid lg={4} item sx={{ paddingLeft: '12px', boxSizing: 'border-box' }}>
                                                <div style={{ fontFamily: "Poppins-SemiBold", fontSize: 18, color: '#009E6C', fontWeight: 500 }}>
                                                    {item?.todo_expense || 0}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>):
                                <div style={{ textAlign: 'center', width: '100%' }}>No Data Found</div>
                            }
                            </Grid>
                            <Outlet />
                        </Box>
                    </Box>}
            </Box >
        </ThemeProvider >
    );
};

export default StatisticAllBarriers;
