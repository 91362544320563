import { ReactComponent as ScreeningImg } from '../assets/webapp_icon/Screening.svg';
import { ReactComponent as DiagnosisImg } from '../assets/webapp_icon/Diagnosis.svg';
import { ReactComponent as InvestigationImg } from '../assets/webapp_icon/Investigation.svg';
import { ReactComponent as TreatmentplanningImg } from '../assets/webapp_icon/Treatmentplanning.svg';
import { ReactComponent as TreatmentinitiationImg } from '../assets/webapp_icon/Treatmentinitiation.svg';

export const BarrierTopNav = [
    {
        label: 'Add Keywords',
        id: 1,
        path: 'add-keywords',
        isActive: true,
    },
    {
        label: 'Group Barriers',
        id: 2,
        path: 'group-barriers',
        isActive: false,
    },
    {
        label: 'Add Sub-categories to Mobile App',
        id: 3,
        path: 'add-subcategory',
        isActive: false,
    },
    {
        label: 'Published Barriers',
        id: 4,
        path: 'published-barriers',
        isActive: false,
    },
]


export const MOCKUP_REGION = [
    {
        name: "National Average",
        value: "1",
        _id: "1",
    }
];
export const MOCKUP_COUNTRYCODED =
    [
        {
            name: "Afghanistan",
            value: "+93",
            _id: "AF"
        },
        {
            name: "Aland Islands",
            value: "+358",
            _id: "AX"
        },
        {
            name: "Albania",
            value: "+355",
            _id: "AL"
        },
        {
            name: "Algeria",
            value: "+213",
            _id: "DZ"
        },
        {
            name: "AmericanSamoa",
            value: "+1684",
            _id: "AS"
        },
        {
            name: "Andorra",
            value: "+376",
            _id: "AD"
        },
        {
            name: "Angola",
            value: "+244",
            _id: "AO"
        },
        {
            name: "Anguilla",
            value: "+1264",
            _id: "AI"
        },
        {
            name: "Antarctica",
            value: "+672",
            _id: "AQ"
        },
        {
            name: "Antigua and Barbuda",
            value: "+1268",
            _id: "AG"
        },
        {
            name: "Argentina",
            value: "+54",
            _id: "AR"
        },
        {
            name: "Armenia",
            value: "+374",
            _id: "AM"
        },
        {
            name: "Aruba",
            value: "+297",
            _id: "AW"
        },
        {
            name: "Australia",
            value: "+61",
            _id: "AU"
        },
        {
            name: "Austria",
            value: "+43",
            _id: "AT"
        },
        {
            name: "Azerbaijan",
            value: "+994",
            _id: "AZ"
        },
        {
            name: "Bahamas",
            value: "+1242",
            _id: "BS"
        },
        {
            name: "Bahrain",
            value: "+973",
            _id: "BH"
        },
        {
            name: "Bangladesh",
            value: "+880",
            _id: "BD"
        },
        {
            name: "Barbados",
            value: "+1246",
            _id: "BB"
        },
        {
            name: "Belarus",
            value: "+375",
            _id: "BY"
        },
        {
            name: "Belgium",
            value: "+32",
            _id: "BE"
        },
        {
            name: "Belize",
            value: "+501",
            _id: "BZ"
        },
        {
            name: "Benin",
            value: "+229",
            _id: "BJ"
        },
        {
            name: "Bermuda",
            value: "+1441",
            _id: "BM"
        },
        {
            name: "Bhutan",
            value: "+975",
            _id: "BT"
        },
        {
            name: "Bolivia, Plurinational State of",
            value: "+591",
            _id: "BO"
        },
        {
            name: "Bosnia and Herzegovina",
            value: "+387",
            _id: "BA"
        },
        {
            name: "Botswana",
            value: "+267",
            _id: "BW"
        },
        {
            name: "Brazil",
            value: "+55",
            _id: "BR"
        },
        {
            name: "British Indian Ocean Territory",
            value: "+246",
            _id: "IO"
        },
        {
            name: "Brunei Darussalam",
            value: "+673",
            _id: "BN"
        },
        {
            name: "Bulgaria",
            value: "+359",
            _id: "BG"
        },
        {
            name: "Burkina Faso",
            value: "+226",
            _id: "BF"
        },
        {
            name: "Burundi",
            value: "+257",
            _id: "BI"
        },
        {
            name: "Cambodia",
            value: "+855",
            _id: "KH"
        },
        {
            name: "Cameroon",
            value: "+237",
            _id: "CM"
        },
        {
            name: "Canada",
            value: "+1",
            _id: "CA"
        },
        {
            name: "Cape Verde",
            value: "+238",
            _id: "CV"
        },
        {
            name: "Cayman Islands",
            value: "+ 345",
            _id: "KY"
        },
        {
            name: "Central African Republic",
            value: "+236",
            _id: "CF"
        },
        {
            name: "Chad",
            value: "+235",
            _id: "TD"
        },
        {
            name: "Chile",
            value: "+56",
            _id: "CL"
        },
        {
            name: "China",
            value: "+86",
            _id: "CN"
        },
        {
            name: "Christmas Island",
            value: "+61",
            _id: "CX"
        },
        {
            name: "Cocos (Keeling) Islands",
            value: "+61",
            _id: "CC"
        },
        {
            name: "Colombia",
            value: "+57",
            _id: "CO"
        },
        {
            name: "Comoros",
            value: "+269",
            _id: "KM"
        },
        {
            name: "Congo",
            value: "+242",
            _id: "CG"
        },
        {
            name: "Congo, The Democratic Republic of the Congo",
            value: "+243",
            _id: "CD"
        },
        {
            name: "Cook Islands",
            value: "+682",
            _id: "CK"
        },
        {
            name: "Costa Rica",
            value: "+506",
            _id: "CR"
        },
        {
            name: "Cote d'Ivoire",
            value: "+225",
            _id: "CI"
        },
        {
            name: "Croatia",
            value: "+385",
            _id: "HR"
        },
        {
            name: "Cuba",
            value: "+53",
            _id: "CU"
        },
        {
            name: "Cyprus",
            value: "+357",
            _id: "CY"
        },
        {
            name: "Czech Republic",
            value: "+420",
            _id: "CZ"
        },
        {
            name: "Denmark",
            value: "+45",
            _id: "DK"
        },
        {
            name: "Djibouti",
            value: "+253",
            _id: "DJ"
        },
        {
            name: "Dominica",
            value: "+1767",
            _id: "DM"
        },
        {
            name: "Dominican Republic",
            value: "+1849",
            _id: "DO"
        },
        {
            name: "Ecuador",
            value: "+593",
            _id: "EC"
        },
        {
            name: "Egypt",
            value: "+20",
            _id: "EG"
        },
        {
            name: "El Salvador",
            value: "+503",
            _id: "SV"
        },
        {
            name: "Equatorial Guinea",
            value: "+240",
            _id: "GQ"
        },
        {
            name: "Eritrea",
            value: "+291",
            _id: "ER"
        },
        {
            name: "Estonia",
            value: "+372",
            _id: "EE"
        },
        {
            name: "Ethiopia",
            value: "+251",
            _id: "ET"
        },
        {
            name: "Falkland Islands (Malvinas)",
            value: "+500",
            _id: "FK"
        },
        {
            name: "Faroe Islands",
            value: "+298",
            _id: "FO"
        },
        {
            name: "Fiji",
            value: "+679",
            _id: "FJ"
        },
        {
            name: "Finland",
            value: "+358",
            _id: "FI"
        },
        {
            name: "France",
            value: "+33",
            _id: "FR"
        },
        {
            name: "French Guiana",
            value: "+594",
            _id: "GF"
        },
        {
            name: "French Polynesia",
            value: "+689",
            _id: "PF"
        },
        {
            name: "Gabon",
            value: "+241",
            _id: "GA"
        },
        {
            name: "Gambia",
            value: "+220",
            _id: "GM"
        },
        {
            name: "Georgia",
            value: "+995",
            _id: "GE"
        },
        {
            name: "Germany",
            value: "+49",
            _id: "DE"
        },
        {
            name: "Ghana",
            value: "+233",
            _id: "GH"
        },
        {
            name: "Gibraltar",
            value: "+350",
            _id: "GI"
        },
        {
            name: "Greece",
            value: "+30",
            _id: "GR"
        },
        {
            name: "Greenland",
            value: "+299",
            _id: "GL"
        },
        {
            name: "Grenada",
            value: "+1473",
            _id: "GD"
        },
        {
            name: "Guadeloupe",
            value: "+590",
            _id: "GP"
        },
        {
            name: "Guam",
            value: "+1671",
            _id: "GU"
        },
        {
            name: "Guatemala",
            value: "+502",
            _id: "GT"
        },
        {
            name: "Guernsey",
            value: "+44",
            _id: "GG"
        },
        {
            name: "Guinea",
            value: "+224",
            _id: "GN"
        },
        {
            name: "Guinea-Bissau",
            value: "+245",
            _id: "GW"
        },
        {
            name: "Guyana",
            value: "+595",
            _id: "GY"
        },
        {
            name: "Haiti",
            value: "+509",
            _id: "HT"
        },
        {
            name: "Holy See (Vatican City State)",
            value: "+379",
            _id: "VA"
        },
        {
            name: "Honduras",
            value: "+504",
            _id: "HN"
        },
        {
            name: "Hong Kong",
            value: "+852",
            _id: "HK"
        },
        {
            name: "Hungary",
            value: "+36",
            _id: "HU"
        },
        {
            name: "Iceland",
            value: "+354",
            _id: "IS"
        },
        {
            name: "India",
            value: "+91",
            _id: "IN"
        },
        {
            name: "Indonesia",
            value: "+62",
            _id: "ID"
        },
        {
            name: "Iran, Islamic Republic of Persian Gulf",
            value: "+98",
            _id: "IR"
        },
        {
            name: "Iraq",
            value: "+964",
            _id: "IQ"
        },
        {
            name: "Ireland",
            value: "+353",
            _id: "IE"
        },
        {
            name: "Isle of Man",
            value: "+44",
            _id: "IM"
        },
        {
            name: "Israel",
            value: "+972",
            _id: "IL"
        },
        {
            name: "Italy",
            value: "+39",
            _id: "IT"
        },
        {
            name: "Jamaica",
            value: "+1876",
            _id: "JM"
        },
        {
            name: "Japan",
            value: "+81",
            _id: "JP"
        },
        {
            name: "Jersey",
            value: "+44",
            _id: "JE"
        },
        {
            name: "Jordan",
            value: "+962",
            _id: "JO"
        },
        {
            name: "Kazakhstan",
            value: "+77",
            _id: "KZ"
        },
        {
            name: "Kenya",
            value: "+254",
            _id: "KE"
        },
        {
            name: "Kiribati",
            value: "+686",
            _id: "KI"
        },
        {
            name: "Korea, Democratic People's Republic of Korea",
            value: "+850",
            _id: "KP"
        },
        {
            name: "Korea, Republic of South Korea",
            value: "+82",
            _id: "KR"
        },
        {
            name: "Kuwait",
            value: "+965",
            _id: "KW"
        },
        {
            name: "Kyrgyzstan",
            value: "+996",
            _id: "KG"
        },
        {
            name: "Laos",
            value: "+856",
            _id: "LA"
        },
        {
            name: "Latvia",
            value: "+371",
            _id: "LV"
        },
        {
            name: "Lebanon",
            value: "+961",
            _id: "LB"
        },
        {
            name: "Lesotho",
            value: "+266",
            _id: "LS"
        },
        {
            name: "Liberia",
            value: "+231",
            _id: "LR"
        },
        {
            name: "Libyan Arab Jamahiriya",
            value: "+218",
            _id: "LY"
        },
        {
            name: "Liechtenstein",
            value: "+423",
            _id: "LI"
        },
        {
            name: "Lithuania",
            value: "+370",
            _id: "LT"
        },
        {
            name: "Luxembourg",
            value: "+352",
            _id: "LU"
        },
        {
            name: "Macao",
            value: "+853",
            _id: "MO"
        },
        {
            name: "Macedonia",
            value: "+389",
            _id: "MK"
        },
        {
            name: "Madagascar",
            value: "+261",
            _id: "MG"
        },
        {
            name: "Malawi",
            value: "+265",
            _id: "MW"
        },
        {
            name: "Malaysia",
            value: "+60",
            _id: "MY"
        },
        {
            name: "Maldives",
            value: "+960",
            _id: "MV"
        },
        {
            name: "Mali",
            value: "+223",
            _id: "ML"
        },
        {
            name: "Malta",
            value: "+356",
            _id: "MT"
        },
        {
            name: "Marshall Islands",
            value: "+692",
            _id: "MH"
        },
        {
            name: "Martinique",
            value: "+596",
            _id: "MQ"
        },
        {
            name: "Mauritania",
            value: "+222",
            _id: "MR"
        },
        {
            name: "Mauritius",
            value: "+230",
            _id: "MU"
        },
        {
            name: "Mayotte",
            value: "+262",
            _id: "YT"
        },
        {
            name: "Mexico",
            value: "+52",
            _id: "MX"
        },
        {
            name: "Micronesia, Federated States of Micronesia",
            value: "+691",
            _id: "FM"
        },
        {
            name: "Moldova",
            value: "+373",
            _id: "MD"
        },
        {
            name: "Monaco",
            value: "+377",
            _id: "MC"
        },
        {
            name: "Mongolia",
            value: "+976",
            _id: "MN"
        },
        {
            name: "Montenegro",
            value: "+382",
            _id: "ME"
        },
        {
            name: "Montserrat",
            value: "+1664",
            _id: "MS"
        },
        {
            name: "Morocco",
            value: "+212",
            _id: "MA"
        },
        {
            name: "Mozambique",
            value: "+258",
            _id: "MZ"
        },
        {
            name: "Myanmar",
            value: "+95",
            _id: "MM"
        },
        {
            name: "Namibia",
            value: "+264",
            _id: "NA"
        },
        {
            name: "Nauru",
            value: "+674",
            _id: "NR"
        },
        {
            name: "Nepal",
            value: "+977",
            _id: "NP"
        },
        {
            name: "Netherlands",
            value: "+31",
            _id: "NL"
        },
        {
            name: "Netherlands Antilles",
            value: "+599",
            _id: "AN"
        },
        {
            name: "New Caledonia",
            value: "+687",
            _id: "NC"
        },
        {
            name: "New Zealand",
            value: "+64",
            _id: "NZ"
        },
        {
            name: "Nicaragua",
            value: "+505",
            _id: "NI"
        },
        {
            name: "Niger",
            value: "+227",
            _id: "NE"
        },
        {
            name: "Nigeria",
            value: "+234",
            _id: "NG"
        },
        {
            name: "Niue",
            value: "+683",
            _id: "NU"
        },
        {
            name: "Norfolk Island",
            value: "+672",
            _id: "NF"
        },
        {
            name: "Northern Mariana Islands",
            value: "+1670",
            _id: "MP"
        },
        {
            name: "Norway",
            value: "+47",
            _id: "NO"
        },
        {
            name: "Oman",
            value: "+968",
            _id: "OM"
        },
        {
            name: "Pakistan",
            value: "+92",
            _id: "PK"
        },
        {
            name: "Palau",
            value: "+680",
            _id: "PW"
        },
        {
            name: "Palestinian Territory, Occupied",
            value: "+970",
            _id: "PS"
        },
        {
            name: "Panama",
            value: "+507",
            _id: "PA"
        },
        {
            name: "Papua New Guinea",
            value: "+675",
            _id: "PG"
        },
        {
            name: "Paraguay",
            value: "+595",
            _id: "PY"
        },
        {
            name: "Peru",
            value: "+51",
            _id: "PE"
        },
        {
            name: "Philippines",
            value: "+63",
            _id: "PH"
        },
        {
            name: "Pitcairn",
            value: "+872",
            _id: "PN"
        },
        {
            name: "Poland",
            value: "+48",
            _id: "PL"
        },
        {
            name: "Portugal",
            value: "+351",
            _id: "PT"
        },
        {
            name: "Puerto Rico",
            value: "+1939",
            _id: "PR"
        },
        {
            name: "Qatar",
            value: "+974",
            _id: "QA"
        },
        {
            name: "Romania",
            value: "+40",
            _id: "RO"
        },
        {
            name: "Russia",
            value: "+7",
            _id: "RU"
        },
        {
            name: "Rwanda",
            value: "+250",
            _id: "RW"
        },
        {
            name: "Reunion",
            value: "+262",
            _id: "RE"
        },
        {
            name: "Saint Barthelemy",
            value: "+590",
            _id: "BL"
        },
        {
            name: "Saint Helena, Ascension and Tristan Da Cunha",
            value: "+290",
            _id: "SH"
        },
        {
            name: "Saint Kitts and Nevis",
            value: "+1869",
            _id: "KN"
        },
        {
            name: "Saint Lucia",
            value: "+1758",
            _id: "LC"
        },
        {
            name: "Saint Martin",
            value: "+590",
            _id: "MF"
        },
        {
            name: "Saint Pierre and Miquelon",
            value: "+508",
            _id: "PM"
        },
        {
            name: "Saint Vincent and the Grenadines",
            value: "+1784",
            _id: "VC"
        },
        {
            name: "Samoa",
            value: "+685",
            _id: "WS"
        },
        {
            name: "San Marino",
            value: "+378",
            _id: "SM"
        },
        {
            name: "Sao Tome and Principe",
            value: "+239",
            _id: "ST"
        },
        {
            name: "Saudi Arabia",
            value: "+966",
            _id: "SA"
        },
        {
            name: "Senegal",
            value: "+221",
            _id: "SN"
        },
        {
            name: "Serbia",
            value: "+381",
            _id: "RS"
        },
        {
            name: "Seychelles",
            value: "+248",
            _id: "SC"
        },
        {
            name: "Sierra Leone",
            value: "+232",
            _id: "SL"
        },
        {
            name: "Singapore",
            value: "+65",
            _id: "SG"
        },
        {
            name: "Slovakia",
            value: "+421",
            _id: "SK"
        },
        {
            name: "Slovenia",
            value: "+386",
            _id: "SI"
        },
        {
            name: "Solomon Islands",
            value: "+677",
            _id: "SB"
        },
        {
            name: "Somalia",
            value: "+252",
            _id: "SO"
        },
        {
            name: "South Africa",
            value: "+27",
            _id: "ZA"
        },
        {
            name: "South Sudan",
            value: "+211",
            _id: "SS"
        },
        {
            name: "South Georgia and the South Sandwich Islands",
            value: "+500",
            _id: "GS"
        },
        {
            name: "Spain",
            value: "+34",
            _id: "ES"
        },
        {
            name: "Sri Lanka",
            value: "+94",
            _id: "LK"
        },
        {
            name: "Sudan",
            value: "+249",
            _id: "SD"
        },
        {
            name: "Suriname",
            value: "+597",
            _id: "SR"
        },
        {
            name: "Svalbard and Jan Mayen",
            value: "+47",
            _id: "SJ"
        },
        {
            name: "Swaziland",
            value: "+268",
            _id: "SZ"
        },
        {
            name: "Sweden",
            value: "+46",
            _id: "SE"
        },
        {
            name: "Switzerland",
            value: "+41",
            _id: "CH"
        },
        {
            name: "Syrian Arab Republic",
            value: "+963",
            _id: "SY"
        },
        {
            name: "Taiwan",
            value: "+886",
            _id: "TW"
        },
        {
            name: "Tajikistan",
            value: "+992",
            _id: "TJ"
        },
        {
            name: "Tanzania, United Republic of Tanzania",
            value: "+255",
            _id: "TZ"
        },
        {
            name: "Thailand",
            value: "+66",
            _id: "TH"
        },
        {
            name: "Timor-Leste",
            value: "+670",
            _id: "TL"
        },
        {
            name: "Togo",
            value: "+228",
            _id: "TG"
        },
        {
            name: "Tokelau",
            value: "+690",
            _id: "TK"
        },
        {
            name: "Tonga",
            value: "+676",
            _id: "TO"
        },
        {
            name: "Trinidad and Tobago",
            value: "+1868",
            _id: "TT"
        },
        {
            name: "Tunisia",
            value: "+216",
            _id: "TN"
        },
        {
            name: "Turkey",
            value: "+90",
            _id: "TR"
        },
        {
            name: "Turkmenistan",
            value: "+993",
            _id: "TM"
        },
        {
            name: "Turks and Caicos Islands",
            value: "+1649",
            _id: "TC"
        },
        {
            name: "Tuvalu",
            value: "+688",
            _id: "TV"
        },
        {
            name: "Uganda",
            value: "+256",
            _id: "UG"
        },
        {
            name: "Ukraine",
            value: "+380",
            _id: "UA"
        },
        {
            name: "United Arab Emirates",
            value: "+971",
            _id: "AE"
        },
        {
            name: "United Kingdom",
            value: "+44",
            _id: "GB"
        },
        {
            name: "United States",
            value: "+1",
            _id: "US"
        },
        {
            name: "Uruguay",
            value: "+598",
            _id: "UY"
        },
        {
            name: "Uzbekistan",
            value: "+998",
            _id: "UZ"
        },
        {
            name: "Vanuatu",
            value: "+678",
            _id: "VU"
        },
        {
            name: "Venezuela, Bolivarian Republic of Venezuela",
            value: "+58",
            _id: "VE"
        },
        {
            name: "Vietnam",
            value: "+84",
            _id: "VN"
        },
        {
            name: "Virgin Islands, British",
            value: "+1284",
            _id: "VG"
        },
        {
            name: "Virgin Islands, U.S.",
            value: "+1340",
            _id: "VI"
        },
        {
            name: "Wallis and Futuna",
            value: "+681",
            _id: "WF"
        },
        {
            name: "Yemen",
            value: "+967",
            _id: "YE"
        },
        {
            name: "Zambia",
            value: "+260",
            _id: "ZM"
        },
        {
            name: "Zimbabwe",
            value: "+263",
            _id: "ZW"
        }
    ];
export const MOCKUP_LOCATION = [
    {
        name: "India",
        value: "1",
        _id: "1",
    },
    {
        name: "China",
        value: "2",
        _id: "2",
    }
];
export const MOCKUP_CANCERTYPE = [
    {
        name: "Lung Cancer",
        value: "1",
        _id: "1",
    },
    {
        name: "NHL",
        value: "2",
        _id: "2",
    },
];
export const MOCKUP_DURATION = [
    {
        name: "Monthly",
        value: "1",
        _id: "1",
    },
    {
        name: "Quarterly",
        value: "2",
        _id: "2",
    },
    {
        name: "Halfyearly",
        value: "3",
        _id: "3",
    },
    {
        name: "Yearly",
        value: "4",
        _id: "4",
    },
];
export const MOCKUP_MONTH = [
    {
        name: "Jan",
        value: "1",
        _id: "1",
    },
    {
        name: "Feb",
        value: "2",
        _id: "2",
    },
    {
        name: "Mar",
        value: "3",
        _id: "3",
    },
    {
        name: "Apr",
        value: "4",
        _id: "4",
    }, {
        name: "May",
        value: "5",
        _id: "5",
    }, {
        name: "Jun",
        value: "6",
        _id: "6",
    }, {
        name: "Jul",
        value: "7",
        _id: "7",
    }, {
        name: "Aug",
        value: "8",
        _id: "8",
    }, {
        name: "Sep",
        value: "9",
        _id: "9",
    }, {
        name: "Oct",
        value: "10",
        _id: "10",
    }, {
        name: "Nov",
        value: "11",
        _id: "11",
    }, {
        name: "Dec",
        value: "12",
        _id: "12",
    }
];
export const MOCKUP_MONTH_QAURTERLY = [
    {
        name: "January - March",
        value: "1",
        _id: "1",
    },
    {
        name: "April - June",
        value: "2",
        _id: "2",
    },
    {
        name: "July - September",
        value: "3",
        _id: "3",
    },
    {
        name: "October - December",
        value: "4",
        _id: "4",
    }
];
export const MOCKUP_MONTH_YEARLY = [
    {
        name: "January - December",
        value: "1",
        _id: "1",
    },
];
export const MOCKUP_MONTH_HALFYEARLY = [
    {
        name: "January - June",
        value: "1",
        _id: "1",
    },
    {
        name: "July - December",
        value: "2",
        _id: "2",
    }
];

const getAllYearUpToCurrentYear = () =>{
    let allYear = [
        {
            name: "2015",
            value: "1",
            _id: "1",
        },
        {
            name: "2016",
            value: "2",
            _id: "2",
        },
        {
            name: "2017",
            value: "3",
            _id: "3",
        },
        {
            name: "2018",
            value: "4",
            _id: "4",
        }, {
            name: "2019",
            value: "5",
            _id: "5",
        }, {
            name: "2020",
            value: "6",
            _id: "6",
        }, {
            name: "2021",
            value: "7",
            _id: "7",
        }, {
            name: "2022",
            value: "8",
            _id: "8",
        }, {
            name: "2023",
            value: "9",
            _id: "9",
        },
        {
            name: "2024",
            value: "10",
            _id: "10",
        },
        {
            name: "2025",
            value: "11",
            _id: "11",
        }
        , {
            name: "2026",
            value: "12",
            _id: "12",
        }, {
            name: "2027",
            value: "13",
            _id: "13",
        },
        {
            name: "2028",
            value: "14",
            _id: "14",
        },
        {
            name: "2029",
            value: "15",
            _id: "15",
        },
        {
            name: "2030",
            value: "16",
            _id: "16",
        }];
        let currentYearIndex = allYear.findIndex(e => e.name == new Date().getFullYear());
      return  allYear.slice(0, currentYearIndex+1) || allYear
}
export const MOCKUP_YEAR = getAllYearUpToCurrentYear();
export const MOCKUP_ROLE = [
    {
        name: "PN",
        value: "1",
        _id: "1",
    },
    {
        name: "NLA",
        value: "2",
        _id: "2",
    },
    {
        name: "ROCEH",
        value: "3",
        _id: "3",
    },
    {
        name: "Patient",
        value: "4",
        _id: "4",
    },
];
export const MOCKUP_FACILITY = [
    {
        name: "Mababane medical",
        value: "1",
        _id: "1",
    },
    {
        name: "Facility 2",
        value: "2",
        _id: "2",
    },
];
export const EXPORT_KEY = {
    JourneyPhase: "Journey Phase",
    Barriers: "Barriers",
    Travel: "Travel",
    Process: "Process",
    RiskFactors: "Risk Factors",
    AppUsage: "App Usage",
    CompletionRate: "Completion Rate",
    Referrals: "Referrals",
    Death: "Death",
}

export const STORAGE_KEY = {
    LoginInfo: "LoginInfo",
    Regions: "AllRegions",
    CancerTypes: "AllCancerType",
    CountryId: 'CountryId'
}




export const USER_ROLE = {
    PN: 'pn',
    ROCHE: 'admin',
    NLA: 'nla'
}

export const NAVIGATIONS_PATH = {
    PN: '/welcome',
    ROCHE: '/roche',
    NLA: '/NLA',
    LOGIN: '/login',
}

export const MOCKUP_ALLINSIGHTS_CATEGORY = [{
    name: 'Journey Phase',
    _id: 1,
    isChecked: true
},
{
    name: 'Barriers',
    _id: 2,
    isChecked: true
}, {
    name: 'Travel',
    _id: 3,
    isChecked: true
}, {
    name: 'Process',
    _id: 4,
    isChecked: true
}, {
    name: 'Risk Factors',
    _id: 5,
    isChecked: true
}, {
    name: 'App Usage',
    _id: 6,
    isChecked: true
}, {
    name: 'Completion Rate',
    _id: 7,
    isChecked: true
}, {
    name: 'Referrals',
    _id: 8,
    isChecked: true
}, {
    name: 'Death',
    _id: 9,
    isChecked: true
}]

export const API = {
    checkLogin: '/users/checkLoginWeb',
    allInsights: '/webrochestatisticsreport/fetchRocheAllInsightReport',
    allInsightsReport: '/webrochestatisticsreport/fetchRocheAllInsightReport',
    allInsightsBarrier: '/webrochestatisticsreport/fetchRocheAllInsightBarriers',
    getPatientJourney: '/webrochestatisticsreport/getRocheCancerPatientJourneryReport',
    getPatientJourneyDetail: '/webrochestatisticsreport/getRocheCancerPhaseWiseDetailsReport',
    fetchRochePhaseWiseBarrierList: '/webrochestatisticsreport/fetchRochePhaseWiseBarrierList',
    showBarrierDescriptionWithoutKeyword: '/webnlabarrier/showBarrierDescriptionWithoutKeyword',
    showBarrierDescriptionWithKeyword: '/webnlabarrier/showAllBarrierDescriptionWithKeyword',
    showAllPublishedBarriers: '/webnlabarrier/showAllPublishedBarriers',
    showAllundefinedBarrier: '/webnlabarrier/showAllundefinedBarrier',
    addBarrierKeyword: '/webnlabarrier/addBarrierKeyword',
    addDefBarrierToPNAPP: '/webnlabarrier/addDefBarrierToPNAPP',
    deleteBarrierDescription: '/webnlabarrier/dataDeleteBarrierDescription',
    deletePublishedBarrier: '/webnlabarrier/dataDeletePublishedBarrier',
    groupUndefinedBarriers: '/webnlabarrier/groupUndefinedBarriers',
    updatePublishedBarrierStatus: '/webnlabarrier/updatePublishedBarrierStatus',
    generateOTP: '/users/generateOTP',
    verifyOTP: '/users/verifyOTP',
    changePassword: '/users/changePassword',
    getAllCancerType: '/misc/fetchCancerTypeList',
    getAllRegions: '/misc/fetchRegionList',
    getAllRegionsV2: '/misc/fetchRegionListv2',
    getFetchUsers: '/webnlausers/fetchUsers',
    getFetchFacility: '/webnlafacility/fetchFacility',
    getAllPNUsers: '/webpnusers/showAllPNUsers',
    updateNlaPnUserStatus: '/webnlausers/updateStatus',
    getUserRoles: '/misc/fetchUserRoles',
    getAllFacility: '/webnlafacility/fetchAllFacility',
    getMiscAllFacility: '/misc/fetchAllFacility',
    addUser: '/webnlausers/addUser',
    updateUser: '/webnlausers/updateUser',
    getLocationByRegionList: '/misc/fetchLocationByRegionList',
    addFacility: '/webnlafacility/addFacility',
    updateFacility: '/webnlafacility/updateFacility',
    updateFacilityStatus: '/webnlafacility/updateStatus',
    deleteNLAUser: '/webnlausers/deleteUser',
    deleteNLAFacility: '/webnlafacility/deleteFacility',
    getFetchDoctors: '/webnladoctor/fetchDoctor',
    deleteNLADoctor: '/webnladoctor/deleteDoctor',
    getSpecialization: '/webnladoctor/fetchSpecialization',
    addDoctor: '/webnladoctor/addDoctor',
    updateDoctor: '/webnladoctor/updateDoctor',
    getPnFacility: '/misc/fetchAllFacility',
    getPnBarriers: '/webpnbarrier/showAllPublishedBarriers',
    updateDoctorStatus: '/webnladoctor/updateStatus',

    getAllPN: '/webnlapn/showPNPMonitor',
    getAllCountryByCancer: '/webrochestatisticsreport/fetchCountryList',
    updatePNPW: '/webpnusers/updatePNPW'
}

export const patientJourneyImages = {
    "Screening": <ScreeningImg />,
    "Diagnosis": <DiagnosisImg />,
    "Investigation": <InvestigationImg />,
    "Treatment planning": <TreatmentplanningImg />,
    "Treatment initiation": <TreatmentinitiationImg />
}

export const fills = {
    0: '#ada758',
    1: '#f6bcb8',
    2: '#edd5fc',
    3: '#f5c076',
    4: '#ad41e8',
    5: '#b42519',
    6: '#721491',
    7: '#ea3d35',
    8: '#b45019',
    9: '#721491',
    10: '#ea3a65'

}



