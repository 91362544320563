import { Box, Button, Typography, Modal, TextField, Label, FormLabel, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { API } from "../../../../constants/constant";
import axios from '../../../../http/axios'
import { useState } from "react";

const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        //width: 748,
        width: "39%",
        maxHeight: "460px",
        height: "85%",
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
    },
    Additem: {
        display: 'flex',
        flexDirection: 'column',
        margin: '30px 30px 0px 30px',
        height: "85%",
        gap: 40,
        overflow: "auto"
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -20,
        right: -15,
        width: 55,
        height: 55,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    }

};


const PopupGroupBarriers = (props) => {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [data, setData] = React.useState({sub_category: ""})
    const [loader, setLoader] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const handleClose = () => navigate(-1);
    console.log("state",state);
    const onChange = (key, e) => {
        let newData = {...data}
        newData[key] = e;
        setData(newData)
    }

    const handleSave = () => {
        let apiData = {
            "master_category_id": state?.data[0]?.master_barrier_category_id,
            "master_category_name": state?.data[0]?.category_name,
            "sub_category_name": data?.sub_category,
            "unbarrier_ids":state?.data.map(item => item.undefined_barrier_id),
            "cancer_id": state?.data[0]?.cancer_id
        }

        var config = {
            method: 'post',
            url: API.groupUndefinedBarriers,
            data: apiData,
            headers: { "Content-Type": "application/json" }
        };
        setLoader(true)
        axios.request(config)
        .then(function (response) {
            setLoader(false)
            navigate("/barriers/add-subcategory")
            // handleClose()
        })
        .catch(function (error) {
            console.log(error);
            setErrorMsg(error.message)
            setLoader(false)
        });
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={30} style={{ color: "#fff", marginTop: 10 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box sx={{ padding: '50px 50px 50px 50px', height:"100%", overflow: 'hidden' }}>
                        <Typography id="modal-modal-description" sx={{ mt: 2, color: '#546FE6', fontSize: 28, fontFamily: 'Montserrat-Bold' }} >
                            {'Group Barriers!'}
                        </Typography>
                        <Box style={style.Additem}>
                            <Box>
                                <FormLabel id="category-label" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Category</FormLabel>
                                <TextField id="category" fullWidth variant="standard" inputProps={{ style: { color: '#464A53', fontSize: 18, fontFamily: 'Poppins-Regular' }, readOnly: true }} defaultValue={state?.data[0]?.category_name} disabled />
                            </Box>
                            <Box>
                                <FormLabel id="subcategory-label" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Sub Category</FormLabel>
                                <TextField id="subcategory" fullWidth variant="standard" inputProps={{ style: { color: '#464A53', fontSize: 18, fontFamily: 'Poppins-Regular' } }} InputLabelProps={{ style: { color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }, }} value={data?.sub_category} onChange={(e) => onChange("sub_category", e.target.value)} helperText={errorMsg ? errorMsg : ''} error={errorMsg} />
                            </Box>
                            <Button
                                variant="contained"
                                style={{
                                    height: 50,
                                    marginBottom: 4,
                                    width: '-webkit-fill-available',
                                    marginTop: 20,
                                    borderRadius: 25,
                                    background: "#546FE6",
                                    textTransform: 'none',
                                    fontSize: 18,
                                    fontFamily: 'Poppins-SemiBold'
                                }}
                                disabled={data?.sub_category && !loader ? false : true}
                                onClick={handleSave}
                            >
                                {loader ? <CircularProgress color="inherit"/> : "Save"}
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
export default PopupGroupBarriers