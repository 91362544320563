import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/login/login'
import Splash from './components/splash/splash'
import Forgot from './components/login/forgotPassword'
import OtpScreen from './components/login/otp'
import SetPassword from './components/login/setPassword'
import PasswordReset from './components/login/passwordReset'
import Home from './components/NLA/index'
import UserRole from './components/NLA/settings/userRole';
import UserFacility from './components/NLA/settings/facility'
import Welcome from './components/PN/welcome';
import Barriers from './components/PN/barriers';
import PatientJourney from './components/NLA/statistics/patientJourney';
import CredentialDetails from "./components/PN/credentialDetails";
import UserCredentials from "./components/PN/userCredential";
import Addkeywords from "./components/NLA/barriers/addkeywords";
import GroupBarriers from "./components/NLA/barriers/groupbarriers";
import AddSubCategories from "./components/NLA/barriers/addsubcategories";
import PublishedBarriers from "./components/NLA/barriers/publishedbarriers";
import "./assets/css/theme.css";
import Delete from "./common/popup/delete";
import PopupAddKeyWords from "./components/NLA/barriers/popup/addkeywords";
import PopupGroupBarriers from "./components/NLA/barriers/popup/groupbarriers";
import PnpOnline from './components/NLA/PNP/index';
import Roche from './components/Roche/flags';
import WelcomeRoche from './components/Roche/index';
import Success from "./common/popup/success";
import BarrierInvestigation from "./components/NLA/statistics/barrierInvestigation";
import AllBarriers from './components/NLA/barriers';
import AllInsights from './components/NLA/statistics/allinsights';
import ExportInsights from './components/NLA/statistics/popups/export';
import StatisticAllBarriers from './components/NLA/statistics/AllBarriers';
import SettingSelectCategory from './components/NLA/popup/settingselectcategory';
import AddUser from './components/NLA/popup/addedituser';
import AddFacility from './components/NLA/popup/addfacility';
import AddEditUserCredentials from './components/PN/popup/addeditusercredentials';
import ReportPdf from './components/NLA/statistics/popups/report';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import Landing from './components';
import PrivateAuth from './common/methods/auth';
import Doctors from './components/NLA/settings/doctor';
import AddDoctor from './components/NLA/popup/addeditdoc';
const App = () => {

  const addKeySubRoute = <Route path="add-keywords" element={<Addkeywords />} >
    <Route path="delete" element={<Delete />} />
    <Route path="new-addkey" element={<PopupAddKeyWords />} />
  </Route>

  const grpBarriersSubRoute = <Route path="group-barriers" element={<GroupBarriers />} >
    <Route path="delete" element={<Delete />} />
    <Route path="new-groupbarriers" element={<PopupGroupBarriers />} />
  </Route>

  const addSubCatToMobileSubRoute = <Route path="add-subcategory" element={<AddSubCategories />} >
    <Route path="delete" element={<Delete />} />
    <Route path="success" element={<Success />} />
  </Route>

  const publishedBarriers = <Route path="published-barriers" element={<PublishedBarriers />} >
    <Route path="delete" element={<Delete />} />
  </Route>

  const allInsights = <Route path="reports/allinsights" element={<PrivateAuth><AllInsights /></PrivateAuth>} >
    <Route path="export-insights" element={<ExportInsights />} />
  </Route>

  return (
    <>
      < Provider store={store} >
        <Router basename="">

          <Routes>
            <Route path="/report-pdf" element={<ReportPdf />} />
            <Route path="/" element={<Splash />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/otp" element={<OtpScreen />} />
            <Route path="/setPassword" element={<SetPassword />} />
            <Route path="/NLA" element={<PrivateAuth><Home /></PrivateAuth>} >
              <Route path="Setting-Category" element={<SettingSelectCategory />} />
            </Route>
            <Route path="/user-role" element={<PrivateAuth><UserRole /></PrivateAuth>}>
              <Route path="add-user" element={<AddUser />} />
              <Route path="edit-user" element={<AddUser />} />
              <Route path="delete" element={<Delete />} />
            </Route>
            <Route path="/doctor" element={<PrivateAuth><Doctors /></PrivateAuth>}>
              <Route path="add-doctor" element={<AddDoctor />} />
              <Route path="edit-doctor" element={<AddDoctor />} />
              <Route path="delete" element={<Delete />} />
            </Route>
            <Route path="/user-facility" element={<PrivateAuth><UserFacility /></PrivateAuth>} >
              <Route path="add-facility" element={<AddFacility />} />
              <Route path="edit-facility" element={<AddFacility />} />
              <Route path="delete" element={<Delete />} />
            </Route>
            <Route path="/credentail-details" element={<PrivateAuth><CredentialDetails /></PrivateAuth>} />
            <Route path="/PNP-Online" element={<PrivateAuth><PnpOnline /></PrivateAuth>} />
            <Route path="/roche" element={<PrivateAuth><Roche /></PrivateAuth>} />
            <Route path="/roche-welcome" element={<PrivateAuth><WelcomeRoche /></PrivateAuth>} />
            <Route path="/reports/barrier-investigation" element={<PrivateAuth><BarrierInvestigation /></PrivateAuth>} />
            <Route path="/reports/AllBarries" element={<PrivateAuth><StatisticAllBarriers /></PrivateAuth>} />

            <Route path="/reports/patient-journey" element={<PrivateAuth><PatientJourney /></PrivateAuth>} />
            {allInsights}
            <Route path="/welcome" element={<PrivateAuth><Welcome /></PrivateAuth>} />
            <Route path="/welcome/barriers" element={<Barriers />} />
            <Route path="/user-credential" element={<PrivateAuth><UserCredentials /></PrivateAuth>} >
              <Route path="edit-credential" element={<AddEditUserCredentials />} />
            </Route>
            <Route path="/barriers" element={<PrivateAuth><AllBarriers /></PrivateAuth>}>
              {addKeySubRoute}
              {grpBarriersSubRoute}
              {addSubCatToMobileSubRoute}
              {publishedBarriers}
              <Route path="*" element={<h1>No Data</h1>} />

            </Route>

          </Routes>

        </Router>
      </Provider>

    </>

  );

};

export default App;





