import { Box, Divider, InputLabel, Paper } from "@mui/material"
import RiskFactors from "./riskfactors"
import Referral from "./referrals"
import { BiSolidUpArrow } from "react-icons/bi"
import store from "../../../../../redux/store/store"
import React, { useState, useEffect } from "react"


const AppUsage = (props) => {

    const [appUsage, setAppUsage] = useState(undefined)
    useEffect(() => {
        let { appUsage } = store?.getState()?.nlaReducer;
        setAppUsage({
            noOfActiveUsers: {
                value: appUsage?.total_active_users || 0,
                percentage: Math.abs(appUsage?.active_users_diff) || 0,
                isUp: parseInt(appUsage?.total_active_users_previous) < parseInt(appUsage?.total_active_users),
            },
            usageHrsPerWeek: {
                value: appUsage?.no_of_hrs_usage || 0, //RV doubt
                percentage: Math.abs(appUsage?.no_of_hrs_usage_diff) || 0,
                isUp: parseInt(appUsage?.no_of_hrs_usage_previous) > parseInt(appUsage?.no_of_hrs_usage),
                name: 'Hours',
            }
        })

    }, [store?.getState()?.nlaReducer?.appUsage])


    return (
        <Paper
            elevation={0}
            sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1, padding: '20px', height: 122 }}
        >
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                {/*NO OF ACTIVE USERS*/}
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 0",
                    }}>
                    <InputLabel
                        style={{
                            fontSize: 13,
                            fontFamily: "Poppins-Medium",
                            color: "#237BBC",
                        }}
                    >
                        {"NO OF ACTIVE USERS"}
                    </InputLabel>


                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            padding: "10px 0",
                        }}
                    >
                        <h3
                            style={{
                                fontSize: 25,
                                fontFamily: "Poppins-SemiBold",
                                color: "#00133A",
                                paddingRight: 10
                            }}
                        >
                            {appUsage?.noOfActiveUsers.value || 0}
                        </h3>
                        <span
                            style={{
                                fontSize: 14,
                                fontFamily: "Poppins-Medium",
                                paddingRight : 5,
                                color: appUsage?.noOfActiveUsers.isUp ? "#34D399" : "#F57C7C",
                            }}
                        >
                            {appUsage?.noOfActiveUsers.percentage || 0}
                        </span>
                        <BiSolidUpArrow color={appUsage?.noOfActiveUsers.isUp ? "#34D399" : "#F57C7C"} size={8} />
                    </Box>
                </Box>
                {/*USAGE (HOURS Per WEEK )*/}
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 0",
                    }}>
                    <InputLabel
                        style={{
                            fontSize: 13,
                            fontFamily: "Poppins-Medium",
                            color: "#237BBC",
                        }}
                    >
                        {"USAGE (HOURS Per WEEK )"}
                    </InputLabel>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <h3
                            style={{
                                fontSize: 25,
                                fontFamily: "Poppins-SemiBold",
                                color: "#00133A",
                                margin: 0,
                                paddingRight: 10
                            }}
                        >
                            {appUsage?.usageHrsPerWeek.value || 0}
                        </h3>
                        <span
                            style={{
                                fontSize: 14,
                                fontFamily: "Poppins-Medium",
                                paddingRight : 5,
                                color: appUsage?.usageHrsPerWeek.isUp ? "#34D399" : "#F57C7C",
                            }}
                        >
                            {appUsage?.usageHrsPerWeek.percentage || 0}
                        </span>
                        <BiSolidUpArrow color={appUsage?.usageHrsPerWeek.isUp ? "#34D399" : "#F57C7C"} size={8} />
                    </Box>
                    <p
                        style={{
                            fontSize: 12,
                            fontFamily: "Poppins-Regular",
                            color: "#4B4B4B",
                            height: 16
                        }}
                    >
                        {appUsage?.usageHrsPerWeek.name}
                    </p>
                </Box>
            </Box>
        </Paper>
    )
}
export default AppUsage