import React,{useState} from 'react'
import {RiContactsFill} from 'react-icons/ri'
import {useNavigate} from 'react-router-dom';
import '../../assets/css/home.css'
const CredentialDetails = () =>{
    const navigate = useNavigate();
    const navigateSplash = () => {
        navigate('/');
      };
      const navigateUser = () => {
        navigate('/user-credential');
      };
    return(
        <>
            <div className="splash">
                <div className='outer'>
                    <div className='inner'>
                        <p className="login-par">Welcome Patient Navigator !</p>
                        <p className="login-innerpar">Please select Credential details to proceed.</p>
                        <div className='d-flex flex-row justify-content-center icons-row'>
                            <div className='d-flex flex-column col-3 inner-col-credential' onClick={navigateUser}>
                                <RiContactsFill size="40px" color="#fff" className='cred-image'/>
                                <p className='credential-par'>Credential Details</p>
                            </div>
                        </div>
                        <div className='exit'>
                            <p onClick={navigateSplash} className='exit-par'>Exit</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}
export default CredentialDetails