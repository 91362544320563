import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import '../../assets/css/home.css'
import Popup from './popup1';
import Glyph from '../../assets/images/Glyph.png'
import Layer from '../../assets/images/Layer_1.png'
import Pie from '../../assets/images/pie-chart.png'
import Settings from '../../assets/images/settings.png'
import BackgroundBg from '../../assets/images/logo-bg.png'
import { Box, InputLabel } from '@mui/material';
import store from '../../redux/store/store';
import { setLogout } from '../../redux/actions/actions';

const NLA = () => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    navigate('Setting-Category');
    // setIsOpen(!isOpen);
  }
  const navigate = useNavigate();
  const onExit = () => {
    store.dispatch(setLogout());
    localStorage.clear();
    navigate('/', { replace: true });
  };
  const navigatePNP = () => {
    navigate('/PNP-Online');
  };
  const navigateBarrier = () => {
    navigate('/barriers')
  }
  const navigateStatisticsReports = () => {
    navigate('/reports/patient-journey')
  }
  return (
    <>
      <div style={{ backgroundImage: `url(${BackgroundBg})`, width: '100%', height: '549px' }}>
        <div className='outer'>
          <div className='inner-for'>
            <p className="login-par-mobile">Welcome NLA !</p>
            <p className="login-innerpar2">Please select a section that you want to visit.</p>
            <div className='d-flex flex-row justify-content-center icons-row'>

              <div className='d-flex flex-column col-2 inner-col' onClick={navigatePNP}>
                <img className='nla-img' src={Glyph} alt="" />
                <p className='setting-par'>PNP Monitor</p>
              </div>
              <div className='d-flex flex-column col-2 inner-col' onClick={navigateStatisticsReports}>
                <img className='nla-img' src={Pie} alt="" />
                <p className='setting-par'>Statistics & Reports</p>
              </div>
              <div className='d-flex flex-column col-2 inner-col' onClick={togglePopup}>
                <img className='nla-img' src={Settings} alt="" />
                <p className='setting-par'>Settings</p>
              </div>
              <div className='d-flex flex-column col-2 inner-col' onClick={navigateBarrier}>
                <img className='nla-img' src={Layer} alt="" />
                <p className='setting-par'>Barriers</p>
              </div>

            </div>

            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '0px 26px' }}>
              <InputLabel
                style={{
                  color: '#546FE6',
                  marginTop: 80,
                  fontSize: 25,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontFamily: 'Poppins-Bold'
                }}
                onClick={onExit}
              >
                {"Exit"}
              </InputLabel>
            </Box>
          </div>
        </div>
        <Outlet />
      </div >
    </>
  )
}
export default NLA