import { Box, Button, InputLabel, MenuItem, Select } from "@mui/material";
import { MOCKUP_CANCERTYPE, MOCKUP_DURATION, MOCKUP_MONTH, MOCKUP_MONTH_HALFYEARLY, MOCKUP_MONTH_QAURTERLY, MOCKUP_MONTH_YEARLY, MOCKUP_REGION, MOCKUP_YEAR } from "../../constants/constant";
import { useEffect, useState } from "react";
import store from "../../redux/store/store";
import { AiOutlineDown } from "react-icons/ai";

const Style = {

    dropdownTitle: {
        fontSize: 14,
        fontFamily: "Poppins-Regular",
        color: "#464A53",
        paddingLeft:2.5
    },
    select: {
        display: 'flex',
        minWidth: 170,
        height: 46.48,
        background: "#ffffff",
        borderRadius: 4,
        border: '1px solid #f4f6fa',
        //   zIndex: 2000
    }
};


const CommonDropDown = (props) => {
    const { callBack } = props;
    const [regions, setRegions] = useState({
        list: [],
        selectedItems: [],
        name: '',
        error: false,
    });
    const [cancerTypes, setCancerTypes] = useState({
        list: [],
        selectedItems: [],
        name: '',
        error: false,
    });

    const [duration, setDuration] = useState({
        list: MOCKUP_DURATION,
        selectedItems: [1],
        name: MOCKUP_DURATION[0].name,
        error: false,
    });
    const [month, setMonth] = useState({
        list: MOCKUP_MONTH,
        selectedItems: [MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).value],
        name: MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).name,
        error: false,
    });
    const [year, setYear] = useState({
        list: MOCKUP_YEAR,
        selectedItems: [MOCKUP_YEAR.find(e => e.name == new Date().getFullYear())?.value],
        name: MOCKUP_YEAR.find(e => e.name == new Date().getFullYear())?.name,
        error: false,
    });
    useEffect(() => {
        let { regions_list, cancerTypes_list } = store?.getState()?.auth;
        setRegions({
            list: regions_list,
            selectedItems: [0],
            name: regions_list?.[0]?.name,
            error: false,
        })
        setCancerTypes({
            list: cancerTypes_list,
            selectedItems: [1001],
            name: cancerTypes_list?.[0]?.name,
            error: false,
        });
        callBack({
            regions: {
                list: regions_list,
                selectedItems: [0],
                name: regions_list?.[0]?.name,
                error: false,
            }, cancerTypes: {
                list: cancerTypes_list,
                selectedItems: [1001],
                name: cancerTypes_list?.[0]?.name,
                error: false,
            }, duration, month, year
        });
    }, [store?.getState()?.auth])

    const handleRegionChange = (event, { props }) => {
        const {
            target: { value },
        } = event;
        setRegions({
            ...regions,
            selectedItems: typeof value === "string" ? value.split(",") : value,
            name: props.children
        });
    };
    const handleCancerTypeChange = (event, { props }) => {
        const {
            target: { value },
        } = event;
        setCancerTypes({
            ...cancerTypes,
            selectedItems: typeof value === "string" ? value.split(",") : value,
            name: props.children
        });
    };
    const handleDurationChange = (event, { props }) => {
        const {
            target: { value },
        } = event;
        setDuration({
            ...duration,
            selectedItems: typeof value === "string" ? value.split(",") : value,
            name: props.children
        });
    };
    const handleMonthChange = (event, { props }) => {
        const {
            target: { value },
        } = event;
        setMonth({
            ...month,
            selectedItems: typeof value === "string" ? value.split(",") : value,
            name: props.children
        });
    };
    const handleYearChange = (event, { props }) => {
        const {
            target: { value },
        } = event;
        setYear({
            ...year,
            selectedItems: typeof value === "string" ? value.split(",") : value,
            name: props.children
        });
    };

    useEffect(() => {
        setMonthData()
    }, [duration])

    // useEffect(() => {
    //     props.callBack({ regions, cancerTypes, duration, month, year })
    // }, []
    // )

    const setMonthData = () => {
        if (duration) {
            switch (duration.name) {
                case duration.list[0].name:
                    setMonth({
                        list: MOCKUP_MONTH,
                        selectedItems: [MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).value],
                        name: MOCKUP_MONTH.find(e => e._id == new Date().getMonth() + 1).name,
                        error: false,
                    })
                    break
                case duration.list[1].name:
                    setMonth({
                        list: MOCKUP_MONTH_QAURTERLY,
                        selectedItems: [1],
                        name: MOCKUP_MONTH_QAURTERLY[0].name,
                        error: false,
                    })
                    break
                case duration.list[2].name:
                    setMonth({
                        list: MOCKUP_MONTH_HALFYEARLY,
                        selectedItems: [1],
                        name: MOCKUP_MONTH_HALFYEARLY[0].name,
                        error: false,
                    })
                    break
                case duration.list[3].name:
                    setMonth({
                        list: MOCKUP_MONTH_YEARLY,
                        selectedItems: [1],
                        name: MOCKUP_MONTH_YEARLY[0].name,
                        error: false,
                    })
                    break
            }
        }
    }


    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                // margin: '20px',
                marginTop: '10px',
                gap: 4,
                justifyContent: 'space-between'
            }}
        >
            <Box style={{ padding: 0, flex: 1 }}>
                <InputLabel style={Style.dropdownTitle}>
                    Region
                </InputLabel>
                <Select
                    value={regions.selectedItems}
                    onChange={handleRegionChange}
                    style={Style.select}
                >
                    {(regions.list || []).map((item) => {
                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                    })}
                </Select>
            </Box>
            {!props?.hideCancerType && <Box style={{ padding: 0, flex: 1 }}>
                <InputLabel id="cancertype" style={Style.dropdownTitle}>
                    Cancer Type
                </InputLabel>
                <Select
                    value={cancerTypes.selectedItems}
                    onChange={handleCancerTypeChange}
                    style={Style.select}
                >
                    {(cancerTypes.list || []).map((item) => {
                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                    })}
                </Select>
            </Box>}
            {!props?.hideDuration && <Box style={{ padding: 0, flex: 1 }}>
                <InputLabel id="duration" style={Style.dropdownTitle}>
                    Duration
                </InputLabel>
                <Select
                    value={duration.selectedItems}
                    onChange={handleDurationChange}
                    style={Style.select}
                >
                    {(duration.list || []).map((item) => {
                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                    })}
                </Select>
            </Box>}
            <Box style={{ padding: 0, flex: 1 }}>
                <InputLabel id="month" style={Style.dropdownTitle}>
                    Month
                </InputLabel>
                <Select
                    value={month.selectedItems}
                    onChange={handleMonthChange}
                    style={Style.select}
                >
                    {(month.list || []).map((item) => {
                        return <MenuItem value={item.value} >{item.name}</MenuItem>;
                    })}
                </Select>
            </Box>
            <Box style={props?.yearBoxStyle ? props?.yearBoxStyle: {}}>
                <InputLabel id="year" style={Style.dropdownTitle}>
                    Year
                </InputLabel>
                <Select
                    value={year.selectedItems}
                    onChange={handleYearChange}
                    
                    // IconComponent={(props) => (<AiOutlineDown  {...props}/>)}
                    style={Style.select}
                >
                    {(year.list || []).map((item) => {
                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                    })}
                </Select>
            </Box>
            <Box sx={{ display: "flex", height: 60, alignItems: "end" }}>
                <Button
                    variant="contained"
                    style={{
                        height: 44, marginBottom: "", width: 109, background: "#546FE6", textTransform: 'none', fontSize: 18, fontFamily: 'Poppins-SemiBold'
                    }}
                    onClick={() => { callBack({ regions, cancerTypes, duration, month, year }) }}
                >
                    Go
                </Button>
            </Box>
        </Box>
    )
}
export default CommonDropDown