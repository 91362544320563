import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Link, Box, LinearProgress, CircularProgress } from '@mui/material';
import { RiDeleteBinLine } from "react-icons/ri";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setAllUndefinedBarrierList, setBarrierLoader, setBarrierPage } from '../../../redux/actions/nlaactions';
import { API } from '../../../constants/constant';
import store from "../../../redux/store/store";
import axios from "../../../http/axios";
import CommonPagination from '../../../common/control/commonpagination';
import {ReactComponent as DeleteIcon} from "../../../assets/webapp_icon/delete.svg";

const columns = [
    { id: "category", label: "Category", minWidth: 130, l: 23 },
    { id: "des", label: "Subcategory", minWidth: 100 },
    { id: "addtopn", label: "", minWidth: 170 },
    { id: "action", label: "Actions", minWidth: 100, align: "center" },
];


const style = {
    spinner: {
      display: 'flex', position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      alignItems: 'center',
      justifyContent: 'center',
    }
  }

const AddSubCategories = () => {

    const { allUndefinedBarrierList, allUndefinedBarrierCount, dropdownData, isLoading, barrierPage } = useSelector(state => state.nlaReducer)
    const {state} = useLocation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();

    const handleChangePage = (newPage) => {
        store.dispatch(setBarrierPage(newPage))
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        store.dispatch(setBarrierPage(1))
    };

    const handleDelete = (item) => {
        navigate('delete', { state: { title: "Delete", res: "Are you sure you want to delete the barrier ?", apiPath: API.deletePublishedBarrier, apiData: {
            subcategory_id: item?.subcategory_id
        } } })
    }

    const showAllundefinedBarrier = (data) => {
        let config = {
          method: 'get',
          url: `${API.showAllundefinedBarrier}?cancer_id=${data?.cancerTypes?.selectedItems[0]}&duration=${data?.duration?.name}&page_no=${barrierPage}&limit=${rowsPerPage}&month_id=${data?.month?.selectedItems[0]}&region_id=${data?.regions?.selectedItems[0]}&year=${data?.year?.name}`
        };
        store.dispatch(setBarrierLoader(true))
        axios.request(config)
        .then(function (response) {
            let _data = response?.data?.data || {}
            store.dispatch(setAllUndefinedBarrierList(_data))
        })
        .catch(function (error) {
            store.dispatch(setBarrierLoader(false))
            alert(error?.message)
        });
      }

    useEffect(() => {
        if(!state && dropdownData) {
            showAllundefinedBarrier(dropdownData)
        }
    },[state, dropdownData, barrierPage, rowsPerPage])

    useEffect(() => {
        return () => {
            store.dispatch(setBarrierPage(1))
        }
    },[])

    const handleSave = (item) => {
        let apiData = {
            "subcategory_id":item?.subcategory_id
        }

        var config = {
            method: 'put',
            url: API.addDefBarrierToPNAPP,
            data: apiData,
            headers: { "Content-Type": "application/json" }
        };
        setLoader(true)
        axios.request(config)
        .then(function (response) {
            setLoader(false)
            navigate('success', { state: { title: "Added", res: "New Subcategory has been successfully added to defined barriers." } })
        })
        .catch(function (error) {
            setLoader(false)
        });
    }


    return (<>
        <Box sx={{ width: "100%", boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA", borderRadius: "8px", background: '#FFFFFF', }}>
            {loader && <Box sx={style.spinner}>
                <CircularProgress />
            </Box>}
            <TableContainer sx={{
                height: "calc(100vh - 320px)", overflow: "auto", borderRadius: "8px"
            }}>
                <Table>
                    <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
                        <TableRow style={{ background: '#F9F9F9 0% 0% no-repeat padding-box', height: "60px" }}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Medium' }}
                                >
                                    <Box style={{ paddingLeft: column.l }}>
                                    {column.label}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoading ? allUndefinedBarrierList
                            .map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                        style={{ height: 60 }}
                                    >
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                            <Box style={{paddingLeft:23}}>{row?.category_name}</Box>                                     
                                        </TableCell>
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular' }}>{row?.subcategory_name}</TableCell>
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                            <Link
                                                underline="always"
                                                style={{
                                                    color: "#1542F2",
                                                    fontWeight: 600,
                                                    fontFamily: "Poppins-Regular",
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleSave(row)}
                                            >
                                                Add to PN App/ Defined barriers
                                            </Link>
                                        </TableCell>
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular', textAlign: 'center' }}>
                                            <DeleteIcon
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                color="#EE2D2D"
                                                onClick={() => handleDelete(row)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }): <TableRow> <TableCell colSpan={6} style={{textAlign: 'center'}}><LinearProgress /></TableCell></TableRow>}
                            {isLoading ? null : allUndefinedBarrierList?.length === 0 && ( <TableRow> <TableCell colSpan={6} style={{textAlign: 'center'}}>No records found</TableCell> </TableRow> )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Outlet />
        </Box>
        <CommonPagination
            count={allUndefinedBarrierCount}
            rowsPerPage={rowsPerPage}
            page={barrierPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </>)
}

export default AddSubCategories