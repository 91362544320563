import { Navigate, Outlet, useLocation } from "react-router-dom";

import {
    Box,
    Button,
    InputLabel,
    Typography,
    Divider,
    Paper,
    CircularProgress,
} from "@mui/material";

import { useEffect, useState } from "react";
import "../../../assets/css/theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BsFillSquareFill } from "react-icons/bs";
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { API } from "../../../constants/constant";
import "../../../assets/css/patientJourney.css";
import { TopNavigation } from "../../../common/control/topnavigation";
import AllInsightsI from '../../../assets/images/navigator/allinsights.png'
import { ProgressWithDynamicTooltip } from "../../../common/control/progresswithdynamictooltip";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts";
import store from "../../../redux/store/store";
import axios from '../../../http/axios'
import { setPatientJourneyDetail, setPatientJourneyLoader } from "../../../redux/actions/rocheactions";
import { useSelector } from "react-redux";
import { CustomLoader } from "../../../common/control/customloader";


const RADIAN = Math.PI / 180;

const theme = createTheme({
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: '12',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h6',
                    subtitle1: 'h2',
                    subtitle2: 'p',
                    body1: 'span',
                    body2: 'span',
                },
                fontFamily: 'Montserrat-Bold',
                color: '#FFFFFF'
            },
        },
    },
});

const Style = {
    spinner: {
      display: "flex",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      alignItems: "center",
      justifyContent: "center",
    },
  };

const BarrierInvestigation = (props) => {
    const { state } = useLocation();
    const screenName = state?.journey_stage_name;
    const navigate = useNavigate();
    const { patientJourneyDetail, isLoading } = useSelector(state => state.rocheReducer)
    const {country_id} = useSelector(state => state.auth)

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${API.getPatientJourneyDetail}?cancer_type=${state?.cancerTypes?.selectedItems[0]}&journey_phase_id=${state?.journey_stage_id}&region=${state?.regions.selectedItems[0]}&duration=${state?.duration?.name}&duration_month=${state?.month?.selectedItems[0]}&year=${state?.year?.name}&country_id=${country_id}`
        };
        store.dispatch(setPatientJourneyLoader(true))
        axios.request(config)
            .then(function (response) {
                if (response?.data) {
                    store.dispatch(setPatientJourneyDetail(response?.data?.data))
                }
            })
            .catch(function (error) {
                console.log(error);
                store.dispatch(setPatientJourneyLoader(false))
            });

    }, [])


    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="#7A7A7A" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 10, fontFamily: ' Poppins-Regular' }}>
                {value}
            </text>
        );
    };

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        return (
            <g>
                <text x={cx} y={cy - 8} dy={8} textAnchor="middle" fill={'#7A7A7A'} style={{ fontSize: 16, fontFamily: 'Poppins-SemiBold' }}>
                    {patientJourneyDetail?.patient_data?.reduce((total, i) => (i?.patient_count || 0) + total, 0) || 0}
                </text>
                <text x={cx} y={cy + 2} dy={13} textAnchor="middle" fill={'#7A7A7A'} style={{ fontSize: 12, fontFamily: 'Poppins' }}>Patients</text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    };

    const onViewAllBarrier = () => {
        navigate('/reports/AllBarries', { state: {...state} })
    }
    const _onTopNavButtonCallBack = () => {
        navigate('/reports/allinsights');
    }

    if(!state) {
        return <Navigate to={'/reports/patient-journey'} />
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ background: "#F6F7FA", paddingBottom: 0.1 }}>

                <TopNavigation navPath={'/reports/patient-journey'} title={screenName}
                    button={{ startIcon: AllInsightsI, name: 'All Insights',width: '177px', _onTopNavButtonCallBack }}>
                    <box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'center',
                            flex: 1
                        }}
                    >
                        <InputLabel
                            style={{
                                fontSize: 20,
                                fontFamily: "Montserrat-Medium",
                                color: "white",
                            }}
                        >
                            {state?.regions?.name}
                        </InputLabel>
                        <Box
                            style={{
                                height: 40,
                                width: 1,
                                margin: '0 20px',
                                // transform: "rotate(90deg)",
                                background: "white",
                            }}
                        />
                        <InputLabel
                            style={{
                                fontSize: 20,
                                fontFamily: "Montserrat-Medium",
                                color: "white",
                            }}
                        >
                            {state?.cancerTypes?.name}
                        </InputLabel>
                        <Box
                            style={{
                                height: 40,
                                width: 1,
                                margin: '0 20px',
                                background: "white",
                            }}
                        />
                        <InputLabel
                            style={{
                                fontSize: 20,
                                fontFamily: "Montserrat-Medium",
                                color: "white",
                            }}
                        >
                            {state?.month?.name + ' ' + state?.year?.name}
                        </InputLabel>
                    </box>
                </TopNavigation>
                {isLoading ?
                    <div style={Style.spinner}><CustomLoader /></div> :
                    <Box style={{ background: '#fefefe', margin: 10 }}>

                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "20px",
                            }}
                        >
                            <Box sx={{ flex: 1, flexDirection: "column" }}>
                                <InputLabel
                                    style={{
                                        fontSize: 16,
                                        fontFamily: "Poppins-Regular",
                                        color: "#727272",
                                    }}
                                >
                                    {"No of days"}
                                </InputLabel>
                                <ProgressWithDynamicTooltip data={{
                                    estimatedDays: parseInt(patientJourneyDetail?.standard_days || 0),
                                    actual: parseInt(patientJourneyDetail?.actual_days || 0)
                                }}
                                    height={35} />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <InputLabel
                                        style={{
                                            fontSize: 16,
                                            fontFamily: "Poppins-Regular",
                                            color: "#727272",
                                        }}
                                    >
                                        {patientJourneyDetail?.start_process}
                                    </InputLabel>
                                    <InputLabel
                                        style={{
                                            fontSize: 16,
                                            fontFamily: "Poppins-Regular",
                                            color: "#727272",
                                        }}
                                    >
                                        {patientJourneyDetail?.end_process}
                                    </InputLabel>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                padding: "20px",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    maxHeight: 600,
                                    maxWidth: 280,
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    minHeight: 250,
                                    justifyContent: 'space-between'
                                }}>
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: 'row', gap: 2, alignItems: 'center' }} >
                                        <InputLabel style={{ fontSize: 18, fontFamily: "Poppins-regular", color: "#656565", lineHeight: "1em" }}>
                                            {'Estimated Days'}
                                        </InputLabel >
                                        <InputLabel style={{ fontSize: 40, fontFamily: "Poppins-Medium", color: "#4B4B4B", lineHeight: "1em", paddingLeft: 15 }}>
                                            {parseInt(patientJourneyDetail?.standard_days || 0)}
                                        </InputLabel >

                                        {parseInt(patientJourneyDetail?.standard_days || 0) - parseInt(patientJourneyDetail?.actual_days || 0) < 0 ? <BsCaretUpFill
                                            style={{ height: 19, width: 22, cursor: "pointer", color: "#EE2D2D", }}
                                        /> :
                                            <BsCaretDownFill style={{ height: 19, width: 22, cursor: "pointer", color: "#62E89C", }} />}
                                        <InputLabel
                                            style={{ display: 'flex', alignItems: 'center', fontSize: 29, fontFamily: "Poppins-Medium", color: parseInt(patientJourneyDetail?.standard_days) - parseInt(patientJourneyDetail?.actual_days) < 0 ? "#EE2D2D" : "#62E89C", }}>
                                            {Math.abs(parseInt(patientJourneyDetail?.standard_days || 0) - parseInt(patientJourneyDetail?.actual_days || 0)) || 0}
                                        </InputLabel>


                                    </div>
                                    <Divider variant="middle" style={{ borderColor: 'gray', borderStyle: 'dashed', width: 200 }} />

                                    <InputLabel style={{ fontSize: 18, fontFamily: "Poppins-regular", color: "#656565", lineHeight: "1em" }}>
                                        {'Process'}
                                    </InputLabel >
                                    <Box mt="20px">
                                        <PieChart width={150} height={150} style={{ margin: 'auto' }} onMouseEnter={() => { }}>
                                            <Pie
                                                data={patientJourneyDetail?.patient_data}
                                                innerRadius={35}
                                                outerRadius={65}
                                                labelLine={false}
                                                cx="50%"
                                                cy="50%"
                                                label={renderCustomizedLabel}
                                                fill="#8884d8"
                                                paddingAngle={0}
                                                dataKey="patient_count"
                                                activeIndex={0}
                                                activeShape={renderActiveShape}
                                            >
                                                {patientJourneyDetail?.patient_data.map((entry, index) => (
                                                    <>
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={entry?.step_color}
                                                        >
                                                        </Cell>
                                                        <Tooltip placement="top" arrow title={entry?.journey_step_name}></Tooltip>
                                                    </>
                                                ))}
                                            </Pie>
                                        </PieChart>
                                        <Box style={{
                                            // margin: 'auto',
                                            marginTop: 10,
                                        }}>
                                            {patientJourneyDetail?.patient_data.map((processItem, i) => {
                                                return (
                                                    <Box style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        // alignItems: "center",
                                                        gap: 5,
                                                        width: 120,
                                                        margin: 'auto'
                                                    }}>
                                                        <BsFillSquareFill color={processItem?.step_color} size={9} style={{marginTop: 6}} />
                                                        <Typography
                                                            style={{
                                                                fontSize: 14,
                                                                fontFamily: "Poppins-Medium",
                                                                color: "#838383",
                                                                width: 100
                                                            }}
                                                        >
                                                            {processItem.journey_step_name}
                                                        </Typography>
                                                    </Box>
                                                )
                                            })}
                                        </Box>

                                    </Box>
                                </Box>

                                <Button
                                    variant="contained"
                                    style={{
                                        height: 45,
                                        width: 194,
                                        background: "#546FE6",
                                        textTransform: 'none',
                                        fontSize: 18,
                                        fontFamily: 'Poppins-SemiBold'
                                    }}
                                    onClick={onViewAllBarrier}

                                >
                                    View All Barriers
                                </Button>

                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    marginLeft: 2,
                                    flex: 1
                                }}>
                                {patientJourneyDetail?.process_milestone_data.map((item) => {
                                    return (
                                        <Paper elevation={0} style={{ background: 'white', border: '1px solid #EAEBEC', borderRadius: 14, boxShadow: '0px 2px 48px #0000000A' }}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    padding: 15,
                                                }}
                                            >

                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: 10
                                                }}>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 16,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#202223",
                                                        }}
                                                    >
                                                        {"Process:"}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 16,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {item?.journey_step_name}
                                                    </InputLabel>
                                                </Box>
                                                <Divider
                                                    style={{
                                                        height: 1,
                                                        width: 40,
                                                        transform: "rotate(90deg)",
                                                        background: "gray",
                                                    }}
                                                />
                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: 10
                                                }}>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 16,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#202223",
                                                        }}
                                                    >
                                                        {"Milestone:"}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 16,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {item?.journey_event_name}
                                                    </InputLabel>
                                                </Box>
                                            </Box>
                                            <Divider variant="middle" style={{ borderColor: 'gray', borderStyle: 'dashed', margin: 10 }} />
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: 'wrap',
                                                    alignItems: "center",
                                                    padding: 15,
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Box>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 24,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#546FE6",
                                                        }}
                                                    >
                                                        {item?.completion_rate + '%'}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {"Completion Rate"}
                                                    </InputLabel>
                                                </Box>
                                                <Box>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 24,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#546FE6",
                                                        }}
                                                    >
                                                        {item?.repetition_rate + '%'}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {"Repetition Rate"}
                                                    </InputLabel>
                                                </Box>
                                                <Box>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 24,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#546FE6",
                                                        }}
                                                    >
                                                        {item?.discontinue_patient}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {"Discontinued Patients"}
                                                    </InputLabel>
                                                </Box>
                                                <Box>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 24,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#546FE6",
                                                        }}
                                                    >
                                                        {item?.rescheduled_appointment}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {"Rescheduled Appointments"}
                                                    </InputLabel>
                                                </Box>
                                                <Box>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 24,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#546FE6",
                                                        }}
                                                    >
                                                        {item?.cancelled_appointment}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {"Cancelled Appointments"}
                                                    </InputLabel>
                                                </Box>
                                                <Box>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 24,
                                                            fontFamily: "Poppins-SemiBold",
                                                            color: "#546FE6",
                                                        }}
                                                    >
                                                        {item?.delay_30_days}
                                                    </InputLabel>
                                                    <InputLabel
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: "Poppins-Regular",
                                                            color: "#82888A",
                                                        }}
                                                    >
                                                        {"30 Days Delay"}
                                                    </InputLabel>
                                                </Box>

                                            </Box>
                                        </Paper>
                                    )
                                })}
                            </Box>

                            <Outlet />
                        </Box>
                    </Box>}
            </Box >
        </ThemeProvider >
    );
};

export default BarrierInvestigation;
