import { Box, Button, Typography, Modal, TextField, Label, FormLabel, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { API } from "../../../../constants/constant";
import axios from '../../../../http/axios'


const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        // width: 748,
        width: "39%",
        maxHeight: "560px",
        height: "85%",
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
    },
    Additem: {
        display: 'flex',
        flexDirection: 'column',
        margin: '30px 30px 0px 30px',
        height: "85%",
        gap: 40,
        overflow: "auto"
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -20,
        right: -15,
        width: 55,
        height: 55,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    }

};

const PopupAddKeyWords = (props) => {
    const [open, setOpen] = React.useState(true);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const handleClose = () => navigate(-1);
    const [data, setData] = useState({description: state?.description || "", keyword: ""})

    const onChange = (e,key) => {
        let newData = {...data}
        newData[key] = e;
        setData(newData)
    }

    const onAddKeyword = (apidata) => {
        let apiData = {
            undefined_barrier_id: apidata?.undefined_barrier_id,
            description: data?.description,
            keyword: data?.keyword
        }

        var config = {
            method: 'put',
            url: API.addBarrierKeyword,
            data: apiData,
            headers: { "Content-Type": "application/json" }
        };
        setLoader(true)
        axios.request(config)
        .then(function (response) {
            setLoader(false)
            navigate("/barriers/group-barriers")
            // handleClose()
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false)
            alert(error?.message)
        });
        
        
    }

    const handleAdd = () => {
        if(state) {
            onAddKeyword(state)
        } else {
            handleClose()
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={30} style={{ color: "#fff", marginTop: 10 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box sx={{ padding: '50px 50px 60px 50px', height:"100%", overflow: 'hidden' }}>
                        <Typography id="modal-modal-description" sx={{ mt: 2, color: '#546FE6', fontSize: 28, fontFamily: 'Montserrat-Bold' }} >
                            {'Add Keywords!'}
                        </Typography>
                        <Box style={style.Additem}>
                            <Box>
                                <FormLabel id="category-label" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Category</FormLabel>
                                <TextField id="category" fullWidth variant="standard" 
                                    sx={{
                                    color: '#464A53',
                                    '& .MuiInputBase-input': {
                                    fontSize: '18px', 
                                    fontFamily: 'Poppins-Regular', 
                                    },
                                    }}
                                 disabled value={state?.category_name} />
                            </Box>
                            <Box>
                                <FormLabel id="description-label" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Description</FormLabel>
                                <TextField id="description" fullWidth variant="standard" 
                                    sx={{
                                    color: '#464A53',
                                    '& .MuiInputBase-input': {
                                    fontSize: '18px', 
                                    fontFamily: 'Poppins-Regular', 
                                    },
                                    }}
                                 InputLabelProps={{ style: { color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }, }} value={data?.description} onChange={(e) => onChange(e.target.value, "description")} />
                            </Box>
                            <Box>
                                <FormLabel id="keywords-label" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Keywords <span style={{fontFamily:"Poppins-Regular", fontSize:16, color:"#EE2D2D"}}>*</span></FormLabel>
                                <TextField id="keywords" fullWidth variant="standard" 
                                    sx={{
                                    color: '#464A53',
                                    '& .MuiInputBase-input': {
                                    fontSize: '18px', 
                                    fontFamily: 'Poppins-Regular', 
                                    },
                                    }}
                                 InputLabelProps={{ style: { color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }, }} value={data?.keyword} onChange={(e) => onChange(e.target.value, "keyword")} />
                            </Box>
                            <Button
                                variant="contained"
                                style={{
                                    height: 50,
                                    marginBottom: 4,
                                    width: '-webkit-fill-available',
                                    marginTop: 20,
                                    borderRadius: 30,
                                    background: "#546FE6",
                                    textTransform: 'none',
                                    fontSize:20,
                                    fontFamily: 'Poppins-SemiBold'
                                }}
                                onClick={handleAdd}
                                disabled={data?.description && data?.keyword && !loader ? false : true}
                            >
                                {loader ? <CircularProgress color="inherit"/> : "Save"}
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
export default PopupAddKeyWords