import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./barriers.scss";
import {
  Box
} from "@mui/material";

import { useEffect } from "react";
import * as _ from "lodash";
import "../../../assets/css/theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BarrierTopNav } from "../../../constants/constant";
import { TopNavigation } from "../../../common/control/topnavigation";
import { setBarrierDropdownData, setBarrierPage } from "../../../redux/actions/nlaactions";
import store from "../../../redux/store/store";
import CommonDropDown from "../../../common/control/commondropdown";

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: '12',
          h4: 'h2',
          h5: 'h2',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'p',
          body1: 'span',
          body2: 'span',
        },
        fontFamily: 'Montserrat-Bold',
        color: '#FFFFFF'
      },
    },
  },
});
const AllBarriers = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('add-keywords') //initial route
  }, [])

  const _dropdownSelectedItems = (item) => {
    store.dispatch(setBarrierPage(1))
    store.dispatch(setBarrierDropdownData(item))
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ background: "#F6F7FA", minHeight: "100vh" }}>
        <TopNavigation navPath={'/NLA'} title={'All Barriers'}
        />
        <nav>
          <Box className={"top-menu"}>
            {(BarrierTopNav || []).map((item) => {
              return (<Box className={item.path === pathname.split("/").slice(-1)[0] ? "top-menu-item" : "top-menu-item-inactive"}>
                <Link to={item.path} className={"section-title"} style={{ textDecoration: 'none', color: item.path === pathname.split("/").slice(-1)[0] ? 'white' : '#707070', fontSize: 17, fontFamily: 'Poppins-Bold' }}>
                  {item.label}
                </Link>
              </Box>)
            })}
          </Box>
          
        </nav>
        <Box
          sx={{
            margin: "10px 60px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
        <CommonDropDown callBack={_dropdownSelectedItems} />
        </Box>
        <Box
          sx={{
            margin: "10px 60px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Outlet />
        </Box>
      </Box >
    </ThemeProvider >
  );
};

export default AllBarriers;
