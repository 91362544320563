import { Box, InputLabel, Paper } from "@mui/material"
import RiskFactors from "./riskfactors"
import Referral from "./referrals"
import Travel from "./travel"
import AppUsage from "./appusage"
import Deaths from "./deaths"


const StatisticsOtherSection = ({ category }) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: '30px',
            flexWrap: "wrap",
            justifyContent: 'center',
        }}>
            {(!category || category?.['Risk Factors']?.isChecked) &&
                <Box style={{ flex: 1 }}>
                    <InputLabel
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins-Medium",
                            color: "#554886",
                            marginLeft: '8px'
                        }}
                    >
                        Risk Factors
                    </InputLabel>
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                            m: 1,
                            minWidth: 451,
                            flex: 1
                            // height: 485,
                        }
                    }} >

                        <RiskFactors />
                    </Box>
                </Box>}
            {(!category || category?.['Referrals']?.isChecked) &&
                <Box style={{ flex: 1 }}>
                    <InputLabel
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins-Medium",
                            color: "#554886",
                            marginLeft: '8px'
                        }}
                    >
                        Referrals
                    </InputLabel>
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                            m: 1,
                            flex: 1
                            // minWidth: 360,
                            // height: 485,
                        }
                    }} >

                        <Referral />
                    </Box>
                </Box>}
                
            <Box style={{ flex: 1 }}>
                {(!category || category?.['Travel']?.isChecked) &&
                    <> <InputLabel
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins-Medium",
                            color: "#554886",
                            marginLeft: '8px'
                        }}
                    >
                        Travel
                    </InputLabel>
                        <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            "& > :not(style)": {
                                m: 1,
                                minWidth: 360,
                                flex: 1,
                                marginBottom: '22px'
                                // height: 485,
                            }
                        }} >

                            <Travel />
                        </Box></>
                }
                {(!category || category?.['App Usage']?.isChecked) &&
                    <><InputLabel
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins-Medium",
                            color: "#554886",
                            marginLeft: '8px'
                        }}
                    >
                        App Usage
                    </InputLabel>
                        <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            "& > :not(style)": {
                                m: 1,
                                flex: 1,
                                minWidth: 360,
                                marginBottom: '22px'
                                // height: 485,
                            }
                        }} >
                            <AppUsage />

                        </Box>
                    </>
                }
                {(!category || category?.['Death']?.isChecked) &&
                    <>
                        <InputLabel
                            style={{
                                fontSize: 24,
                                fontFamily: "Poppins-Medium",
                                color: "#554886",
                                marginLeft: '8px'
                            }}
                        >
                            Deaths
                        </InputLabel>
                        <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            "& > :not(style)": {
                                m: 1,
                                flex: 1,
                                minWidth: 129,
                                // height: 485,
                            }
                        }} >

                            <Deaths />
                        </Box>
                    </>
                }
            </Box>
        </Box >
    )
}
export default StatisticsOtherSection