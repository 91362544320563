import * as React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import {
    InputLabel,
    Paper,
    Tooltip
} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material";
import { BiSolidUpArrow } from "react-icons/bi";
import Divider from '@mui/material/Divider';
import { fills } from "../../../../../constants/constant";
import store from "../../../../../redux/store/store";

const RADIAN = Math.PI / 180;
const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#7A7A7A'} style={{ fontSize: 12, fontFamily: 'Poppins-SemiBold' }}>
                {100}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        </g>
    );
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index, value }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} key={index} fill="#7A7A7A" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 10, fontFamily: ' Poppins-Regular' }}>
            {value}
        </text>
    );
};

const DiagnosedPatients = () => {
    const screenIndex = 2
    const [process, setProcess] = useState([])
    const [patientData, setPatientData] = useState(undefined)

    React.useEffect(() => {
        let { process } = store?.getState()?.nlaReducer;
        process = (process || [])?.find(e => e.journey_step_seq == screenIndex);
        setProcess(process?.journey_data.map((item, i) => {
            return ({
                value: parseInt(item?.total_patient || 0),
                name: item?.journey_step_name,
                _id: item?.journey_step_id,
                fill: item?.step_color // fills[i]
            })
        }))
        setPatientData(_setPatientData(process?.patient_data))
    }, [store?.getState()?.nlaReducer?.process])

    const checkInfinity = (value) => {
        const mathRetult = Math.round(value)
        return Number.isFinite(mathRetult) ? Math.abs(mathRetult) : "NA"
    }


    const _setPatientData = (item) => {
        return {
            diagnosedPatient: {
                value: item?.diagnosis_patient || 0,
                percentage: checkInfinity((item?.diagnosis_patient - item?.diagnosis_patient_previous) / item?.diagnosis_patient_previous * 100) || 0, //RV
                isUp: item?.diagnosis_patient <= item?.diagnosis_patient_previous //RV
            },
            sampleCollection: {
                value: item?.sample_collection_patient || 0,
            },
            biopsyResult: {
                value: item?.bioposy_patient || 0,//RV
                positive: {
                    name: 'Positive',
                    value: item?.positive_patient || 0,
                    isUp: true
                },
                negative: {
                    name: 'Negative',
                    value: item?.nagative_patient || 0,
                    isUp: false
                }

            },
            validation: {
                value: item?.validation_patient || 0,
            },
        }
    }

    // const patientData = props.patientData || {
    //     diagnosedPatient: {
    //         value: 286,
    //         percentage: 46,
    //         isUp: true
    //     },
    //     sampleCollection: {
    //         value: 87,
    //     },
    //     biopsyResult: {
    //         value: 62,
    //         positive: {
    //             name: 'Positive',
    //             value: 42,
    //             isUp: true
    //         },
    //         negative: {
    //             name: 'Negative',
    //             value: 20,
    //             isUp: false
    //         }

    //     },
    //     validation: {
    //         value: 43,
    //     },
    // }

    return (
        <>
            <Paper
                elevation={0}
                sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1 }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 20px"
                    }}
                >
                    <InputLabel
                        style={{
                            fontSize: 14,
                            fontFamily: "Poppins-Medium",
                            color: "#838383",
                            paddingBottom: 5,
                            marginTop: 15
                        }}
                    >
                        {"Patient"}
                    </InputLabel>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            // alignItems: "center",
                            justifyContent: 'space-between',
                            padding: "20px 0",
                            height: 240,
                            overflowY: 'auto',
                            paddingTop: 0
                        }}
                    >
                        <Box
                            style={{ display: "flex", flexDirection: "column", alignItems: 'start', width: 167 }}
                        >
                            <Box style={{ width: 180 }}>
                                
                                {(process || []).map((processItem, i) => {
                                    return (
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: "Poppins-SemiBold",
                                                    color: "#00133A",
                                                    width: 20
                                                }}
                                            >
                                                {processItem.value}
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: "Poppins-Medium",
                                                    color: "#838383",
                                                    paddingLeft: 8,
                                                    flex: 1
                                                }}
                                            >
                                                {processItem.name}
                                            </span>
                                        </Box>
                                    )
                                })}


                            </Box>
                        </Box>

                        <PieChart width={130} height={130} onMouseEnter={() => { }}>
                            <Pie
                                data={process}
                                innerRadius={22}
                                outerRadius={45}
                                labelLine={false}
                                cx="50%"
                                cy="50%"
                                label={renderCustomizedLabel}
                                fill="#8884d8"
                                paddingAngle={0}
                                dataKey="value"
                                activeIndex={0}
                                activeShape={renderActiveShape}
                            >
                                {(process || []).map((entry, index) => (
                                    <>
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.fill}
                                        >
                                        </Cell>
                                        <Tooltip placement="top" arrow title={"Test"}></Tooltip>
                                    </>
                                ))}
                            </Pie>
                        </PieChart>
                    </Box>
                    <Divider variant="middle" />
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"DIAGNOSED PATIENTS"}
                            </InputLabel>


                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "10px 0",
                                }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                    }}
                                >
                                    <h3
                                        style={{
                                            fontSize: 25,
                                            fontFamily: "Poppins-SemiBold",
                                            color: "#00133A",
                                            paddingRight: 10
                                        }}
                                    >
                                        {patientData?.diagnosedPatient.value}
                                    </h3>
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontFamily: "Poppins-Medium",
                                            color: patientData?.diagnosedPatient.isUp ? "#34D399" : "#F57C7C",
                                        }}
                                    >
                                        {patientData?.diagnosedPatient.percentage + '%'}
                                    </span>
                                    <BiSolidUpArrow color={patientData?.diagnosedPatient.isUp ? "#34D399" : "#F57C7C"} size={8} />
                                </Box>
                            </Box>
                        </Box>
                        <Divider
                            style={{
                                height: 1,
                                width: 40,
                                transform: "rotate(90deg)",
                                background: "gray",
                            }}
                        />
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'space-between',
                                padding: "10px 0",
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                }}
                            >
                                {"SAMPLE COLLECTION"}
                            </InputLabel>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // alignItems: "center",
                                    justifyContent: 'space-between',
                                    padding: "10px 0",
                                }}>
                                <h3
                                    style={{
                                        fontSize: 25,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                        paddingRight: 10
                                    }}
                                >
                                    {patientData?.sampleCollection.value}
                                </h3>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "20px 0",
                        }}
                    >
                        <Box>
                            <InputLabel
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                    paddingBottom: 5
                                }}
                            >
                                {"BIOPSY RESULT"}
                            </InputLabel>
                            <Box style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                                <Box>
                                    <h3
                                        style={{
                                            fontSize: 22,
                                            fontFamily: "Poppins-SemiBold",
                                            color: "#00133A",
                                        }}
                                    >
                                        {patientData?.biopsyResult.value}
                                    </h3>
                                </Box>
                                <Divider
                                    style={{
                                        height: 1,
                                        width: 40,
                                        transform: "rotate(90deg)",
                                        background: "gray",
                                    }}
                                />
                                {patientData && Object.keys(patientData?.biopsyResult).map((key, i) => {
                                    return (!!i &&
                                        < Box style={{
                                            padding: '0px 5px'
                                        }} >
                                            <h3
                                                style={{
                                                    fontSize: 13,
                                                    fontFamily: "Poppins-SemiBold",
                                                    color: "#00133A",
                                                }}
                                            >
                                                {patientData?.biopsyResult[key].value}
                                            </h3>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    fontFamily: "Poppins-Regular",
                                                    color: patientData?.biopsyResult[key].isUp ? "#34D399" : "#F57C7C",
                                                }}
                                            >
                                                {patientData?.biopsyResult[key].name}
                                            </p>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Divider
                            style={{
                                height: 1,
                                width: 40,
                                transform: "rotate(90deg)",
                                background: "gray",
                            }}
                        />

                        <Box>
                            <InputLabel
                                id="patient"
                                style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins-Medium",
                                    color: "#237BBC",
                                    paddingBottom: 5
                                }}
                            >
                                {"VALIDATION"}
                            </InputLabel>
                            <Box>
                                <h3
                                    style={{
                                        fontSize: 22,
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#00133A",
                                    }}
                                >
                                    {patientData?.validation.value}
                                </h3>
                            </Box>
                        </Box>
                    </Box>


                </Box>
            </Paper >
        </>
    )
}
export default DiagnosedPatients