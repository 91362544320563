import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Button, LinearProgress, Box } from '@mui/material';
import { RiDeleteBinLine } from "react-icons/ri";
import { BiCheck } from "react-icons/bi";
import * as _ from "lodash";

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API } from '../../../constants/constant';
import { setBarrierLoader, setBarrierPage, setDefinedBarrierList } from '../../../redux/actions/nlaactions';
import store from "../../../redux/store/store";
import axios from '../../../http/axios'
import { useEffect } from 'react';
import CommonPagination from '../../../common/control/commonpagination';
import {ReactComponent as DeleteIcon} from "../../../assets/webapp_icon/delete.svg";


const columns = [
    { id: "select", label: "", minWidth: 60, align: "center" },
    { id: "category", label: "Category", minWidth: 120 },
    { id: "des", label: "Description", minWidth: 300 },
    { id: "keywords", label: "Keywords", minWidth: 170 },
    { id: "action", label: "Actions", minWidth: 100, align: "center" },
];

const GroupBarriers = () => {
    const { state } = useLocation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [definedList, setDefinedList] = useState([])
    const [selectedArray, setSelectedArray] = useState([])
    const navigate = useNavigate();
    const { definedBarrierList, definedBarrierCount, dropdownData, isLoading, barrierPage } = useSelector(state => state.nlaReducer)
    const handleChangePage = (newPage) => {
        store.dispatch(setBarrierPage(newPage))
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        store.dispatch(setBarrierPage(1))
    };

    const handleDelete = (item) => {
        navigate('delete', {
            state: {
                title: "Delete", res: "Are you sure you want to delete the barrier ?", apiPath: API.deleteBarrierDescription, apiData: {
                    undefined_barrier_id: item?.undefined_barrier_id
                }
            }
        })
    }

    const handleNewGroupBarriers = () => {
        let _data = [...selectedArray]
        if (_data?.length) {
            navigate('new-groupbarriers', { state: { data: _data } })
        }
    }
    const handleTableRowSelect = (item) => {
        let list = [...selectedArray]
        if (list?.length) {
            let findPrev = list.find(itm => itm.undefined_barrier_id === item.undefined_barrier_id)
            if (findPrev?.undefined_barrier_id) {
                list = list.filter(itm => itm.undefined_barrier_id !== findPrev?.undefined_barrier_id)
            } else {
                if (list[0]?.category_name === item?.category_name) {
                    list = [...list, item]
                }
            }
        } else {
            list = [...list, item]
        }
        setSelectedArray(list)
    }


    const showBarrierDescriptionWithKeyword = (data) => {
        let config = {
            method: 'get',
            url: `${API.showBarrierDescriptionWithKeyword}?cancer_id=${data?.cancerTypes?.selectedItems[0]}&duration=${data?.duration?.name}&page_no=${barrierPage}&limit=${rowsPerPage}&month_id=${data?.month?.selectedItems[0]}&region_id=${data?.regions?.selectedItems[0]}&year=${data?.year?.name}`
        };
        store.dispatch(setBarrierLoader(true))
        axios.request(config)
            .then(function (response) {
                let _data = response?.data?.data || {}
                store.dispatch(setDefinedBarrierList(_data))
            })
            .catch(function (error) {
                store.dispatch(setBarrierLoader(false))
                alert(error?.message)
            });
    }

    useEffect(() => {
        if (!state && dropdownData) {
            showBarrierDescriptionWithKeyword(dropdownData)
        }
    }, [state, dropdownData, barrierPage, rowsPerPage])

    useEffect(() => {
        setDefinedList(definedBarrierList)
    }, [definedBarrierList])

    useEffect(() => {
        return () => {
            store.dispatch(setBarrierPage(1))
        }
    },[])


    return (<>
        <Button
            variant="contained"
            style={{
                height: 38,
                marginBottom: 4,
                width: 260,
                background: selectedArray?.length == 0 ? "#bfbfbf" : "#546FE6",
                textTransform: 'none',
                fontSize: 18,
                fontFamily: 'Poppins-SemiBold'
            }}
            onClick={handleNewGroupBarriers}

        >
            Group selected barriers
        </Button>
        <Box sx={{ width: "100%", boxShadow: '10px 24px 30px 10px #0000000F', border: "1px solid #D3DDEA", borderRadius: "8px", background: '#FFFFFF', }}>
            <TableContainer sx={{
                height: "calc(100vh - 378px)", overflow: "auto", borderRadius: "8px"
            }}>
                <Table>
                    <TableHead style={{position: "sticky",top: 0,zIndex: 1, boxShadow: '0px 15px 10px -10px lightgray',}}>
                        <TableRow style={{ background: '#F9F9F9 0% 0% no-repeat padding-box', height: "60px" }}>
                            {columns.map((column) => (

                                < TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Medium' }}

                                >
                                    {
                                        column.label
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !isLoading ? definedList?.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                        style={{ height: 60 }}
                                    >
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular', textAlign: 'center' }}>
                                            <BiCheck size={18} style={{ color: '#fff', background: selectedArray?.length && selectedArray?.map(item => item?.undefined_barrier_id)?.includes(row?.undefined_barrier_id) ? '#009E6C' : '#d3ddea', borderRadius: 10 }} onClick={() => handleTableRowSelect(row)} />
                                        </TableCell>
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular' }}>{row?.category_name}</TableCell>
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular' }}>{row?.description}</TableCell>
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular' }}>{row?.keyword}</TableCell>
                                        <TableCell style={{ fontSize: 16, fontFamily: 'Poppins-Regular', textAlign: 'center' }}>
                                            <DeleteIcon
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                color="#EE2D2D"
                                                onClick={() => handleDelete(row)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableRow> <TableCell colSpan={6} style={{ textAlign: 'center' }}><LinearProgress /></TableCell></TableRow>}
                        {isLoading ? null : definedList?.length === 0 && (<TableRow> <TableCell colSpan={6} style={{ textAlign: 'center' }}>No records found</TableCell> </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Outlet />
        </Box >
        <CommonPagination
            count={definedBarrierCount}
            rowsPerPage={rowsPerPage}
            page={barrierPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </>)
}

export default GroupBarriers