import { Box, Button, Typography, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";


const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        width: 637,
        height: 481,
        bgcolor: '#ffffff',
        // boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
        outline: 'none'
    },
    ButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // textAlign: 'center',
        margin: '20px 30px',
        // height: 443,
        gap: 10
    },
    button: {
        height: 60,
        marginBottom: 4,
        width: '-webkit-fill-available',
        marginTop: 20,
        borderRadius: 30,
        background: "#546FE6",
        textTransform: 'none',
        fontSize: 24,
        fontFamily: 'Poppins-SemiBold'
    },

    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -25,
        right: -25,
        width: 63,
        height: 63,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    }

};
const SettingSelectCategory = (props) => {
    const [open, setOpen] = React.useState(true);
    const { state } = useLocation();
    const navigate = useNavigate();
    const handleClose = () => navigate(-1);
    const navigateUserRole = () => {
        navigate('/user-role');
    };
    const navigateUserFacility = () => {
        navigate('/user-facility');
    };
    const navigateDoctor = () => {
        navigate('/doctor');
    };



    useEffect(() => {
        console.log('props', state)
    }, [state])
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                    <Box style={style.close}>
                        <MdOutlineClose size={36} style={{ color: "#fff", marginTop: 11 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box sx={{ margin: '50px' }}>
                        <Typography id="modal-modal-description" sx={{ mt: 8, color: 'Montserrat, SemiBold', fontSize: 25, fontFamily: 'Montserrat-Bold', textAlign: 'center' }} >
                            {'Select a category!'}
                        </Typography>
                        <Box style={style.ButtonContainer}>
                            <Button
                                variant="contained"
                                style={style.button}
                                onClick={navigateUserRole}
                            >
                                PN & NLA
                            </Button>
                            <Button
                                variant="contained"
                                style={style.button}
                                onClick={navigateDoctor}
                            >
                                Care Provider
                            </Button>
                            <Button
                                variant="contained"
                                style={style.button}
                                onClick={navigateUserFacility}
                            >
                                Facility
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
export default SettingSelectCategory