import { Box, Button, Typography, Modal, TextField, Label, FormLabel, Select, InputLabel, MenuItem, Autocomplete, CircularProgress, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { API, MOCKUP_COUNTRYCODED, MOCKUP_FACILITY, MOCKUP_ROLE, MOCKUP_YEAR } from "../../../constants/constant";
import axios from "../../../http/axios";
import _ from "lodash";
import { useSelector } from "react-redux";
import store from "../../../redux/store/store";

const countries = [
    { code: '+1', name: 'United States' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+91', name: 'India' },
];
// Create new PR

const style = {
    container: {
        top: '50%',
        left: '50%',
        alignItem: 'center',
        transform: 'translate(-50%, -50%)',
        width:"39%",
        maxHeight: "685px",
        height: "90%",
        bgcolor: '#ffffff',
        boxShadow: 15,
        borderRadius: 10,
        position: 'fixed',
    },
    Additem: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 30px 0px 30px',
        height: "92%",
        gap: 10,
        overflow: "auto"
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        top: -25,
        right: -25,
        width: 63,
        height: 63,
        backgroundColor: '#fda945',
        borderColor: '#fff',
        borderRadius: 30,
        border: '2px solid #fff',
        textAlign: 'center'
    },
    button: {
        height: 50,
        marginBottom: 8,
        width: '-webkit-fill-available',
        marginTop: 20,
        borderRadius: 25,
        background: "#546FE6",
        textTransform: 'none',
        fontSize: 20,
        fontFamily: 'Poppins-SemiBold'
    },
    dropdownTitle: {
        fontSize: 14,
        fontFamily: "Poppins-Regular",
        color: "#464A53",
    },
    select: {
        display: 'flex',
        minWidth: 170,
        height: 46.48,
        background: "#ffffff",
        borderRadius: 4,
        border: '0px solid #f4f6fa',
    },

};

const AddDoctor = () => {
    const { loginInfo } = useSelector(state => state.auth)

    const [isLoading, setIsLoading] = useState(false);
    const [errorCaught, setErrorCaught] = useState(null);
    const { state } = useLocation();
    const [firstName, setFirstName] = React.useState(state?.rowItem?.first_name || '');
    const [surName, setSurName] = React.useState(state?.rowItem?.sur_name || '');
    const [email, setEmail] = React.useState(state?.rowItem?.email || '');
    const [pass, setPass] = React.useState(state?.rowItem?.password || '');
    const [contact, setContact] = React.useState(state?.rowItem?.contact_number || '');
    const [countryCode, setCountryCode] = React.useState(state?.rowItem?.country_code ? { name: '', value: '+' + state?.rowItem?.country_code } : MOCKUP_COUNTRYCODED[208]);
    const [allFacility, setAllFacility] = useState(state?.allFacility);
    const [specilization, setSpecilization] = useState(state?.specilization);
    const [errors, setErrors] = useState({
        firstName: false,
        surName: false,
    });

    const navigate = useNavigate();
    const handleClose = () => navigate(-1);
    const onSave = () => {
        
        let errorsItem = {};
        if (!firstName?.trim()) {
            errorsItem['firstName'] = true;
        }
        if (!surName?.trim()) {
            errorsItem['surName'] = true;
        }
        // if (!email?.trim()) {
        //     errorsItem['email'] = true;
        // }
        // if (!contact?.trim()) {
        //     errorsItem['contact'] = true;
        // }
        if (!countryCode.value?.trim()) {
            errorsItem['countryCode'] = true;
        }
        if (!specilization.name?.trim()) {
            errorsItem['specilization'] = true;
        }
        if (!allFacility.name?.trim()) {
            errorsItem['pass'] = true;
        }
        if (!_.isEmpty(errorsItem)) {
            setErrors({ ...errors, ...errorsItem });
            return;
        }
        setIsLoading(true)
        if (state?.isEdit) {
            var data = JSON.stringify({
                "specilization_id": specilization?.selectedItems[0],
                "email": email,
                "facility_id": allFacility?.selectedItems[0],
                "first_name": firstName,
                "sur_name": surName,
                "contact_number": contact,
                "country_code": loginInfo?.calling_code,
                "dr_code": state?.rowItem?.dr_code
            });
           

            var config = {
                method: 'put',
                url: API.updateDoctor,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios.request(config)
                .then(function (response) {
                    handleClose();
                    setIsLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorCaught(error);
                    setIsLoading(false)
                });


        }
        else {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('specilization_id', specilization?.selectedItems[0]);
            data.append('email', email);
            data.append('first_name', firstName);
            data.append('sur_name', surName);
            data.append('facility_id', allFacility?.selectedItems[0]);
            // data.append('country_code', countryCode?.value.replace("+", ""));
            data.append('country_code', loginInfo?.calling_code);
            data.append('contact_number', contact);

            // var dataObject = {};
            // data.forEach(function(value, key){
            //     dataObject[key] = value;
            // });
            // console.log(dataObject);

            var config = {
                method: 'post',
                url: API.addDoctor,
                data: data
            };

            axios.request(config)
                .then(function (response) {
                    handleClose()
                    setIsLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorCaught(error);
                    setIsLoading(false)
                });
        }
    };

    const handleRoleChange = (event) => {
        const {
            target: { value },
        } = event;
        console.log(value,"value")
        setSpecilization({
            ...specilization,
            selectedItems: typeof value === "string" ? value.split(",") : value,
        });
    };

    

    const handleFacilityChange = (event) => {
        const {
            target: { value },
        } = event;
        setAllFacility({
            ...allFacility,
            selectedItems: typeof value === "string" ? value.split(",") : value,
        });
    };

    const alertComponent = errorCaught ? (
        <Alert icon={false} severity="error">
            {errorCaught.message}
        </Alert>
    ) : null;

    const clearErrorMessage = () => {
        setErrorCaught(null);
    };

    let findSpecialization = specilization.list?.find(item => item.value === specilization?.selectedItems?.[0])
    return (
        <>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.container}>
                
                    <Box style={style.close}>
                        <MdOutlineClose size={36} style={{ color: "#fff", marginTop: 11 }} onClick={handleClose}></MdOutlineClose>
                    </Box>
                    <Box sx={{ padding: '15px 25px 15px 25px', height:"100%", overflow: 'hidden' }}>
                        <Typography id="modal-modal-description" sx={{ mt: 1, color: '#546FE6', fontSize: 28, fontFamily: 'Montserrat-Bold' }} >
                            {state.title}
                        </Typography>
                        <Box style={style.Additem}>
                            {alertComponent}
                            <Box style={{ padding: 0, flex: 1 }}>
                                <FormLabel id="first-name" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    First Name<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}>*</span></FormLabel>
                              
                                <TextField id="category" value={firstName} onChange={(e) => setFirstName(e.target.value)} fullWidth variant="standard"
                                    onFocus={() => setErrors({ ...errors, firstName: false })}
                                    InputProps={{
                                        startAdornment: findSpecialization?.isdoctor == "1" && <span style={{paddingRight : 10}}>Dr.</span>,
                                        disableUnderline: true,
                                        style: {
                                            color: '#464A53',
                                            borderBottom: errors?.firstName ? '1px solid red' : "1px solid grey",
                                            fontSize: 18,
                                            fontFamily: 'Poppins-Regular',
                                        },
                                    }}
                                />
                            </Box>
                            <Box style={{ padding: 0, flex: 1 }}>
                                <FormLabel id="sur-name" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Sur Name<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}>*</span></FormLabel>
                                <TextField id="description" value={surName} onChange={(e) => setSurName(e.target.value)} fullWidth variant="standard"
                                    style={{ color: '#464A53', fontSize: 18, fontFamily: 'Poppins-Regular' }}
                                    onFocus={() => setErrors({ ...errors, surName: false })}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: '#464A53',
                                            borderBottom: errors?.surName ? '1px solid red' : "1px solid grey",
                                            fontSize: 18,
                                            fontFamily: 'Poppins-Regular',
                                        },
                                    }}
                                />
                            </Box>
                            <Box style={{ padding: 0, flex: 1 }}>
                                <FormLabel id="email-id" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Email ID</FormLabel>
                                <TextField id="keywords" value={email} onChange={(e) => {setEmail(e.target.value); clearErrorMessage();}} fullWidth variant="standard"
                                    style={{ color: '#464A53', fontSize: 18, fontFamily: 'Poppins-Regular' }}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: '#464A53',
                                            borderBottom: errors?.email ? '1px solid red' : "1px solid grey",
                                            fontSize: 18,
                                            fontFamily: 'Poppins-Regular',
                                        },
                                    }}
                                />
                            </Box>
                            <Box style={{ padding: 0, flex: 1 }}>
                                <FormLabel id="passowrd" sx={{ color: '#ACB1B6', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Password</FormLabel>
                                <TextField id="category" value={pass} onChange={(e) => setPass(e.target.value)} fullWidth variant="standard"
                                    style={{ color: '#464A53', fontSize: 18, fontFamily: 'Poppins-Regular' }}
                                    onFocus={() => setErrors({ ...errors, pass: false })}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: '#464A53',
                                            borderBottom: errors?.pass ? '1px solid red' : "1px dashed #00000029",
                                            fontSize: 18,
                                            fontFamily: 'Poppins-Regular',
                                        },
                                    }}
                                    disabled={true} />
                            </Box>
                            <Box style={{ padding: 0, flex: 1 }}>
                                <FormLabel id="phone-no" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Phone No<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}></span></FormLabel>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        // borderBottom: errors?.contact ? '1px solid red' : "1px solid grey",
                                        borderBottom: "1px solid grey",
                                        '&:hover': {
                                            borderBottom: '2px solid black',
                                        },
                                    }}
                                >
                                    {/* <Autocomplete
                                        id="country-code"
                                        options={MOCKUP_COUNTRYCODED}
                                        getOptionLabel={(option) => option.value}
                                        value={countryCode}
                                        onChange={(event, newValue) => {
                                            setCountryCode(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    style: {
                                                        color: '#464A53',
                                                        fontSize: 18,
                                                        fontFamily: 'Poppins-Regular',
                                                        width: 130,
                                                        // borderBottom: errors?.contact ? '1px solid red' : "1px solid grey",
                                                    },
                                                }}
                                            />
                                        )}
                                    /> */}
                                    <Box style={{color: '#464A53',fontSize: 18, fontFamily: 'Poppins-Regular', marginRight: 10, marginBottom: "1px"}}> {"+"+loginInfo?.calling_code} </Box>
                                    <TextField
                                        id="phone-number"
                                        fullWidth
                                        variant="standard"
                                        style={{
                                            color: '#464A53',
                                            fontSize: 18,
                                            fontFamily: 'Poppins-Regular',
                                            // marginLeft: '8px',
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' },
                                        }}
                                        value={contact} onChange={(e) => {const numericValue = e.target.value.replace(/[^0-9]/g, ''); setContact(numericValue); clearErrorMessage();}}
                                        onFocus={() => setErrors({ ...errors, contact: false })}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                color: '#464A53',
                                                border: 0,
                                                // borderBottom: errors?.contact ? '1px solid red' : "1px solid grey",
                                                fontSize: 18,
                                                fontFamily: 'Poppins-Regular',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            if (!(e.key === 'Backspace' || /^[0-9]+$/.test(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box style={{ padding: 0, flex: 1 }}>
                                
                                <FormLabel id="role" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                Specialization<span style={{ fontFamily: "Poppins-Regular", fontSize: 16, color: "#EE2D2D", paddingLeft: 4 }}>*</span></FormLabel>
                                <Select
                                    value={specilization?.selectedItems}
                                    variant="standard"
                                    onChange={handleRoleChange}
                                    style={style.select}
                                >
                                    {(specilization.list || []).map((item) => {
                                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                            </Box>
                            <Box style={{ padding: 0, flex: 1 }}>
                                {/* <InputLabel id="role" style={style.dropdownTitle}>
                                    Facility
                                </InputLabel> */}
                                <FormLabel id="facility-id" sx={{ color: '#63799B', fontSize: 16, fontFamily: 'Poppins-Regular' }}>
                                    Facility</FormLabel>
                                <Select
                                    value={allFacility?.selectedItems}
                                    variant="standard"
                                    onChange={handleFacilityChange}
                                    style={style.select}
                                >
                                    {(allFacility.list || []).map((item) => {
                                        return <MenuItem value={item.value}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                            </Box>
                            <Button
                                variant="contained"
                                style={style.button}
                                onClick={onSave}
                                disabled={isLoading}
                            >
                               {isLoading ? <CircularProgress color="inherit" /> : "Save"}
                               
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    );
};
export default AddDoctor