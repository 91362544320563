import * as React from "react";
import { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { styled } from '@mui/material/styles';
import {
  Grid,
  InputLabel,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  LinearProgress,
  Tooltip
} from "@mui/material";
import { useState } from "react";
import { Box, Link } from "@mui/material";
import { FiMonitor } from "react-icons/fi";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { BsFillSquareFill } from "react-icons/bs";
import Divider from '@mui/material/Divider';
import { Outlet, useNavigate } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import "../../../../../assets/css/patientJourney.css";

const RADIAN = Math.PI / 180;
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#7A7A7A'} style={{ fontSize: 12, fontFamily: 'Poppins-SemiBold' }}>
        {100}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="#7A7A7A" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 10, fontFamily: ' Poppins-Regular' }}>
      {value}
    </text>
  );
};



const CompletionRate = (props) => {

  const process = props.process || [{
    value: 110,
    name: 'HPV/DNA',
    _id: '1',
    fill: '#F5C076',
    rate: 100,
    isUp: true
  },
  {
    value: 26,
    name: 'VIA',
    _id: '2',
    fill: '#AD41E8',
    rate: 70,
    isUp: true
  },
  {
    value: 90,
    name: 'PAP',
    _id: '3',
    fill: '#F5BCB8',
    rate: 30,
    isUp: false
  },
  {
    value: 10,
    name: 'Others',
    _id: '4',
    fill: '#EDD5FC',
    rate: 50,
    isUp: false
  },
  ]
  const sections = props.sections || {
    completion: {
      value: 80,
      repitition: 8,
      isUp: true
    },
    delayed: {
      value: 87,
    },
    cancelled: {
      value: 43,
    },
  }

  const data12 = [
    { name: 'a', studentss: sections.completion.value },
  ];
  const value = data12[0].studentss;
  const totalValue = 100
  const startAngle = 0;
  const endAngle = (value / totalValue) * 360;
  return (
    <>
      <Paper
        elevation={0}
        sx={{ border: "0.5px solid #D3DDEA", borderRadius: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 20px"
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              justifyContent: 'space-between',
              padding: "20px 0",
            }}
          >
            <Box
              style={{ display: "flex", flexDirection: "column", alignItems: '' }}
            >
              <Box style={{ width: 160 }}>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                  <PieChart width={50} height={50}>
                    <Pie
                      data={data12}
                      dataKey="studentss"
                      startAngle={startAngle}
                      endAngle={endAngle}
                      outerRadius={25}
                      fill="#62E89C"
                    />
                  </PieChart>
                  <span
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins-Medium",
                      color: "#222222",
                      paddingLeft: 8,
                      width: 147
                    }}
                  >
                    {sections.completion.value + "%"}
                  </span>
                </Box>
              </Box>
            </Box>

            <Box style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4
            }}>

              <span
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Medium",
                  color: "#838383",
                }}
              >
                Repetition
              </span>
              <span
                id="patient"
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins-Medium",
                  color: "#222222",
                }}
              >
                {sections.completion.repitition + "%"}
              </span>
              {sections.completion.isUp && <BiSolidUpArrow color={"#34D399"} size={8} />}
              {!sections.completion.isUp && <BiSolidDownArrow color={"#F57C7C"} size={8} />}

            </Box>
          </Box>


          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 0",
              height: 210,
              overflowY: 'auto'
            }}
          >
            <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                }}
              >
                {"Process"}
              </InputLabel>

              {process.map((processItem, i) => {
                return (
                  <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    gap: 5
                  }}>
                    <BsFillSquareFill color={processItem.fill} size={9} style={{marginTop: 6}} />
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "Poppins-Medium",
                        color: "#838383",
                        width: 160
                      }}
                    >
                      {processItem.name}
                    </Typography>
                  </Box>
                )
              })}
            </Box>


            <Box style={{ display: "flex", flexDirection: "column", gap: 4, alignItems: "end" }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                  //paddingBottom: 5
                }}
              >
                {"Completion Rate"}
              </InputLabel>
              {process.map((processItem, i) => {
                return (
                  <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5
                  }}>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "Poppins-Medium",
                        color: "#222222",
                      }}
                    >
                      {processItem.rate + "%"}
                    </Typography>
                    {/* <BiSolidUpArrow color={processItem.isUp ? "#34D399" : "#F57C7C"} size={8} /> */}
                    {processItem.isUp && <BiSolidUpArrow color={"#34D399"} size={8} />}
                    {!processItem.isUp && <BiSolidDownArrow color={"#F57C7C"} size={8} />}
                  </Box>
                )
              })}
            </Box>
          </Box>

          <Divider variant="" />

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              //alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box className=" align-items-center" style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Semibold",
                  color: "#505050",
                }}
              >
                {"30 Days Delayed"}
              </InputLabel>

              <PieChart width={130} height={130} onMouseEnter={() => { }}>
                <Pie
                  data={process}
                  innerRadius={22}
                  outerRadius={45}
                  labelLine={false}
                  cx="50%"
                  cy="50%"
                  label={renderCustomizedLabel}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="delay30DaysValue"
                  activeIndex={0}
                  activeShape={renderActiveShape}
                >
                  {process.map((entry, index) => (
                    <>
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.fill}
                      >
                      </Cell>
                      <Tooltip placement="top" arrow title={"Test"}></Tooltip>
                    </>
                  ))}
                </Pie>
              </PieChart>
              <Typography
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                  width: 150,
                  textAlign: "center"
                }}
              >
                Average Gap* between milestones
              </Typography>
              <Typography
                style={{
                  fontSize: 22,
                  fontFamily: "Poppins-Semibold",
                  color: "#2F2F2F",
                  marginTop: "12px"
                }}
              >
                {sections.delayed.value}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  fontFamily: "Poppins-Regular",
                  color: "#6B6B6B",
                }}
              >
                *Accepted 5 Days
              </Typography>

            </Box>

            <Box className="align-items-center" style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <InputLabel
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Semibold",
                  color: "#505050",
                }}
              >
                {"Cancelled Appointment"}
              </InputLabel>

              <PieChart width={130} height={130} onMouseEnter={() => { }}>
                <Pie
                  data={process}
                  innerRadius={22}
                  outerRadius={45}
                  labelLine={false}
                  cx="50%"
                  cy="50%"
                  label={renderCustomizedLabel}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="cancelledAppointment"
                  activeIndex={0}
                  activeShape={renderActiveShape}
                >
                  {process.map((entry, index) => (
                    <>
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.fill}
                      >
                      </Cell>
                      <Tooltip placement="top" arrow title={"Test"}></Tooltip>
                    </>
                  ))}
                </Pie>
              </PieChart>
              <Typography
                style={{
                  fontSize: 13,
                  fontFamily: "Poppins-Regular",
                  color: "#8E8E8E",
                  padding: "10px 0px"
                }}
              >
                Discontinued Patients
              </Typography>
              <Typography
                style={{
                  fontSize: 22,
                  fontFamily: "Poppins-Semibold",
                  color: "#2F2F2F",
                  marginTop: "12px"
                }}
              >
                {sections.cancelled.value}
              </Typography>

            </Box>


          </Box>


        </Box>
      </Paper>
    </>
  )
}
export default CompletionRate